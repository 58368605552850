import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import SigninLayout from "layouts/Signin/index";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import Tournaments from './views/Tournaments'
import Shop from "./views/Shop";
import Layout from './layouts/Admin/Admin'
import Dashboard from "views/Dashboard";
import TournamentDetails from './components/Tournament/TournamentDetails';
import store from './redux/store'
import { Provider } from 'react-redux'
import Orders from "views/Orders";
import Providers from "views/Providers";
import Giftcards from "components/Store/GiftCard/Giftcards";
import Avatars from "components/Tournament/Avatar/Avatars";
import Quizzes from "views/Quizzes";
import Leaderboard from "views/Leaderboard";
import GD from "views/GD";
import Users from "views/Users";
import UserProfile from "components/User/UserProfile";
import Quests from "views/Quests";
import Support from "views/Support";
import QuizQuestions from "components/Quiz/QuizQuestions";
import QuizCategories from "components/Quiz/QuizCategories";
import MiniGames from "views/MiniGames";
import MiniGamesCategories from "components/MiniGame/MiniGamesCategories";
import Roles from "config/Roles";
import ProtectedRoute from "protectedRoutes";
import Forbidden from "layouts/403/Forbidden";
import Admins from "views/Admins";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="signin" element={<SigninLayout />} />
            <Route element={<Layout />}>
              <Route path="403" element={<Forbidden />} />
              <Route path="Support">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.SOCIAL_MEDIA]} element={<Support />} />
                } />
              </Route>
              <Route path="Mini-games">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<MiniGames />} />
                } />
                <Route path="Categories" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<MiniGamesCategories />} />
                } />
              </Route>
              <Route path="Users-manager">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS_MANAGER, Roles.ESPORTS]} element={<Users />} />
                } />
                <Route path=":id" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<UserProfile />} />
                } />
              </Route>
              <Route path="Admins">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Admins />} />
                } />
              </Route>
              <Route path="Leaderboard">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS_MANAGER]} element={<Leaderboard />} />
                } />
              </Route>
              <Route path="Quests">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Quests />} />
                } />
              </Route>
              <Route path="Quizzes">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Quizzes />} />
                } />
                <Route path=":themeId" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<QuizQuestions />} />
                } />
                <Route path="Categories" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<QuizCategories />} />
                } />
              </Route>
              <Route path="GD">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<GD />} />
                } />
              </Route>
              <Route path="Providers">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Providers />} />
                } />
              </Route>
              <Route path="Orders">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Orders />} />
                } />
              </Route>
              <Route path="Shop">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Shop />} />
                } />
                <Route path=":id" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN]} element={<Giftcards />} />
                } />
              </Route>
              <Route path="Tournaments">
                <Route path="" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS, Roles.ESPORTS_MANAGER]} element={<Tournaments />} />
                } />
                <Route path=":id" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS, Roles.ESPORTS_MANAGER]} element={<TournamentDetails />} />
                } />
                <Route path="Avatars" element={
                  <ProtectedRoute permissions={[Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS_MANAGER]} element={<Avatars />} />
                } />
              </Route>
              <Route path="Dashboard">
                <Route path="" element={<Dashboard />} />
              </Route>
            </Route>

            <Route path="/" element={<Navigate to="/signin" replace />} />
          </Routes>
        </BrowserRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  </Provider>
);



/*
function getRoutes(routes)  {
  return routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={prop.component}
          key={key}
        />
      );
    
  });
};*/

