import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getAllowedRoutes } from "utils";

const SidebarLink = styled(Link)`
    display: flex;
    /* color: #e1e9fc; */
    justify-content: space-between;
    align-items: center;
`;
const OpenCloseIcon = styled.div`
    position: absolute;
    right: 10px;
`
const MenuDiv = styled.div`
    display: flex;
    align-items: baseline;
`;
const SubmenuDiv = styled.div`
    margin-left: 30px;
    display: flex;
    align-items: baseline;
`;

const DropdownLink = styled(Link)`
    display: flex;
    align-items: center;
`;

const SubMenu = ({ item, active }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <SidebarLink to={item.path}
                onClick={item.subNav && showSubnav}>
                <MenuDiv>
                    <i className="tim-icons"><img src={item.icon} style={{ width: '60%' }} /></i>
                    {item.name}
                    <OpenCloseIcon>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                                ? item.iconClosed
                                : null}
                    </OpenCloseIcon>
                </MenuDiv>
            </SidebarLink>
            {subnav &&
                getAllowedRoutes(item.subNav).map((item, index) => {
                    return (
                        <li key={index} className={active(item.path)}>
                            <DropdownLink data-toggle="collapse" to={item.path} key={index}>
                                <SubmenuDiv>
                                    <i className="tim-icons"><img src={item.icon} style={{ width: '65%' }} /></i>
                                    <span>{item.name}</span>
                                </SubmenuDiv>
                            </DropdownLink>
                        </li>
                    );
                })}
        </>
    );
};

export default SubMenu;
