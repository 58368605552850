import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader
} from "reactstrap";
import { AddIcon2, AdminsIcon, AvatarIcon, AwardIcon, CategoryIcon, GiftCardIcon, ManetteIcon, MiniGamesIcon, NotifIcon, PlatformIcon, ProductsIcon, QuestIcon, QuizThemeIcon, QuizzesIcon, T7Dash } from '../../assets/img';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import TournamentNotifications from "components/Tournament/TournamentNotifications";
import CreateUpdateTournament from "components/Tournament/CreateUpdateTournament";
import AddAvatar from "components/Tournament/Avatar/AddAvatar";
import AddUpdateTournamentGame from "components/Tournament/Games/CreateUpdateGame";
import CreateUpdateProduct from "components/Store/Product/CreateUpdateProduct";
import CustomNotification from "components/Dashboard/CustomNotification";
import CreateUpdateGiftCard from "components/Store/GiftCard/CreateUpdateGiftCard";
import CreateUpdateQuiz from "components/Quiz/CreateUpdateQuiz";
import CreateUpdateQuest from "components/Quest/CreateUpdateQuest";
import CreateUpdateTheme from "components/Quiz/CreateUpdateTheme";
import CreateUpdateCategory from "components/Quiz/CreateUpdateCategory";
import CreateUpdateMiniGameCategory from "components/MiniGame/CreateUpdateCategory";
import CreateUpdateMiniGame from "components/MiniGame/CreateUpdateMiniGame";
import CreateAdmin from "components/Admin/CreateAdmin";

const T7DLogo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    img{
        position: absolute;
        max-width: 110px;
    }
    p{
        margin-top: 60px;
        color: #525f7f;
        font-size: 9px;
    }
`

const BackgroundDiv = styled.div`
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::after{
      content: "";
      top: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(27, 37, 115,.33), rgba(255, 70, 94,.23));
      inset: 0;
      position: absolute;
      border-radius: 50%;
    }
    &::before{
      content:"";
      top:-10px;
      right: -5px;
      min-height: 15px !important;
      width: 13px !important;
      max-height: 15px !important;
      max-width: 15px !important;
      position: absolute;
      background-image: url(${AddIcon2});
      background-size: cover;
      background-position: center;
      z-index: 456;
    }
`

function AdminNavbar(props) {
  const location = useLocation();
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [profileData, setProfileData] = React.useState({});
  // Modal custom notification
  const [modalCustomNotif, setmodalCustomNotif] = React.useState(false);
  // Modal notification
  const [modalNotif, setmodalNotif] = React.useState(false);
  // Modal open state
  const [modal, setModal] = React.useState(false);
  // Modal avatar
  const [modalAvatar, setModalAvatar] = React.useState(false);
  // Modal games
  const [modalGames, setModalGames] = React.useState(false);
  // Modal product
  const [modalProduct, setModalProduct] = React.useState(false);
  // Modal miniGame category
  const [modalMiniGamesCategory, setModalMiniGamesCategory] = React.useState(false);
  // Modal miniGame 
  const [modalMiniGames, setModalMiniGames] = React.useState(false);
  // Modal giftcard
  const [modalGiftCard, setModalGiftCard] = React.useState(false);
  // Modal quiz category
  const [modalQuizCategory, setModalQuizCategory] = React.useState(false);
  // Modal quiz theme
  const [modalQuizTheme, setModalQuizTheme] = React.useState(false);
  // Modal quiz
  const [modalQuiz, setModalQuiz] = React.useState(false);
  // Modal quest
  const [modalQuest, setModalQuest] = React.useState(false);
  // Modal admin
  const [modalCreateAdmin, setModalCreateAdmin] = React.useState(false);
  // check if super admin
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);

  // Toggle for custom notifications
  const toggleCustomNotif = () => {
    setmodalCustomNotif(!modalCustomNotif);
  }
  // this function is to open the Notification modal
  const toggleModalNotification = () => {
    setmodalNotif(!modalNotif);
  }

  // Toggle for Modal
  const toggle = () => {
    // getAllTournaments();
    setModal(!modal);
  }

  // Toggle for Avatar Modal
  const toggleAvatar = () => {
    setModalAvatar(!modalAvatar);
  }

  // Toggle for Avatar Modal
  const toggleGames = () => {
    setModalGames(!modalGames);
  }

  // Toggle for Product Modal
  const toggleProduct = () => {
    setModalProduct(!modalProduct);
  }

  // Toggle for giftcard Modal
  const toggleGiftCard = () => {
    setModalGiftCard(!modalGiftCard);
  }
  // Toggle for mini games  Modal
  const toggleMiniGames = () => {
    setModalMiniGames(!modalMiniGames);
  }
  // Toggle for mini games category Modal
  const toggleMiniGamesCategory = () => {
    setModalMiniGamesCategory(!modalMiniGamesCategory);
  }

  // Toggle for quiz category Modal
  const toggleQuizCategory = () => {
    setModalQuizCategory(!modalQuizCategory);
  }

  // Toggle for quiz theme Modal
  const toggleQuizTheme = () => {
    setModalQuizTheme(!modalQuizTheme);
  }

  // Toggle for quiz Modal
  const toggleQuiz = () => {
    setModalQuiz(!modalQuiz);
  }

  // Toggle for quest Modal
  const toggleQuest = () => {
    setModalQuest(!modalQuest);
  }

  // Toggle for quest Modal
  const toggleCreateAdmin = () => {
    setModalCreateAdmin(!modalCreateAdmin);
  }

  React.useEffect(() => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile) {
      setProfileData(profile);
      setIsSuperAdmin(profile.roleId.title === 'super admin');
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  // Open popup

  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <T7DLogo>
                <img alt="T7D" src={T7Dash} />
                <p>Dashboard Version : 1.1.0.1</p>
              </T7DLogo>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {/* <InputGroup className="search-bar">
                <Button color="link" onClick={toggleModalSearch}>
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup> */}
              {/* Dashboard */}
              {(location.pathname.includes("/Dashboard") && isSuperAdmin) &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleCustomNotif() }}>
                    <BackgroundDiv>
                      <img src={NotifIcon} />
                      <span className="d-lg-none d-md-block">Send custom notifications</span>
                    </BackgroundDiv>
                  </Button>
                </InputGroup>
              }
              {/* Tournament */}
              {location.pathname.includes("/Tournaments") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleGames() }}>
                    <BackgroundDiv>
                      <img src={ManetteIcon} />
                      <span className="d-lg-none d-md-block">Add Games</span>
                    </BackgroundDiv>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Tournaments") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleAvatar() }}>
                    <BackgroundDiv>
                      <img src={AvatarIcon} />
                      <span className="d-lg-none d-md-block">Create avatars</span>
                    </BackgroundDiv>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Tournaments") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggle() }}>
                    <BackgroundDiv>
                      <img src={AwardIcon} style={{ height: "20px", width: "30px" }} />
                      <span className="d-lg-none d-md-block">Create Tournament</span>
                    </BackgroundDiv>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Tournaments") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleModalNotification() }}>
                    <BackgroundDiv>
                      <img src={NotifIcon} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Send Notifications</span>
                  </Button>
                </InputGroup>
              }
              {/* Store */}
              {location.pathname.includes("/Shop") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleProduct() }}>
                    <BackgroundDiv>
                      <img src={ProductsIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Create products</span>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Shop") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleGiftCard() }}>
                    <BackgroundDiv>
                      <img src={GiftCardIcon} style={{ height: "20px", width: "23px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Create gift cards</span>
                  </Button>
                </InputGroup>
              }
              {/* {location.pathname.includes("/Shop") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleGiftCard() }}>
                    <BackgroundDiv>
                      <img src={PlatformIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Platforms</span>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Shop") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleGiftCard() }}>
                    <BackgroundDiv>
                      <img src={CategoryIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Categories</span>
                  </Button>
                </InputGroup>
              } */}
              {/* Mini games */}
              {location.pathname.includes("/Mini-games") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleMiniGames() }}>
                    <BackgroundDiv>
                      <img src={MiniGamesIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Add MiniGame</span>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Mini-games") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleMiniGamesCategory() }}>
                    <BackgroundDiv>
                      <img src={CategoryIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Add Category</span>
                  </Button>
                </InputGroup>
              }
              {/* Quizzes */}
              {location.pathname.includes("/Quizzes") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleQuizCategory() }}>
                    <BackgroundDiv>
                      <img src={CategoryIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Add Category</span>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Quizzes") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleQuizTheme() }}>
                    <BackgroundDiv>
                      <img src={QuizThemeIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Add Quiz Theme</span>
                  </Button>
                </InputGroup>
              }
              {location.pathname.includes("/Quizzes") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleQuiz() }}>
                    <BackgroundDiv>
                      <img src={QuizzesIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Add Quiz</span>
                  </Button>
                </InputGroup>
              }
              {/* Quests */}
              {location.pathname.includes("/Quests") &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleQuest() }}>
                    <BackgroundDiv>
                      <img src={QuestIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Add Quest</span>
                  </Button>
                </InputGroup>
              }
              {/* Admins */}
              {(location.pathname.includes("/Admins") && isSuperAdmin) &&
                <InputGroup >
                  <Button color="link" onClick={() => { toggleCreateAdmin() }}>
                    <BackgroundDiv>
                      <img src={AdminsIcon} style={{ height: "20px", width: "20px" }} />
                    </BackgroundDiv>
                    <span className="d-lg-none d-md-block">Create Admin</span>
                  </Button>
                </InputGroup>
              }
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={profileData.avatar} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
      {modalCustomNotif && <CustomNotification toggle={toggleCustomNotif} modal={modalCustomNotif} />}
      {modalNotif && <TournamentNotifications toggle={toggleModalNotification} modal={modalNotif} />}
      {modal && <CreateUpdateTournament modal={modal} toggle={toggle} />}
      {modalAvatar && <AddAvatar modal={modalAvatar} toggle={toggleAvatar} />}
      {modalGames && <AddUpdateTournamentGame modal={modalGames} toggle={toggleGames} />}
      {modalProduct && <CreateUpdateProduct modal={modalProduct} toggle={toggleProduct} />}
      {modalGiftCard && <CreateUpdateGiftCard modal={modalGiftCard} toggle={toggleGiftCard} />}
      {modalQuizCategory && <CreateUpdateCategory modal={modalQuizCategory} toggle={toggleQuizCategory} />}
      {modalQuizTheme && <CreateUpdateTheme modal={modalQuizTheme} toggle={toggleQuizTheme} />}
      {modalQuiz && <CreateUpdateQuiz modal={modalQuiz} toggle={toggleQuiz} />}
      {modalQuest && <CreateUpdateQuest modal={modalQuest} toggle={toggleQuest} />}
      {modalMiniGames && <CreateUpdateMiniGame modal={modalMiniGames} toggle={toggleMiniGames} />}
      {modalMiniGamesCategory && <CreateUpdateMiniGameCategory modal={modalMiniGamesCategory} toggle={toggleMiniGamesCategory} />}
      {modalCreateAdmin && <CreateAdmin modal={modalCreateAdmin} toggle={toggleCreateAdmin} />}
    </>
  );
}

export default AdminNavbar;
