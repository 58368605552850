import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../utils/notification';
import profileService from 'services/profile.service';
import { BanIcon } from 'assets/img';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const BanUser = ({ modal, toggle, profileId, username }) => {
    const [data, setData] = React.useState({ profileId: profileId, bannedUntil: undefined, reason: undefined })
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const reasons = [
        { key: "1", value: "Cheating or hacking" },
        { key: "2", value: "Inappropriate behavior" },
        { key: "3", value: "Account sharing or selling" },
        { key: "4", value: "Exploiting bugs" },
        { key: "5", value: "Fraudulent activities" },
        { key: "6", value: "Multiple accounts" },
        { key: "7", value: "Violation of terms of service" },
        { key: "8", value: "Inappropriate content" },
        { key: "9", value: "Impersonation" },
        { key: "10", value: "Match-fixing or collusion" },
        { key: "11", value: "Violation of age restrictions" },
        { key: "12", value: "Negative impact on server or network" },
        { key: "13", value: "Unauthorized access" },
        { key: "14", value: "Infringement of intellectual property" },
        { key: "15", value: "Promotion of third-party services" }
    ]

    const ban = async () => {
        console.log(data);
        if (data.bannedUntil !== undefined && data.reason !== undefined) {
            profileService.banProfile(data).then((response) => {
                if (response.data.error === true) {
                    setColor("3");
                    setMessage(response.data.message)
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
                else {
                    setColor("2");
                    setMessage(username + " Banned successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }
            }).catch((err) => {
                console.log(err.message);
            })
        }
        else {
            setColor("3");
            setMessage("Please fill in the required fields!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const inputHandler = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <img src={BanIcon} />Ban {username}
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Ban {username} until</label><br />
                                    <Input type="datetime-local" onChange={inputHandler} name="bannedUntil" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Reason </label>
                                    <select defaultValue="" onChange={inputHandler} name="reason">
                                        <option value="" disabled hidden>Select reason</option>
                                        {reasons.map((reason) => {
                                            return (
                                                <option key={reason.key} value={reason.value}>{reason.value}</option>
                                            );
                                        })}

                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className='post' onClick={() => ban()}>Ban</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(BanUser);