import store from '../../services/store.service'
import { GET_ALL_PRODUCTS_ACTION, GET_PRODUCT_GIFTCARDS_ACTION } from '../actions/storeActions'

const initState = {
    allProducts: [],
    productGiftCards: []
}

const storeReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
        case "GET_ALL_PRODUCTS":
            return { ...state, allProducts: action.data }
        case "GET_PRODUCT_GIFTCARDS":
            return { ...state, productGiftCards: action.data }
    }
}

const getProducts = () => async (dispatch, getState) => {
    var products = await store.getAllProducts()
    dispatch(GET_ALL_PRODUCTS_ACTION(products.data.data))
}

const getProductGiftCards = (payload) => async (dispatch, getState) => {
    var productGiftCards = await store.getGiftCards(payload)
    dispatch(GET_PRODUCT_GIFTCARDS_ACTION(productGiftCards.data.data))
}


export { storeReducer, getProducts, getProductGiftCards }