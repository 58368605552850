import React from 'react';
import styled from 'styled-components'
import { AddIcon, ArrowIcon, BackArrowIcon, DeleteIcon, EditIcon, QuizzesIcon } from '../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import profileService from 'services/profile.service';
import { useDispatch } from 'react-redux';
import { questions } from 'redux/reducers/quizSlice';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const ActionStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    .add{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`

const CreateUpdateQuiz = ({ modal, toggle, quiz }) => {
    const [showNotif, setShowNotif] = React.useState(false);
    const [color, setColor] = React.useState();
    const [message, setMessage] = React.useState("");
    const [quizThemes, setQuizThemes] = React.useState([]);
    const [theme, setTheme] = React.useState("");
    const [correctResponse, setCorrectResponse] = React.useState("");
    const [quizResponses, setQuizResponses] = React.useState([""]);
    const [data, setData] = React.useState({ quizTheme: undefined, text: undefined, quizOptions: [], correctResponse: undefined, gd: undefined })

    const dispatch = useDispatch();

    const inputHandler = (e, index) => {
        const { name, value } = e.target
        const list = [...quizResponses];
        if (name === "quizOptions") {
            list[index] = value;
            setQuizResponses(list);
            setData({ ...data, quizOptions: list })
        }
        else if (name === "correctResponse") {
            setCorrectResponse(value);
            setData({ ...data, [name]: value })
        }
        else if (name === "quizTheme") {
            setTheme(value);
            setData({ ...data, [name]: value })
        }
        else
            setData({ ...data, [name]: value })
    }

    const AddUpdateQuiz = async () => {
        if (quiz) { //Update
            data._id = quiz._id;
            profileService.updateQuizQuestion(data).then((response) => {
                setColor("2");
                setMessage("Quiz question updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new question
            if (data.text !== undefined && data.quizOptions !== [] && data.correctResponse !== undefined) {
                profileService.addQuizQuestion(data).then((response) => {
                    setColor("2");
                    setMessage("Quiz question created successfully!");
                    setShowNotif(true);
                    dispatch(questions(theme, { skip: 0 }));
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
            else {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
        }
    }

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...quizResponses];
        list.splice(index, 1);
        setData({ ...data, quizOptions: list })
        setQuizResponses(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setQuizResponses([...quizResponses, ""]);
    };

    const getThemes = async () => {
        profileService.getAllQuizTheme({ skip: 0 }).then(response => {
            setQuizThemes(response.data.data)
        })
    }

    React.useEffect(() => {
        getThemes();
        if (quiz) {
            if (quiz.quizOptions.length !== 0)
                setQuizResponses(quiz.quizOptions);

            setCorrectResponse(quiz.correctResponse);
            setTheme(quiz.quizTheme);
            setData({ quizTheme: quiz.quizTheme, text: quiz.text, quizOptions: quiz.quizOptions, correctResponse: quiz.correctResponse })
        }
    }, [])
    React.useEffect(() => {
        // This code will run every time quizResponses changes
    }, [quizResponses]);

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update quiz" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={QuizzesIcon} style={{ height: "20px", width: "20px" }} />  {quiz ? 'Update' : 'Add'} Quiz question</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Quiz Theme</label><br />
                                    <select value={theme} onChange={inputHandler} name="quizTheme">
                                        <option value="" disabled selected hidden>Select quiz theme</option>
                                        {quizThemes && quizThemes.map((qt) => {
                                            return (
                                                <option key={qt._id} value={qt._id}>{qt.title}</option>
                                            );
                                        })}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Quiz question </label>
                                    <Input placeholder="Quiz question" type="textarea" defaultValue={quiz && quiz.text} onChange={inputHandler} name="text" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Quiz options </label>
                                    {quizResponses.map((q, i) => {
                                        return (
                                            <ActionStyle>
                                                <Input placeholder="Quiz option" type="text" value={q} onChange={(e) => inputHandler(e, i)} name="quizOptions" />
                                                {quizResponses.length - 1 === i && <div className='add'><img src={AddIcon} alt="add" onClick={handleAddClick} /></div>}
                                                {quizResponses.length !== 1 && <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => handleRemoveClick(i)} /></div>}
                                            </ActionStyle>
                                        )
                                    })}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Correct Answer</label><br />
                                    <select value={correctResponse} onChange={inputHandler} name="correctResponse">
                                        <option value="" disabled selected hidden>Select the correct answer</option>
                                        {quizResponses.map((qr, i) => {
                                            return (
                                                <option key={i} value={qr}>{qr}</option>
                                            );
                                        })}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>GD </label>
                                    <Input placeholder="GD reward" type="number" defaultValue={quiz && quiz.gd} onChange={inputHandler} name="gd" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className='post' onClick={AddUpdateQuiz}>{quiz ? 'Update' : 'Post'}</Button>
                    </Form>
                </CreationForm>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateQuiz);