import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../utils/notification';
import authService from 'services/auth.service';
import { AdminsIcon } from 'assets/img';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const CreateAdmin = ({ modal, toggle }) => {
    const [data, setData] = React.useState({ username: undefined, email: undefined, companyId: undefined, roleId: undefined })
    const [roles, setRoles] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const create = async () => {
        if (data.username !== undefined && data.email !== undefined && data.companyId !== undefined && data.roleId !== undefined) {
            authService.createAdmin(data).then((response) => {
                setColor("2");
                setMessage("Admin created successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);

            }).catch((error) => {
                if (error.response.status !== 200) {
                    setColor("3");
                    setMessage(error.response.data.message)
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
                console.log(error.message);
            })
        }
        else {
            setColor("3");
            setMessage("Please fill in the required fields!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const inputHandler = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }
    const getRoles = async () => {
        authService.allRoles().then(response => {
            setRoles(response.data.data)
        })
    }
    const getCompanies = async () => {
        authService.allCompanies().then(response => {
            setCompanies(response.data.data)
        })
    }

    React.useEffect(() => {
        getRoles();
        getCompanies();
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <img src={AdminsIcon} /> Create Admin
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Email</label><br />
                                    <Input type="email" onChange={inputHandler} name="email" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Username</label><br />
                                    <Input type="text" onChange={inputHandler} name="username" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Role </label>
                                    <select defaultValue="" onChange={inputHandler} name="roleId">
                                        <option value="" disabled hidden>Select role</option>
                                        {roles.map((role) => {
                                            return (
                                                <option key={role._id} value={role._id}>{role.title}</option>
                                            );
                                        })}

                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Company </label>
                                    <select defaultValue="" onChange={inputHandler} name="companyId">
                                        <option value="" disabled hidden>Select company</option>
                                        {companies.map((company) => {
                                            return (
                                                <option key={company._id} value={company._id}>{company.name}</option>
                                            );
                                        })}

                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className='post' onClick={() => create()}>Create new admin</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(CreateAdmin);