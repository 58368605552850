import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button, Table } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import { OrdersLogo, BlueLockIcon, AddAvatarIcon, RemoveIcon, GreyLockIcon } from 'assets/img';
import orderService from 'services/order.service';
import Notification from 'utils/notification';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    button.resend, button.rebuy{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
`

const TaxInfo = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
`

const OrderDetails = ({ modal, toggle, order, total }) => {
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const resendVoucher = async () => {
        try {
            orderService.resendVoucher({ orderId: order.orderId }).then((response) => {
                setColor("2");
                setMessage("Voucher re-sent successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 4000);
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    const reBuyVoucher = async () => {
        try {
            orderService.reBuyVoucher({ orderId: order.orderId }).then((response) => {
                setColor("2");
                setMessage("Voucher re-bought successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 4000);
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='ordersModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <h4><img src={OrdersLogo} /> Order details: {order.orderId} </h4>
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "20px 0px 50px 20px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <TaxInfo>
                                    <h4>Total price: {total} $</h4>
                                </TaxInfo>
                            </Col>
                            <Col className="px-md-1" md="12">
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Price</th>
                                            <th className="text-center">Original price</th>
                                            <th className="text-center">Discount</th>
                                            <th className="text-center">Premium Discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.products.map((product, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{product.supplements[0] && product.supplements[0].sku}</td>
                                                    <td className="text-center">{product.giftCardId.description}</td>
                                                    <td className="text-center">{product.supplements[0] &&
                                                        (parseFloat(product.supplements[0].finalPrice / 100).toFixed(2) + ` * ` + product.supplements.length)}</td>
                                                    <td className="text-center">{parseFloat(product.supplements[0] && product.supplements[0].priceAfterDiscountForNormalUser / 100).toFixed(2)}</td>
                                                    <td className="text-center">{product.supplements[0] && product.supplements[0].discount}</td>
                                                    <td className="text-center">{product.supplements[0] && product.supplements[0].discountPremium}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <h4>Order data</h4>
                            </Col>
                            <Col className="px-md-1" md="12">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th rowSpan="2">Product name</th>
                                            <th rowSpan="2">Provider</th>
                                            <th colSpan="3" className="text-center">Vouchers</th>
                                        </tr>
                                        <tr>
                                            <th className="text-center">Item name</th>
                                            <th className="text-center">Card code</th>
                                            <th className="text-center">Pin code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.products.map((product, i) => product.supplements.map((supp) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{product.giftCardId.productId.title}</td>
                                                    <td>{supp.provider}</td>
                                                    <td className="text-center">{supp.voucherKey.item_name}</td>
                                                    <td className="text-center">{supp.voucherKey.voucher}</td>
                                                    <td className="text-center">{supp.voucherKey.pin_code}</td>
                                                </tr>
                                            )
                                        })

                                        )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ flexDirection: "row", display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        {order.paymentStatus && <Button className='resend' onClick={resendVoucher}>Resend voucher</Button>}
                        {order.paymentStatus && <Button className='rebuy' onClick={reBuyVoucher}>Re-buy voucher</Button>}
                    </div>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(OrderDetails);