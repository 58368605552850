import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://support.t7d.io"
} else {
    BASE_URL = "https://staging.support.t7d.io"
    // BASE_URL = "http://localhost:4000"
}

const getSupportCases = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/supportcases/allSupportCases`,
        data: payload,
        headers: authHeader()
    });
}

const getSubjectTypes = async () => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/subjectTypes`
    });
}

const updateSupportCase = async (id, payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/supportcases/updateOneSupportCase/${id}`,
        data: payload,
        headers: authHeader()
    });
}

export default {
    getSupportCases,
    getSubjectTypes,
    updateSupportCase
}