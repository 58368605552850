
import React from 'react';
import { EditIcon, DeleteIcon, QuestIcon, RepeatIcon, MiniGamesIcon, ThinkingIcon } from '../assets/img';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import { Stack, TablePagination } from '@mui/material';
import styled from 'styled-components';
import Notification from 'utils/notification';
import GreenButton from 'components/UI/GreenButton';
import miniGameService from "../services/mimiGames.service";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/miniGameSlice';
import { fetchMiniGames } from 'redux/reducers/miniGameSlice';
import Loader from 'components/UI/Loader';
import PaginationBox from 'components/UI/PaginationBox';
import CreateUpdateMiniGame from 'components/MiniGame/CreateUpdateMiniGame';

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .edit{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`
const NoMiniGames = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`
const SearchDiv = styled.div`
    display: flex;
    gap: 10px;
    input{
        background-color: #322e60;
        border-radius: 17px;
        width: 250px;
    }
    > * {
        flex: 0 0 auto;
    }
    button{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
`
const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: 250px;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`
function MiniGames() {
    const [miniGame, setMiniGame] = React.useState(undefined)
    const [miniGameId, setMiniGameId] = React.useState()
    const [searchedName, setSearchedName] = React.useState()
    const [category, setCategory] = React.useState()
    const [categories, setCategories] = React.useState([])
    const [premium, setPremium] = React.useState()
    const [ratingOrder, setRatingOrder] = React.useState()
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const dispatch = useDispatch();
    const { loading, miniGames, miniGamesCategories, pageNumber, error } = useSelector((state) => state.miniGames, shallowEqual)

    const orderBy = [
        { key: "1", value: "Ascending" },
        { key: "-1", value: "Descending" },
    ]

    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    // Toggle for Modal
    const toggle = (mg) => {
        if (mg !== undefined) {
            setMiniGame(mg)
        } else {
            setMiniGame(undefined)
        }
        fetchData()
        setModal(!modal)
    }

    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }

    const fetchData = (name, category, ratingOrder) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        let filter = { pageNumber, skip, limit }
        setSkip(skip);

        if (ratingOrder !== "") {
            filter.ratingOrder = ratingOrder
        }
        if (name !== "")
            filter.name = name
        if (category !== "") {
            filter.category = category;
        }
        if (premium !== "") {
            filter.premium = premium;
        }
        dispatch(fetchMiniGames(filter))
    }
    React.useEffect(() => {
        fetchData();
    }, [pageNumber])

    const fetchCategories = async () => {
        if (miniGamesCategories.length === 0) {
            const allCategories = await miniGameService.getCategories()
            setCategories(allCategories.data.data);
        }
        else setCategories(miniGamesCategories.data);
    }
    React.useEffect(() => {
        fetchCategories();
    }, [miniGamesCategories])

    const deleteMiniGame = async (id) => {
        try {
            miniGameService.deleteMiniGame({ _id: id }).then((response) => {
                setMiniGameId("");
                fetchData();
                deleteToggle();
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                <Row>
                    <Col md="12">
                        <Card style={{
                            height: '750px',
                            overflowY: 'auto',
                        }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={MiniGamesIcon} alt="minigames" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Mini Games</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SearchDiv>
                                            <h5>Search by name:</h5>
                                            <Input placeholder="Search by name" type="text" onChange={(e) => { setSearchedName(e.target.value) }} onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    fetchData(searchedName, category, ratingOrder)
                                                }
                                            }} name="username" />
                                            <Button onClick={() => fetchData(searchedName, category, ratingOrder)}>Search</Button>
                                        </SearchDiv>
                                        <SortFilterDiv>
                                            <h5>Filter by category:</h5>
                                            <select defaultValue="" onChange={(e) => { setCategory(e.target.value); fetchData(searchedName, e.target.value, ratingOrder) }} name="categoryFilter">
                                                <option value=""></option>
                                                {categories && categories.map((category) => {
                                                    return (
                                                        <option key={category._id} value={category._id}>{category.title}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                        <SortFilterDiv>
                                            <h5>Order by rating </h5>
                                            <select defaultValue="" onChange={(e) => { setRatingOrder(e.target.value); fetchData(searchedName, category, e.target.value) }} name="order">
                                                <option value=""></option>
                                                {orderBy.map((o) => {
                                                    return (
                                                        <option key={o.key} value={o.key}>{o.value}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive">
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (miniGames.data && miniGames.data.length === 0) ? <NoMiniGames>
                                            <img src={ThinkingIcon} />
                                            <p>No Mini games</p>
                                        </NoMiniGames>
                                            :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Rating</th>
                                                        <th>Category</th>
                                                        <th>Description</th>
                                                        <th>Age</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {miniGames.data && miniGames.data.map((element, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td className="text-center" style={{ cursor: "pointer" }} >
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        {/* Image */}
                                                                        <div style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px", height: "80px", width: "50px", backgroundImage: `url(${element.miniGame.image})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                                                                        {/* End image */}
                                                                        <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "5px" }}>
                                                                            {element.miniGame.name} <br />
                                                                            <div style={{ display: "flex" }}>
                                                                                {element.miniGame.premuim && <GreenButton text={"Premium"} />}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{element.miniGame.rating} </td>
                                                                <td>{element.category.title}</td>
                                                                <td>{element.miniGame.description.length > 50 ? element.miniGame.description.substring(0, 50) + "..." : element.miniGame.description}</td>
                                                                <td>{element.miniGame.age} </td>
                                                                <td >
                                                                    <ActionStyle>
                                                                        <div className='edit'><img src={EditIcon} alt="edit" onClick={() => { toggle(element) }} /></div>
                                                                        <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setMiniGameId(element.miniGame._id) }} /></div>
                                                                    </ActionStyle>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(miniGames.totalCount / limit)} totalNumber={miniGames.totalCount} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
                <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                    <ModalHeader toggle={deleteToggle}>Delete Mini Game</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this mini game?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteMiniGame(miniGameId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {modal && <CreateUpdateMiniGame modal={modal} toggle={toggle} miniGame={miniGame} />}
            </div>
        </>
    )
}

export default MiniGames;