import React from 'react';
import axios from 'axios';
import { EditIcon, ApproveIcon, CancelIcon } from '../../../assets/img';
import { Input } from "reactstrap";
import styled from 'styled-components';
import tournamentService from 'services/tournament.service';

const ActionDiv = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    .cancel{
        cursor: pointer;
    }
    .submit{
        cursor: pointer;
    }
`
const EditStyle = styled.div`
    cursor: pointer;
`
const InputDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const InputStyle = styled(Input)`
    text-align: center;
    height: 15px;
    width: 60px;
    
`
const BracketPlayerScoreRow = ({ index, player, tournamentId, roundNumber, showedTr }) => {
    const [editable, setEditable] = React.useState(false);
    const [status, setStatus] = React.useState(false);

    const [data, setData] = React.useState({ goals: 0, kills: 0, placement: 0, deaths: 0, assist: 0, overallScore: 0, status: false })
    const [prevData, setPrevData] = React.useState({ goals: 0, kills: 0, placement: 0, deaths: 0, assist: 0, overallScore: 0, status: false })

    const inputHandler = (e) => {

        const { name, value } = e.target
        if (name === "status") {
            setStatus(!status)
            setData(values => ({ ...values, [name]: !status }))
        }
        else setData(values => ({ ...values, [name]: value }))
    }

    React.useEffect(() => {
        setData(values => ({
            ...values,
            kills: player.kills,
            placement: player.placement,
            deaths: player.deaths,
            assist: player.assist,
            status: player.isWinner,
            overallScore: player.overallScore
        }))
        setStatus(player.isWinner)
    }, [])

    const activateEditable = (player) => {
        setPrevData(values => ({
            ...values,
            kills: data.kills,
            placement: data.placement,
            deaths: data.deaths,
            assist: data.assist,
            status: data.status,
            overallScore: data.overallScore
        }))
        setEditable(!editable)
        setStatus(data.status)
    }
    const submitResults = async () => {
        data.tournamentId = tournamentId
        data.roundNumber = roundNumber
        data.participationId = player.participationId
        tournamentService.submitPlayerScoreInBracket(data).then(response => {
            setEditable(false)
        }).catch((error) => {
            console.log(error.message);
        })
    }
    const cancel = async () => {
        setData(values => ({
            ...values,
            kills: prevData.kills,
            placement: prevData.placement,
            deaths: prevData.deaths,
            assist: prevData.assist,
            status: prevData.status,
            overallScore: prevData.overallScore
        }))
        setEditable(false)
    }

    return (<>
        {/* {showNotif && <Notification color={color} message={message} />} */}
        {player &&
            <tr key={index} >
                <td>{player.name}</td>
                {showedTr.goals && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.goals} name="goals" /></InputDiv> : data.goals}</td>}
                {showedTr.kills && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.kills} name="kills" /></InputDiv> : data.kills}</td>}
                {showedTr.placement && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.placement} name="placement" /></InputDiv> : data.placement}</td>}
                {showedTr.deaths && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.deaths} name="deaths" /></InputDiv> : data.deaths}</td>}
                {showedTr.assist && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.assist} name="assist" /></InputDiv> : data.assist}</td>}
                <td className="text-center">{editable ? <InputDiv><InputStyle type="checkbox" onChange={inputHandler} checked={status} name="status" /></InputDiv> : <p style={{ color: data.status ? 'green' : 'red' }}>{data.status?.toString()}</p>}</td>
                <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.overallScore} name="overallScore" /></InputDiv> : data.overallScore}</td>
                <td className="text-center">
                    {editable ?
                        <ActionDiv>
                            <div className="cancel" onClick={cancel}><img src={CancelIcon} /></div>
                            <div className='submit' onClick={submitResults}><img src={ApproveIcon} /></div>
                        </ActionDiv> :
                        <EditStyle onClick={() => activateEditable(player)}> <img src={EditIcon} /> </EditStyle>
                    }

                </td>
            </tr>
        }
    </>

    );
}

export default React.memo(BracketPlayerScoreRow);