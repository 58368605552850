import React from "react";
import { Card, CardBody, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ParticipantRow from "./ParticipantRow";
import tournamentService from "services/tournament.service";
import sharedDataService from "services/sharedData.service";
import styled from "styled-components";
import { ThinkingIcon } from "assets/img";
import { ProgressGIF } from "assets/img";
import { useDispatch, useSelector } from "react-redux";
import { getTournamentParticipants } from "redux/reducers/tournamentReducer";
import Notification from "utils/notification";

const NoParticipants = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`

const Progress = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  img{
    height: 300px;
  }
`
const Participants = ({ id }) => {
  const [allCountries, setAllCountries] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [player, setPlayer] = React.useState(undefined);
  const [kickModal, setKickModal] = React.useState(false);
  // Notification
  const [showNotif, setShowNotif] = React.useState(false)
  const [color, setColor] = React.useState()
  const [message, setMessage] = React.useState("")

  const dispatch = useDispatch()
  const allParticipants = useSelector(state => state.tournament.tournamentParticipants)

  const getCountries = async () => {
    sharedDataService.getCountries().then((response) => {
      setAllCountries(response.data.data);
    });
  };
  const kickToggle = (playerData) => {
    if (playerData !== undefined) {
      setPlayer(playerData);
    }
    else setPlayer(undefined);
    setKickModal(!kickModal)
  }
  const kickPlayer = async (PlayerId) => {
    try {
      tournamentService.kickPlayer({ tournamentId: id, PlayerId }).then((response) => {
        dispatch(getTournamentParticipants(id))
        kickToggle();
        setColor("2");
        setMessage("Player kicked successfully from tournament!");
        setShowNotif(true);
        setTimeout(() => {
          setShowNotif(false)
        }, 2000);
      })
    } catch (error) {
      console.log(error.message);
    }
  }
  React.useEffect(() => {
    dispatch(getTournamentParticipants(id))
    getCountries();
  }, []);
  React.useEffect(() => {
    if (allParticipants !== undefined)
      setLoading(false)
  }, [allParticipants]);

  return (
    <>
      <div className="content">
        {showNotif && <Notification color={color} message={message} />}
        <Modal isOpen={kickModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={kickToggle} >
          <ModalHeader toggle={kickToggle}>Kick Player</ModalHeader>
          <ModalBody>
            Are you sure you want to kick {player && player?.player?.username} from this tournament?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => kickPlayer(player.player._id)}>Yes</Button>{' '}
            <Button color="secondary" onClick={() => kickToggle()}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <div className="table-full-width table-responsive" style={{
                  maxHeight: '650px',
                  overflowY: 'auto'
                }}>
                  {loading ? <Progress><img src={ProgressGIF} /></Progress> :
                    allParticipants.length === 0 ?
                      <NoParticipants>
                        <img src={ThinkingIcon} />
                        <p>Still no registrations yet!<br />
                          Are you really a tournament organizer?</p>
                      </NoParticipants> :
                      <Table>
                        <thead className="text-primary" style={{ position: "sticky" }}>
                          <tr>
                            <th className="text-center">All players</th>
                            <th className="text-center">Country</th>
                            <th className="text-center">Subscription</th>
                            <th className="text-center">Game ID</th>
                            <th className="text-center">Total Wins</th>
                            <th className="text-center">Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allParticipants.map((p, index) => (
                            <ParticipantRow
                              key={index}
                              index={index}
                              participant={p}
                              allCountries={allCountries}
                              kick={kickToggle}
                            />
                          ))}
                        </tbody>
                      </Table>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Participants;
