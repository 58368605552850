const GET_ALL_TOURNAMENTS_ACTION = (payload) => {
    return {
        type: "GET_ALL_TOURNAMENTS",
        data: payload

    }
}
const GET_TOURNAMENT_PARTICIPANTS_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_PARTICIPANTS",
        data: payload
    }
}
const GET_GROUPS_KNOCKOUTS_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_GROUPS_KNOCKOUTS_PLAYERS",
        data: payload
    }
}
const GET_GROUPS_SEMIFINAL_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_GROUPS_SEMIFINAL_PLAYERS",
        data: payload
    }
}
const GET_GROUPS_FINAL_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_GROUPS_FINAL_PLAYERS",
        data: payload
    }
}
const GET_WINNERS_KNOCKOUTS_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_WINNERS_KNOCKOUTS_PLAYERS",
        data: payload
    }
}

const GET_WINNERS_SEMIFINAL_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_WINNERS_SEMIFINAL_PLAYERS",
        data: payload
    }
}

const GET_WINNERS_FINAL_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_WINNERS_FINAL_PLAYERS",
        data: payload
    }
}

const GET_BRACKET_PLAYERS_ACTION = (payload) => {
    return {
        type: "GET_TOURNAMENT_BRACKETS_PLAYERS",
        data: payload
    }
}

export {
    GET_ALL_TOURNAMENTS_ACTION, GET_TOURNAMENT_PARTICIPANTS_ACTION, GET_GROUPS_KNOCKOUTS_ACTION, GET_GROUPS_SEMIFINAL_ACTION, GET_GROUPS_FINAL_ACTION,
    GET_WINNERS_KNOCKOUTS_ACTION, GET_WINNERS_SEMIFINAL_ACTION, GET_WINNERS_FINAL_ACTION,
    GET_BRACKET_PLAYERS_ACTION
}