import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Row } from "reactstrap";
import styled from 'styled-components';
import GenerateGroups from './GenerateGroups';
import Knockouts from './Knockouts';
import SemiFinal from './SemiFinal';
import Final from './Final';

const GenerateButton = styled.div`
    position: absolute;
    top: 90px;
    right: 16px;
    cursor: pointer;
    z-index: 20;
    button.generate{
        width:200px;
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
    }
`;
const Nav = styled.nav`
    margin-top: 10px;
    border-radius: 21px;
    background: rgba(101,111,181,.15);
    height: 41px;
    width: 536px;
`;
const NavMenu = styled.div`
    text-align: center;
    button{
        cursor: pointer;
        padding: 10px 50px 0 50px;
        background: transparent;
        color: white;
        border: none;
    }
`;
const Groups = ({ id, format }) => {
    const [modal, setModal] = React.useState(false)
    const [active, setActive] = React.useState('knockouts')

    const toggle = () => {
        setModal(!modal)
    }
    const knockouts = useSelector(state => state.tournament.knockoutsGroupsPlayers)
    const semiFinal = useSelector(state => state.tournament.semiFinalGroupsPlayers)
    const final = useSelector(state => state.tournament.finalGroupsPlayers)

    return (
        <>
            {modal && <GenerateGroups toggle={toggle} modal={modal} id={id} format={format} />}
            <div className="content">
                {(active === 'knockouts' && knockouts.length !== 0) &&
                    <GenerateButton>
                        <Button className='generate' onClick={() => toggle()}>Regenerate knockouts Groups</Button>
                    </GenerateButton>
                }
                {(active === 'semiFinal' && semiFinal.length !== 0) &&
                    <GenerateButton>
                        <Button className='generate' onClick={() => toggle()}>Regenerate semi-final Groups</Button>
                    </GenerateButton>
                }
                {(active === 'final' && final.length !== 0) &&
                    <GenerateButton>
                        <Button className='generate' onClick={() => toggle()}>Regenerate final Groups</Button>
                    </GenerateButton>
                }
            </div>
            <Row>
                <Col md="12">
                    <Card style={{ alignItems: "center", background: "none" }}>
                        <Nav>
                            <NavMenu>
                                <button onClick={() => setActive('knockouts')} >
                                    Knockouts
                                </button>
                                {format === "Groups" &&
                                    <>
                                        <button onClick={() => setActive('semiFinal')} >
                                            Semi final
                                        </button>

                                        <button onClick={() => setActive('final')} >
                                            Final
                                        </button>
                                    </>
                                }

                            </NavMenu>
                        </Nav>
                        {active === 'knockouts' && <Knockouts id={id} toggle={toggle} />}
                        {active === 'semiFinal' && <SemiFinal id={id} toggle={toggle} />}
                        {active === 'final' && <Final id={id} toggle={toggle} />}
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default Groups;