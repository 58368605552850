import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import { AvatarIcon, BlueLockIcon, AddAvatarIcon, RemoveIcon, GreyLockIcon } from 'assets/img';
import authService from 'services/auth.service';
import { fetchAvatars } from 'redux/reducers/adminSlice';
import { useDispatch } from 'react-redux';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

`
const AvatarsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
`

const ContainerOfAvatar = styled.div`
    position: relative;
    min-height: 50px;
    min-width: 50px;
    margin: 10px 0 10px 0;
`
const SideImagesOfAvatarContainerOne = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    max-width: 20px;
    cursor: pointer;
    img{
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50% !important;
    }

`
const SideImagesOfAvatarContainerTwo = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    max-width: 20px;
    cursor: pointer;
    img{
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50% !important;

    }
`
const AddAvatar = ({ modal, toggle }) => {
    const [data, setData] = React.useState([])
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const formData = React.useMemo(() => new FormData(), []);
    const dispatch = useDispatch();
    const publishAvatars = async () => {
        console.log(data);
        if (data.length !== 0) {
            for (let i = 0; i < data.length; i++) {
                formData.set("avatar", data[i].avatar);
                formData.set("state", data[i].state);
                formData.set("exclusive", data[i].exclusive);
                authService.addAvatar(formData)
            }
            dispatch(fetchAvatars({ skip: 0 }))
            setColor("2");
            setMessage("Avatar(s) saved successfully!");
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
                toggle();
            }, 2000);

        }
        else {
            setColor("3");
            setMessage("Please upload new avatars!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const loadFile = (e) => {
        const [file] = e.target.files;
        setData([...data, { "avatar": e.target.files[0], "exclusive": false, "state": false, "showAvatar": URL.createObjectURL(file) }]);
    };

    const removeAvatar = (e, index) => {
        setData([...data.filter((item, i) => i !== index)]);
    }

    const isPremiumState = (e, index) => {
        data[index].state = !data[index].state
        setData([...data]);
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <img src={AvatarIcon} /> Avatars
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <AvatarsContainer className='button-container'>
                                        {data.map((a, index) =>
                                            <ContainerOfAvatar>
                                                <SideImagesOfAvatarContainerOne><img src={RemoveIcon} onClick={(e) => removeAvatar(e, index)} alt={index} /></SideImagesOfAvatarContainerOne>
                                                {a.state ?
                                                    <SideImagesOfAvatarContainerTwo><img src={BlueLockIcon} onClick={(e) => isPremiumState(e, index)} alt={index} /></SideImagesOfAvatarContainerTwo> :
                                                    <SideImagesOfAvatarContainerTwo><img src={GreyLockIcon} onClick={(e) => isPremiumState(e, index)} alt={index} /></SideImagesOfAvatarContainerTwo>
                                                }
                                                <img src={a.showAvatar} className="avatar" alt={index} key={index} />
                                            </ContainerOfAvatar>

                                        )}
                                        <Button className='add'>
                                            <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { loadFile(e) }} name="avatar" />
                                        </Button>
                                    </AvatarsContainer>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className='post' onClick={() => publishAvatars()}>Publish</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(AddAvatar);