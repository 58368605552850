import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import tournamentService from 'services/tournament.service';
import { getGroups } from 'redux/reducers/tournamentReducer';
import { useDispatch } from 'react-redux';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const GenerateGroups = ({ modal, toggle, id, format }) => {
    const [data, setData] = React.useState({ groupSize: undefined, tournament_id: id, lvl: "knockouts" })
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const dispatch = useDispatch()

    const generateGroups = async () => {
        if (data.groupSize !== undefined) {
            tournamentService.generateGroups(data).then((response) => {
                if (response.data.error === true) {
                    setColor("3");
                    setMessage(response.data.message)
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
                else {
                    setColor("2");
                    setMessage("Groups generated successfully!");
                    setShowNotif(true);
                    dispatch(getGroups(data))
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }
            }).catch((err) => {
                console.log(err.message);
            })
        }
        else {
            setColor("3");
            setMessage("Please enter the group size")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const inputHandler = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Submit results" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                Generate groups
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Enter group size </label>
                                    <Input placeholder="Group size" value={data.groupSize} onChange={inputHandler} type="number" name="groupSize" />
                                </FormGroup>
                            </Col>
                        </Row>
                        {format !== "Single Elimination" &&
                            <Row style={{ width: "100%" }}>
                                <Col className="px-md-1" md="12">
                                    <FormGroup>
                                        <label>Choose level </label><br />
                                        <select placeholder="Level" value={data.lvl} onChange={inputHandler} name="lvl">
                                            <option value="knockouts">Knockouts</option>
                                            <option value="semiFinal">Semi Final</option>
                                            <option value="final">Final</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>}
                        <Button className='post' onClick={() => generateGroups()}>Submit</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(GenerateGroups);