import React from "react";
import { Card, CardBody, Table, Row, Col, CardHeader, CardTitle, Input, Button } from "reactstrap";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginationBox from "components/UI/PaginationBox";
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/profileSlice';
import Loader from "components/UI/Loader";
import { ThinkingIcon, BanIcon, GDIcon, PremiumSubscription, UsersIcon } from "assets/img";
import { fetchProfilesWithPagination } from "redux/reducers/profileSlice";
import BanUser from "components/User/BanUser";
import { useNavigate } from "react-router-dom";
import GrantGD from "components/Tournament/GD/GrantGD";
import NoData from "components/UI/NoData";
import moment from "moment";

const NumberDiv = styled.div`
    width: 35px;
    font-size: 20px;
    text-align: center;
    border-radius: 13px;
    background-image: linear-gradient(to bottom, rgba(27, 37, 115,.13), rgba(255, 70, 94,.13));
`
const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .ban{
        cursor: pointer;
    }
    .grantGD{
        cursor: pointer;
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
`
const SearchDiv = styled.div`
    display: flex;
    gap: 10px;
    input{
        background-color: #322e60;
        border-radius: 17px;
        width: 250px;
    }
    > * {
        flex: 0 0 auto;
    }
    button{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
`
const SubscriptionDiv = styled.div`
display: flex;
gap: 10px;
select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: fit-content;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
`

const Users = () => {
    const [subscription, setSubscription] = React.useState("All");
    const [banned, setBanned] = React.useState("All");
    const [profileId, setProfileId] = React.useState();
    const [username, setUsername] = React.useState();
    const [searchedName, setSearchedName] = React.useState();
    const [modalBan, setModalBan] = React.useState(false);
    const [modalGD, setModalGD] = React.useState(false);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    // check if e sports manager
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, allProfiles, pageNumber } = useSelector((state) => state.profiles, shallowEqual)

    React.useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile) {
            setIsSuperAdmin(profile.roleId.title === 'super admin');
        }
        getAllProfiles();
    }, [pageNumber])

    const prepareModalData = (id, name) => {
        if (id !== undefined) {
            setProfileId(id);
            setUsername(name);
        } else {
            setProfileId(undefined);
            setUsername(undefined);
        }
        dispatch(fetchProfilesWithPagination({ pageNumber, skip, name: searchedName, subscription, banned }))
    }
    const toggleBan = (id, name) => {
        prepareModalData(id, name)
        setModalBan(!modalBan);
    }
    const toggleGD = (id, name) => {
        prepareModalData(id, name)
        setModalGD(!modalGD);
    }

    const getAllProfiles = () => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        dispatch(fetchProfilesWithPagination({ pageNumber, skip, name: searchedName, subscription, banned }))
    }
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    const skipToFirstPage = () => {
        for (let i = pageNumber; i > 1; i--) {
            decrementPage()
        }
    }
    const navigateToProfile = (id) => {
        navigate(id);
    };

    const searchProfile = (subscriptionType, isBanned) => {
        dispatch(fetchProfilesWithPagination({ pageNumber: 1, skip: 0, name: searchedName, subscription: subscriptionType, banned: isBanned }))
    }

    return (
        <>
            <div className="content">
                {modalBan && <BanUser modal={modalBan} toggle={toggleBan} profileId={profileId} username={username} />}
                {modalGD && <GrantGD modal={modalGD} toggle={toggleGD} profileId={profileId} username={username} />}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={UsersIcon} alt="Store" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Users</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SearchDiv>
                                            <h4>Search profile:</h4>
                                            <Input placeholder="Search profile" type="text" onChange={(e) => setSearchedName(e.target.value)} onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    searchProfile(subscription, banned)
                                                }
                                            }} name="name" />
                                            <Button onClick={() => searchProfile(subscription, banned)}>Search</Button>
                                        </SearchDiv>
                                        <SubscriptionDiv>
                                            <h5>Subscription: </h5>
                                            <select defaultValue="" onChange={(e) => { setSubscription(e.target.value); searchProfile(e.target.value, banned) }} name="subscription">
                                                <option key={1} value="All">All</option>
                                                <option key={2} value="Premium">Premium</option>
                                                <option key={3} value="Regular">Regular</option>
                                            </select>
                                            <h5>Display: </h5>
                                            <select defaultValue="All" onChange={(e) => { setBanned(e.target.value); searchProfile(subscription, e.target.value); }} name="isBanned">
                                                <option key={1} value="All">All</option>
                                                <option key={2} value="true">Banned</option>
                                                <option key={3} value="false">Not banned</option>
                                            </select>
                                        </SubscriptionDiv>
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* <Filters /> */}
                                <div className="table-full-width table-responsive" style={{
                                    height: '565px',
                                    overflowY: 'auto',
                                }}>
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        !allProfiles.data ? <NoData text={"Your search did not match any player's username!"} /> :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Player</th>
                                                        <th>Subscription</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allProfiles.data.map((p, index) => {
                                                        return (
                                                            <tr key={index} style={{ backgroundColor: p.banned?.status && '#605176' }}>
                                                                <td style={{ cursor: "pointer" }} >{/* onClick={() => { navigateToProfile(p._id) }}> */}
                                                                    <div style={{ display: "inline-flex", gap: "30px" }}>
                                                                        <NumberDiv style={{ color: '#fe4958' }}>{skip + index + 1}</NumberDiv>
                                                                        <div className="photo">
                                                                            <img src={p.avatar} />
                                                                        </div>
                                                                        {p.username}
                                                                    </div>
                                                                </td>
                                                                <td>{p.isPremium.status ? <img src={PremiumSubscription} style={{ width: "29px", height: "29px" }} /> : "Regular"}</td>
                                                                <td>
                                                                    {p.banned?.status && "Ban ends: " + moment.utc(p.banned.bannedUntil).format('ll') + " " + moment.utc(p.banned.bannedUntil).format('LT')}
                                                                </td>
                                                                <td>
                                                                    {p.banned?.status && "Reason: " + p.reason}
                                                                </td>
                                                                <td>
                                                                    <ActionStyle>
                                                                        <div className='ban'>
                                                                            <img src={BanIcon} alt="Ban" onClick={() => toggleBan(p._id, p.username)} />
                                                                        </div>
                                                                        {isSuperAdmin &&
                                                                            <div className='grantGD'>
                                                                                <img src={GDIcon} style={{ width: "25px", height: "25px" }} alt="Grant GD" onClick={() => toggleGD(p._id, p.username)} />
                                                                            </div>
                                                                        }
                                                                    </ActionStyle>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(allProfiles.totalNbOfProfiles / limit)} totalNumber={allProfiles.totalNbOfProfiles} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Users;
