import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import order from '../../services/order.service'

const initialState = {
    loading: false,
    GdProfilesHistory: [],
    pageNumber: 1,
    error: ''
}

const GdSlice = createSlice({
    name: 'gd',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;

        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGdProfilesHistory.fulfilled, (state, action) => {
            state.GdProfilesHistory = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchGdProfilesHistory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGdProfilesHistory.rejected, (state, action) => {
            state.GdProfilesHistory = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(fetchGdInHistory.fulfilled, (state, action) => {
            state.GdProfilesHistory = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchGdInHistory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGdInHistory.rejected, (state, action) => {
            state.GdProfilesHistory = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(fetchGdOutHistory.fulfilled, (state, action) => {
            state.GdProfilesHistory = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchGdOutHistory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchGdOutHistory.rejected, (state, action) => {
            state.GdProfilesHistory = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchGdProfilesHistory = createAsyncThunk('gd/getProfilesGdHistory', async (payload) => {
    const orders = await order.getProfilesGdHistory(payload)
    return (orders.data);
})

export const fetchGdInHistory = createAsyncThunk('gd/gdInHistory', async (payload) => {
    const inHistory = await order.getGdInHistory(payload)
    return (inHistory.data);
})

export const fetchGdOutHistory = createAsyncThunk('gd/gdOutHistory', async (payload) => {
    const outHistory = await order.getGdOutHistory(payload)
    return (outHistory.data);
})


export const { incrementPageNumber, decrementPageNumber } = GdSlice.actions
export default GdSlice.reducer;