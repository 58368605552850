import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import support from '../../services/support.service'

const initialState = {
    loading: false,
    supportCases: [],
    pageNumber: 1,
    error: ''
}

const SupportSlice = createSlice({
    name: 'supportCases',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;

        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSupportCases.fulfilled, (state, action) => {
            state.supportCases = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchSupportCases.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchSupportCases.rejected, (state, action) => {
            state.supportCases = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchSupportCases = createAsyncThunk('support/getSupportCases', async (payload) => {
    const cases = await support.getSupportCases(payload)
    return (cases.data);
})


export const { incrementPageNumber, decrementPageNumber } = SupportSlice.actions
export default SupportSlice.reducer;