import tournament from '../../services/tournament.service'
import {
    GET_ALL_TOURNAMENTS_ACTION, GET_TOURNAMENT_PARTICIPANTS_ACTION,
    GET_GROUPS_FINAL_ACTION, GET_GROUPS_KNOCKOUTS_ACTION, GET_GROUPS_SEMIFINAL_ACTION,
    GET_WINNERS_KNOCKOUTS_ACTION, GET_WINNERS_SEMIFINAL_ACTION, GET_WINNERS_FINAL_ACTION,
    GET_BRACKET_PLAYERS_ACTION
} from '../actions/tournamentActions'

const initState = {
    allTournaments: [],
    tournamentParticipants: [],
    bracketsPlayers: [],
    knockoutsGroupsPlayers: [],
    semiFinalGroupsPlayers: [],
    finalGroupsPlayers: [],
    knockoutsWinners: [],
    semiFinalWinners: [],
    finalWinners: []
}

const tournamentReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
        case "GET_ALL_TOURNAMENTS":
            return { ...state, allTournaments: action.data }
        case "GET_TOURNAMENT_PARTICIPANTS":
            return { ...state, tournamentParticipants: action.data }
        case "GET_TOURNAMENT_BRACKETS_PLAYERS":
            return { ...state, bracketsPlayers: action.data }
        case "GET_TOURNAMENT_GROUPS_KNOCKOUTS_PLAYERS":
            return { ...state, knockoutsGroupsPlayers: action.data }
        case "GET_TOURNAMENT_GROUPS_SEMIFINAL_PLAYERS":
            return { ...state, semiFinalGroupsPlayers: action.data }
        case "GET_TOURNAMENT_GROUPS_FINAL_PLAYERS":
            return { ...state, finalGroupsPlayers: action.data }
        case "GET_TOURNAMENT_WINNERS_KNOCKOUTS_PLAYERS":
            return { ...state, knockoutsWinners: action.data }
        case "GET_TOURNAMENT_WINNERS_SEMIFINAL_PLAYERS":
            return { ...state, semiFinalWinners: action.data }
        case "GET_TOURNAMENT_WINNERS_FINAL_PLAYERS":
            return { ...state, finalWinners: action.data }
    }
}

const getTournaments = () => async (dispatch, getState) => {
    var tournaments = await tournament.getAllTournaments()
    dispatch(GET_ALL_TOURNAMENTS_ACTION(tournaments.data.data))
}

const getTournamentParticipants = (id) => async (dispatch, getState) => {
    var participants = await tournament.getTournamentPlayersProfile(id)
    dispatch(GET_TOURNAMENT_PARTICIPANTS_ACTION(participants.data.data))
}

const getBracketsPlayers = (id) => async (dispatch, getState) => {
    var players = await tournament.getBracketPlayers(id);
    dispatch(GET_BRACKET_PLAYERS_ACTION(players.data.data))
}
const getGroups = (payload) => async (dispatch, getState) => {
    var groups = await tournament.getGroupPlayers(payload)
    if (payload.lvl === "knockouts")
        dispatch(GET_GROUPS_KNOCKOUTS_ACTION(groups.data.data))
    else if (payload.lvl === "semiFinal")
        dispatch(GET_GROUPS_SEMIFINAL_ACTION(groups.data.data))
    else
        dispatch(GET_GROUPS_FINAL_ACTION(groups.data.data))
}

const getWinners = (payload) => async (dispatch, getState) => {
    var winners = await tournament.getWinners(payload)
    if (payload.lvl === "knockouts")
        dispatch(GET_WINNERS_KNOCKOUTS_ACTION(winners.data.data))
    else if (payload.lvl === "semiFinal")
        dispatch(GET_WINNERS_SEMIFINAL_ACTION(winners.data.data))
    else
        dispatch(GET_WINNERS_FINAL_ACTION(winners.data.data))
}


export { getTournaments, getTournamentParticipants, getBracketsPlayers, getGroups, getWinners, tournamentReducer }