import React from 'react'
import { Row, Col, FormGroup, Form, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../utils/notification';
import NotificationService from 'services/notification.service';
import tournamentService from 'services/tournament.service';
import profileService from 'services/profile.service';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const TournamentNotifications = ({ modal, toggle }) => {
    const [data, setData] = React.useState({ title: undefined, link: "" })
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [tournaments, setTournaments] = React.useState([]);

    const sendNotifications = async () => {
        if (data.title !== undefined && data.link !== "") {
            let body
            const tournament = tournaments.find(({ _id }) => _id === data.link)
            switch (data.title) {
                case "Out of slots? Hurry up!":
                    body = "Dear participant, registrations for " + tournament.title + " will close in 24 hours."
                    break;
                case "Knock! Knock!":
                    body = "Dear participant, " + tournament.title + " will start in 24 hours, buckle up champ!"
                    break;
                case "The Battle is nigh!":
                    body = "Dear participant, " + tournament.title + " will start in 1 hour, buckle up champ!"
                    break;
                case "It’s not over yet!":
                    body = tournament.title + " recently concluded! More to come!"
                    break;
                case "Join the Community Discord!":
                    body = "Dear Participant, please join our Community Discord Server to know more about Tournaments!"
                    break;
                case "Knowledge is key!":
                    body = "Dear participant, make sure to always read the Tournament rules! For more information please join our Discord server."
                    break;
                case "Check-in is now Open!":
                    body = "Dear Participant, Check-in is now available on Discord please proceed to verify your Tournament entry!"
                    break;
                case "Tournament updated!":
                    body = "Dear Participant, changes have been made on " + tournament.title + " that you have recently participated into, have a look!"
                    break;
                default:
                    break;
            }

            if (["Knock! Knock!", "The Battle is nigh!", "It’s not over yet!", "Check-in is now Open!", "Tournament updated!"].includes(data.title)) {
                // send notifications to specific participants
                const payload = {
                    categoryType: "TOURNAMENT", notifType: "INFO", from: "T7D", body, title: data.title, link: data.link
                }
                if (data.title === "Check-in is now Open!") payload.link = "https://discord.com/channels/866709892047700069/1005864411333922956"
                NotificationService.sendNotificationsToTournamentParticipants(tournament._id, payload).then((response) => {
                    if (response.data.error === true) {
                        setColor("3");
                        setMessage(response.data.message)
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                        }, 7000);
                    }
                    else {
                        setColor("2");
                        setMessage("Notifications sent successfully!");
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                            toggle();
                        }, 2000);
                    }
                }).catch((err) => {
                    console.log(err.message);
                })
            }
            else if (["Out of slots? Hurry up!", "Join the Community Discord!", "Knowledge is key!"].includes(data.title)) {
                // send notifications to tournament participants
                const payload = {
                    notifType: "INFO", categoryType: "TOURNAMENT", playerGameIds: [tournament.gameId._id], from: "T7D", body, title: data.title, link: data.link
                }
                if (data.title === "Join the Community Discord!") payload.link = "https://discord.gg/t7d"

                NotificationService.sendNotificationsToSpecificProfiles(payload).then((response) => {
                    if (response.data.error === true) {
                        setColor("3");
                        setMessage(response.data.message)
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                        }, 7000);
                    }
                    else {
                        setColor("2");
                        setMessage("Notifications sent successfully!");
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                            toggle();
                        }, 2000);
                    }
                }).catch((err) => {
                    console.log(err.message);
                })
            }
        }
        else {
            setColor("3");
            setMessage("Please select a template and enter the tournament ID")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const inputHandler = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const getTournaments = async () => {
        tournamentService.getAllTournaments({ skip: 0, limit: 20, status: "Active" }).then(response => {
            setTournaments(response.data.data)
        })
    }

    React.useEffect(() => {
        getTournaments();
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Submit results" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                Send Tournament notifications
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Choose notification template </label><br />
                                    <select defaultValue="" value={data.title} onChange={inputHandler} name="title">
                                        <option value="" disabled hidden>Select template</option>
                                        <option value="Out of slots? Hurry up!">Registrations End Notification</option>
                                        <option value="Knock! Knock!">Knock! Knock! Notification</option>
                                        <option value="The Battle is nigh!">The Battle is nigh! Notification</option>
                                        <option value="It’s not over yet!">Tournament Closure Notification</option>
                                        <option value="Join the Community Discord!">Discord</option>
                                        <option value="Knowledge is key!">Rules check</option>
                                        <option value="Check-in is now Open!">Tournament Check-in Reminder</option>
                                        <option value="Tournament updated!">Tournament Updates</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Enter tournament id </label>
                                    <select defaultValue="" value={data.link} onChange={inputHandler} name="link">
                                        <option value="" disabled hidden>Select tournament</option>
                                        {tournaments.map((t) => {
                                            return (
                                                <option key={t._id} value={t._id}>{t.title}</option>
                                            );
                                        })}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className='post' onClick={() => sendNotifications()}>Send</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(TournamentNotifications);