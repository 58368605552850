import React from 'react';
import axios from 'axios';
import { EditIcon, ApproveIcon, CancelIcon } from '../../../assets/img';
import { Input } from "reactstrap";
import styled from 'styled-components';
import tournamentService from 'services/tournament.service';

const ActionDiv = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    .cancel{
        cursor: pointer;
    }
    .submit{
        cursor: pointer;
    }
`
const EditStyle = styled.div`
    cursor: pointer;
`
const InputDiv = styled.div`
    display: grid;
    justify-content: center;
`
const InputStyle = styled(Input)`
    text-align: center;
    height: 15px;
    width: 60px;
`
const GroupPlayerRow = ({ index, player, tournamentId, lvl, showedTr }) => {
    const [editable, setEditable] = React.useState(false);

    const [data, setData] = React.useState({ goals: 0, kills: 0, placement: 0, deaths: 0, assist: 0, overallScore: 0 })
    const [prevData, setPrevData] = React.useState({ goals: 0, kills: 0, placement: 0, deaths: 0, assist: 0, overallScore:0 })

    const inputHandler = (e) => {
        const { name, value } = e.target
        setData(values => ({ ...values, [name]: value }))
    }
    
    React.useEffect(() => {
        setData(values => ({
            ...values,
            // goals: player.score.goals,
            kills: player.score.kills,
            placement: player.score.placement,
            deaths: player.score.deaths,
            assist: player.score.assist,
            overallScore: player.score.overallScore
        }))
    }, [])

    const activateEditable = (player) => {
        setPrevData(values => ({
            ...values,
            // goals: player.score.goals,
            kills: data.kills,
            placement: data.placement,
            deaths: data.deaths,
            assist: data.assist,
            overallScore: data.overallScore
        }))
        setEditable(!editable)
    }
    const submitResults = async () => {
        data.tournament_id = tournamentId
        data.groupNumber = player.score.groupNumber
        data.lvl = lvl
        data.participationId = player.participationId
        tournamentService.submitParticipationScore(data).then(response => {
            setEditable(false)
        }).catch((error) => {
            console.log(error.message);
        })
    }
    const cancel = async () => {
        setData(values => ({
            ...values,
            // goals: player.score.goals,
            kills: prevData.kills,
            placement: prevData.placement,
            deaths: prevData.deaths,
            assist: prevData.assist,
            overallScore: prevData.overallScore
        }))
        setEditable(false)
    }

    return (<>
        {/* {showNotif && <Notification color={color} message={message} />} */}
        <tr key={index} >
            <td>{player.user.username}</td>
            {showedTr.goals && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.goals} name="goals" /></InputDiv> : data.goals}</td>}
            {showedTr.kills && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.kills} name="kills" /></InputDiv> : data.kills}</td>}
            {showedTr.placement && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.placement} name="placement" /></InputDiv> : data.placement}</td>}
            {showedTr.deaths && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.deaths} name="deaths" /></InputDiv> : data.deaths}</td>}
            {showedTr.assist && <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.assist} name="assist" /></InputDiv> : data.assist}</td>}
            <td className="text-center">{editable ? <InputDiv><InputStyle type="number" min="1" onChange={inputHandler} value={data.overallScore} name="overallScore" /></InputDiv> : data.overallScore}</td>
            <td className="text-center">
                {editable ?
                    <ActionDiv>
                        <div className="cancel" onClick={cancel}><img src={CancelIcon} /></div>
                        <div className='submit' onClick={submitResults}><img src={ApproveIcon} /></div>
                    </ActionDiv> :
                    <EditStyle onClick={() => activateEditable(player)}> <img src={EditIcon} /> </EditStyle>
                }

            </td>
        </tr>
    </>

    );
}

export default React.memo(GroupPlayerRow);