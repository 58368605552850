import axios from "axios";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://quest.t7d.io"
} else {
    BASE_URL = "https://staging.quest.t7d.io"
    // BASE_URL = "http://localhost:3333"
}

const getQuests = async () => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/quest/getQuestsForAdmin`
    });
}

const addQuest = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quest/create`,
        data: payload
    });
}

const updateQuest = async (id, payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quest/update/${id}`,
        data: payload
    });
}

const deleteQuest = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/quest/delete/${id}`
    });
}

export default {
    getQuests, addQuest, updateQuest, deleteQuest
}