import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profile from '../../services/profile.service'

const initialState = {
    loading: false,
    allProfiles: [],
    pageNumber: 1,
    error: ''
}

const ProfilesSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;

        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfilesWithPagination.fulfilled, (state, action) => {
            state.allProfiles = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchProfilesWithPagination.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchProfilesWithPagination.rejected, (state, action) => {
            state.allProfiles = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchProfilesWithPagination = createAsyncThunk('profiles/getProfilesWithPagination', async (payload) => {
    const profiles = await profile.GetAllProfilesWithPagination(payload)
    return (profiles.data);
})


export const { incrementPageNumber, decrementPageNumber } = ProfilesSlice.actions
export default ProfilesSlice.reducer;