
import React from 'react';
import { EditIcon, DeleteIcon, QuestIcon, RepeatIcon } from '../assets/img';
// reactstrap components
import {
    Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap";
import { Stack, TablePagination } from '@mui/material';
import questService from 'services/quest.service';
import styled from 'styled-components';
import Notification from 'utils/notification';
import GreenButton from 'components/UI/GreenButton';
import CreateUpdateQuest from 'components/Quest/CreateUpdateQuest';

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .edit{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`
function Quests() {
    const [quests, setQuests] = React.useState([])
    const [quest, setQuest] = React.useState()
    const [questId, setQuestId] = React.useState()
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Toggle for Modal
    const toggle = (q) => {
        if (q !== undefined) {
            setQuest(q)
        } else {
            setQuest(undefined)
        }
        fetchQuests()
        setModal(!modal)
    }

    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }

    const fetchQuests = async () => {
        const allQuests = await questService.getQuests();
        setQuests(allQuests.data);
    }
    React.useEffect(() => {
        fetchQuests();
    }, [])

    const deleteQuest = async (id) => {
        try {
            questService.deleteQuest(id).then((response) => {
                setQuestId("");
                fetchQuests();
                deleteToggle();
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={QuestIcon} alt="Quizzes" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Quests</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" style={{
                                    maxHeight: '620px',
                                    overflowY: 'auto'
                                }}>
                                    <Table>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Quest name</th>
                                                <th className="text-center">Gd reward</th>
                                                <th className="text-center">Number of tasks</th>
                                                <th className="text-center">Frequency</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(rowsPerPage > 0
                                                ? quests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : quests
                                            )
                                                .map((q, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td className="text-center" style={{ cursor: "pointer" }} >
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    {/* Image */}
                                                                    <div style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px", height: "80px", width: "50px", backgroundImage: `url(${q.icon})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                                                                    {/* End image */}
                                                                    <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", gap: "5px" }}>
                                                                        {q.name} <br />
                                                                        <p style={{ color: "#757ba8" }}>{q.description} </p>
                                                                        <div style={{ display: "flex", paddingLeft: "20px", gap: "10px" }}>
                                                                            {q.online ? <GreenButton text={"Online"} /> : <p style={{ color: "red" }}>Offline</p>}
                                                                            {q.repeatable && <img width="25px" height="25px" alt="repeat" src={RepeatIcon} />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">{q.gdReward} GD</td>
                                                            <td className="text-center">{q.numberOfTasks}</td>
                                                            <td className="text-center">{q.frequency}</td>
                                                            <td >
                                                                <ActionStyle>
                                                                    <div className='edit'><img src={EditIcon} alt="edit" onClick={() => { toggle(q) }} /></div>
                                                                    <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setQuestId(q._id) }} /></div>
                                                                </ActionStyle>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>

                            <Stack>
                                <TablePagination color='white'
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    count={quests.length}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    backIconButtonProps={{
                                        "aria-label": "Previous Page",
                                        style: { color: page === 0 ? "#b5b8c4" : "#7cb5ec" },
                                        autoid: "pagination-button-next-collector",
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page",
                                        style: { color: "#7cb5ec" },
                                        autoid: "pagination-button-previous-collector",
                                    }}
                                />
                            </Stack>
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                    <ModalHeader toggle={deleteToggle}>Delete Quest</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this Quest?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteQuest(questId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {modal && <CreateUpdateQuest modal={modal} toggle={toggle} quest={quest} />}
            </div>
        </>
    )
}

export default Quests;