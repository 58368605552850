import React from "react";
import styled from 'styled-components';
import main from './images/main_bg.png'
import seven from './images/seven.png'
import characterImage from './images/Smite-up.png'
import plus from './images/plus.png'
import {DevIcon, T7Dash} from '../../assets/img'
import Signin from "./signin";

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    position: relative;
    left: 0;
    top: 0;
    background-image: url(${main});
    background-size: cover;
    background-attachment: fixed;
    z-index: 165165;
    min-height:800px;
    overflow: hidden;


`
const ImgsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-image: url(${seven});
    background-size: 100%;
    background-repeat: no-repeat;
`

const Character = styled.div`
    position: relative;
    height: 80%;
    width: 100%;
    img{
        position: absolute;
        top: 3%;
        left: 0%;
        max-width: 100%;
    }

`
const PlusImage = styled.div`
    position: relative;
    height: 15%;
    width: 15%;
    img{
        position: absolute;
        top: -500%;
        left: 130%;
        max-width: 50%;
    }

`
const T7DLogo = styled.div`
    position: absolute;
    height: 10%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        position: absolute;
        max-width: 118px;
    }
    p{
        margin-top: 70px;
        color: #525f7f;
        font-size: 9px;
    }
`
const Footer = styled.div`
    position: fixed;
    left: 20px;
    bottom: 50px;
    color: #757ba8;
    text-align: left;
    font-size: 9px;
`

function index() {
    return (
        <>
            <Container>
                <T7DLogo>
                    <img alt="T7D" src={T7Dash} />
                    <p>Dashboard Version : 1.1.0.1</p>
                </T7DLogo>
                <ImgsContainer>
                    <Character>
                        <img alt="Character" src={characterImage} />
                    </Character>
                    <PlusImage>
                        <img alt="Plus" src={plus} />
                    </PlusImage>

                </ImgsContainer>
                <Signin />
                <Footer>
                    <img src={DevIcon} style={{width: "15px", height: "15px"}}/> {" "} A work of art by @Wifek
                </Footer>
            </Container>
        </>
    );
}

export default index;
