import React from 'react';
import moment from 'moment';
import { RedLike, Check, ClockIcon, Store, CheckoutLogo, FawriLogo, SobflousLogo, WalletIcon, ClickToPayIcon, KonnectIcon, GDIcon, File, ThinkingIcon, EditIcon } from '../assets/img';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PaginationBox from 'components/UI/PaginationBox';
import Loader from 'components/UI/Loader';
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/supportSlice';
import { fetchSupportCases } from 'redux/reducers/supportSlice';
import supportService from 'services/support.service';
import styled from 'styled-components';
import UpdateSupportCase from 'components/Support/UpdateSupportCase';
import NoData from 'components/UI/NoData';

const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
`
const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .update{
        cursor: pointer;
    }
`
const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: 250px;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
    
`
function Support() {
    const [supportCase, setSupportCase] = React.useState({});
    const [status, setStatus] = React.useState("-1");
    const [subject, setSubject] = React.useState("");
    const [types, setTypes] = React.useState([]);
    const [view, setView] = React.useState(false);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [modal, setModal] = React.useState(false);

    const dispatch = useDispatch();
    const allStatus = [
        { key: "0", value: "Pending" },
        { key: "1", value: "Resolved" },
        { key: "2", value: "Closed/ Denied" },
    ]

    const { loading, supportCases, pageNumber } = useSelector((state) => state.support, shallowEqual)

    const toggleUpdate = (supportCase, view) => {
        setSupportCase(supportCase)
        setView(view)
        filterData();
        setModal(!modal)
    }

    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }

    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }

    const getAllSupportCases = (pageNumber, skip) => {
        filterData();
    }

    const fetchTypes = async () => {
        await supportService.getSubjectTypes().then(response => {
            setTypes(response.data.data)
        })
    }
    const filterData = (subject, status) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        let filterBy = { pageNumber, skip }
        if (subject !== undefined && subject !== "") {
            filterBy.subject = subject;
        }
        if (status !== undefined && status !== "-1") {
            filterBy.status = [status];
        }
        dispatch(fetchSupportCases(filterBy))
    }

    React.useEffect(() => {
        getAllSupportCases(pageNumber, skip);
    }, [pageNumber])

    React.useEffect(() => {
        fetchTypes();
    }, [])


    return (
        <>
            {modal && <UpdateSupportCase modal={modal} toggle={toggleUpdate} supportCase={supportCase} view={view} />}
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={File} alt="Store" style={{ marginRight: "8px" }} /> Support cases</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SortFilterDiv>
                                            <h4>Filter by subject:</h4>
                                            <select defaultValue="" onChange={(e) => { setSubject(e.target.value); filterData(e.target.value, status); }} name="subject">
                                                <option value=""></option>
                                                {types && types.map((type) => {
                                                    return (
                                                        <option key={type._id} value={type._id}>{type.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                        <SortFilterDiv>
                                            <h4>Filter by status </h4>
                                            <select defaultValue="-1" onChange={(e) => { setStatus(e.target.value); filterData(subject, e.target.value); }} name="status">
                                                <option value="-1"></option>
                                                {allStatus.map((s) => {
                                                    return (
                                                        <option key={s.key} value={s.value}>{s.value}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody style={{ height: '620px', overflowY: 'scroll' }}>
                                <div className="table-full-width table-responsive" >
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (supportCases.data && supportCases.data.length === 0) ? <NoData text={"No support cases available!"} /> :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Username</th>
                                                        <th>Email</th>
                                                        <th>Subject</th>
                                                        <th>Problem</th>
                                                        <th>Date</th>
                                                        <th className="text-center">Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(!loading && supportCases.data) && supportCases.data.map((sc, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>
                                                                    {sc.profile &&
                                                                        <div style={{ display: "inline-flex", gap: "30px" }}>
                                                                            <div className="photo">
                                                                                <img src={sc.profile.avatar} />
                                                                            </div>
                                                                            {sc.profile.username}
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td>{sc.supportCase.email}</td>
                                                                <td>{sc.supportCase.subjectType.name}</td>
                                                                <td style={{ cursor: "pointer" }} onClick={() => toggleUpdate(sc, true)}>
                                                                    {sc.supportCase.content.slice(0, 30) + (sc.supportCase.content.length > 30 ? "..." : "")}
                                                                </td>
                                                                <td>{moment(sc.supportCase.createdAt).format('ll')} {moment(sc.supportCase.createdAt).format('LT')}</td>
                                                                <td className="text-center">{sc.supportCase.status === "Pending" ? <p style={{ color: "#e57d07" }}><img src={ClockIcon} alt="clock" /> {sc.supportCase.status}</p> :
                                                                    (sc.supportCase.status === "Resolved" ? <p style={{ color: "#23e35d" }}><img src={Check} alt="check" /> {sc.supportCase.status}</p> :
                                                                        <p style={{ color: "#fe4958" }}><img src={RedLike} alt="like" /> {sc.supportCase.status}</p>)}</td>
                                                                <td className="text-center">
                                                                    <ActionStyle>
                                                                        <div className='update'>
                                                                            <img src={EditIcon} alt="update" onClick={() => toggleUpdate(sc, false)} />
                                                                        </div>
                                                                    </ActionStyle>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(supportCases.totalNbOfSupportCases / limit)} totalNumber={supportCases.totalNbOfSupportCases} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Support;