import Dashboard from "views/Dashboard.js";
import Tournaments from "./views/Tournaments";
import Shop from "views/Shop";
import Users from "views/Users.js";
import * as RiIcons from "react-icons/ri";
import { DashboardIcon, Trophy, Store, Wallet, News, TrafficAnalyser, UsersIcon, File, Calendar, Logout, OrdersLogo, Vouchers, AvatarIcon, QuizzesIcon, LeaderBoard, RamadanIcon, GDIcon, QuestIcon, CategoryIcon, MiniGamesIcon, AdminsIcon } from './assets/img'
import Signin from "layouts/Signin/signin";
import Orders from "views/Orders";
import Providers from "views/Providers";
import Leaderboard from "views/Leaderboard";
import GD from "views/GD";
import Quests from "views/Quests";
import Support from "views/Support";
import Icons from "views/Icons";
import Quizzes from "views/Quizzes";
import MiniGames from "views/MiniGames";
import Roles from "config/Roles";
import Admins from "views/Admins";

var routes = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: <Dashboard />,
    layout: ""
  },
  {
    name: "Tournaments",
    icon: Trophy,
    layout: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS, Roles.ESPORTS_MANAGER],
    subNav: [
      {
        name: "Tournaments",
        path: "/Tournaments",
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS, Roles.ESPORTS_MANAGER],
        icon: Trophy
      },
      {
        name: "Avatars",
        path: "/Tournaments/Avatars",
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS_MANAGER],
        icon: AvatarIcon
      }
    ]
  },
  {
    path: "/Shop",
    name: "Shop",
    icon: Store,
    component: <Shop />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: ""
  },

  {
    path: "/Orders",
    name: "Orders",
    icon: OrdersLogo,
    component: <Orders />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: ""
  },

  {
    path: "/Providers",
    name: "Providers",
    icon: Vouchers,
    component: <Providers />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: ""
  },
  {
    path: "/GD",
    name: "GD",
    icon: GDIcon,
    component: <GD />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: ""
  },
  {
    name: "Quizzes",
    path: "/Quizzes",
    icon: QuizzesIcon,
    component: <Quizzes />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        name: "Categories",
        path: "/Quizzes/Categories",
        icon: CategoryIcon
      }
    ]
  },
  {
    path: "/Quests",
    name: "Quests",
    icon: QuestIcon,
    component: <Quests />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: ""
  },
  {
    path: "/Leaderboard",
    name: "Leaderboard",
    icon: LeaderBoard,
    component: <Leaderboard />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS, Roles.ESPORTS_MANAGER],
    layout: ""
  },
  // {
  //   path: "/wallet",
  //   name: "Wallet",
  //   icon: Wallet,
  //   component: <Notifications/>,
  //   layout: ""
  // },
  // {
  //   path: "/news",
  //   name: "News",
  //   icon: News,
  //   component: <UserProfile/>,
  //   layout: ""
  // },
  {
    path: "/Mini-games",
    name: "Mini games",
    icon: MiniGamesIcon,
    component: <MiniGames />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: "",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        name: "Categories",
        path: "/Mini-games/Categories",
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
        icon: CategoryIcon
      }
    ]
  },
  // {
  //   path: "/traffic-analyzer",
  //   name: "Traffic analyzer",
  //   icon: TrafficAnalyser,
  //   component: <Typography/>,
  //   layout: ""
  // },
  {
    path: "/Users-manager",
    name: "Users manager",
    icon: UsersIcon,
    component: <Users />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.ESPORTS_MANAGER, Roles.ESPORTS],
    layout: ""
  },
  {
    path: "/Admins",
    name: "Admins",
    icon: AdminsIcon,
    component: <Admins />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
    layout: ""
  },
  {
    path: "/Support",
    name: "Support cases",
    icon: File,
    component: <Support />,
    permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.SOCIAL_MEDIA],
    layout: ""
  },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: Calendar,
  //   component: <UserProfile/>,
  //   layout: ""
  // },
  {
    path: "/signin",
    name: "Logout",
    icon: Logout,
    component: <Signin />,
    layout: ""
  }
];
export default routes;
