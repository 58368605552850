import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../utils/notification';
import supportService from 'services/support.service';
import { File } from 'assets/img';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input:read-only, textarea:read-only {
        background-color: #322e60;
        color: white;
        border-radius: 17px;
    } 
    textarea{
        height:400px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const UpdateSupportCase = ({ modal, toggle, supportCase, view }) => {
    const [currStatus, setCurrStatus] = React.useState(supportCase.supportCase.status)
    const [response, setResponse] = React.useState(supportCase.supportCase.response)
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const allStatus = [
        { key: "0", value: "Pending" },
        { key: "1", value: "Resolved" },
        { key: "2", value: "Closed/ Denied" },
    ]

    const updateStatus = async (username) => {
        if (response !== "" && response !== "N/A" && currStatus != "Pending") {
            supportService.updateSupportCase(supportCase.supportCase._id, { status: currStatus, response }).then(() => {
                setColor("2");
                setMessage("Email successfully sent to " + username + "!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })
        }
        else {
            setColor("3");
            setMessage("You forgot to update response / status !");
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }


    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <img src={File} /> {!view && "Update"} support case
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>User</label><br />
                                    <Input type="type" readOnly name="username" value={supportCase.profile.username} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Email</label><br />
                                    <Input type="type" readOnly name="email" value={supportCase.supportCase.email} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Content</label><br />
                                    <Input type="textarea" readOnly name="content" value={supportCase.supportCase.content} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>response</label><br />
                                    <Input type="textarea" name="response" defaultValue={response} onChange={(e) => setResponse(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Status </label>
                                    {!view ? <select defaultValue={currStatus} onChange={(e) => setCurrStatus(e.target.value)} name="status">
                                        <option value="" disabled hidden>Select status</option>
                                        {allStatus.map((status) => {
                                            return (
                                                <option key={status.key} value={status.value}>{status.value}</option>
                                            );
                                        })}
                                    </select> :
                                        <Input type="type" readOnly name="status" value={supportCase.supportCase.status} />
                                    }
                                </FormGroup>
                            </Col>
                        </Row>

                        {!view && <Button className='post' onClick={() => updateStatus(supportCase.profile.username)}>Respond user</Button>}
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(UpdateSupportCase);