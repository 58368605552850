import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import admin from '../../services/auth.service'

const initialState = {
    loading: false,
    avatars: [],
    admins: [],
    pageNumber: 1,
    error: ''
}

const AdminSlice = createSlice({
    name: 'admins',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;

        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAvatars.fulfilled, (state, action) => {
            state.avatars = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchAvatars.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAvatars.rejected, (state, action) => {
            state.avatars = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(fetchAdmins.fulfilled, (state, action) => {
            state.admins = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchAdmins.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAdmins.rejected, (state, action) => {
            state.admins = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchAvatars = createAsyncThunk('admins/getAvatars', async (payload) => {
    const avatars = await admin.allAvatars(payload)
    return (avatars.data);
})

export const fetchAdmins = createAsyncThunk('admins/getAdmins', async (payload) => {
    const admins = await admin.allAdmins(payload)
    return (admins.data);
})


export const { incrementPageNumber, decrementPageNumber } = AdminSlice.actions
export default AdminSlice.reducer;