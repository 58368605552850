import { Dialog } from "@mui/material";
import FocusLock from 'react-focus-lock';
import { AvatarIcon } from "assets/img";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Table } from "reactstrap";
import styled from "styled-components";
import SendAvatar from "./sendAvatar";

const PlayersModal = styled(Dialog)`
    .modalStyle{
        background-image: linear-gradient(to bottom, #3b437d, #1f265e);
    }
`

const NumberDiv = styled.div`
    width: 30px;
    font-size: 20px;
    text-align: center;
    border-radius: 13px;
    background-image: linear-gradient(to bottom, rgba(27, 37, 115,.13), rgba(255, 70, 94,.13));
`
const ShowRestOfGroup = ({ toggle, modal, players, groupNumber, tournamentGame, tournament }) => {

    const [showedTr, setShowedTr] = React.useState({ kills: true, goals: true, placement: true, deaths: true, assist: true })
    const [showSendAvatars, setShowSendAvatars] = React.useState(false);
    const [modalSendAvatar, setModalSendAvatar] = React.useState(false);
    const [playerId, setPlayerId] = React.useState(undefined);

    React.useEffect(() => {
        if (tournamentGame.title.includes('Free Fire') || tournamentGame.title.includes('Pubg') || tournamentGame.title.includes('Call Of Duty')) {
            setShowedTr(values => ({ ...values, assist: false, goals: false }))
        } else if (tournamentGame.title.includes('FIFA') || tournamentGame.title.includes('Rocket League')) {
            setShowedTr(values => ({ ...values, placement: false, kills: false, deaths: false }))
        }
        else {
            setShowedTr(values => ({ ...values, placement: false, goals: false }))
        }

    }, [tournamentGame.title])

    React.useEffect(() => {
        if (tournament.prizeClaim === "In-App artifacts" && tournament.prizes.prize1 === "Avatars")
            setShowSendAvatars(true);
    }, [tournament])

    const toggleSendAvatar = (id) => {
        setPlayerId(id)
        setModalSendAvatar(!modalSendAvatar)
    }

    return (
        <>
            {modalSendAvatar && <SendAvatar toggle={toggleSendAvatar} modal={modalSendAvatar} id={playerId} />}
            <FocusLock>
                <PlayersModal
                    fullWidth={true}
                    maxWidth={"md"}
                    open={modal}
                    onClose={toggle}>
                    <div className="modalStyle">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"> Group {groupNumber + 1} results</CardTitle></CardHeader>
                            <CardBody>
                                <Table borderless responsive style={{ tableLayout: "fixed" }}>
                                    <thead>
                                        <tr>
                                            <th className={"text-center"}>All players</th>
                                            {/* <th className={"text-center"}>Country</th> */}
                                            {showedTr.goals && <th className="text-center">Goals</th>}
                                            {showedTr.kills && <th className="text-center">Kills</th>}
                                            {showedTr.deaths && <th className="text-center">Deaths</th>}
                                            {showedTr.assist && <th className="text-center">Assists</th>}
                                            {showedTr.placement && <th className="text-center">Placement</th>}
                                            <th className="text-center">Overall score</th>
                                            {showSendAvatars && <th className="text-center">Send Avatar</th>}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {players[groupNumber].sort((a, b) => { return b.score.overallScore - a.score.overallScore }).map((player, index) => {
                                            return (

                                                <tr key={index} style={{ backgroundColor: player.score.status && '#605176' }}>
                                                    <td>
                                                        <div style={{ display: "inline-flex", gap: "20px" }}>
                                                            <NumberDiv style={{ color: player.score.status ? '#fe4958' : '#656fb5' }}>{index + 1}</NumberDiv>
                                                            <div className="photo"><img src={player.user.avatar} alt="avatar" /></div>
                                                            {player.user.username}
                                                        </div>
                                                    </td>
                                                    {/* <td>{player.user.username}</td> */}
                                                    {showedTr.goals && <td className="text-center">{player.score.goals}</td>}
                                                    {showedTr.kills && <td className="text-center">{player.score.kills}</td>}
                                                    {showedTr.deaths && <td className="text-center">{player.score.deaths}</td>}
                                                    {showedTr.assist && <td className="text-center">{player.score.assist}</td>}
                                                    {showedTr.placement && <td className="text-center">{player.score.placement}</td>}
                                                    <td className="text-center">{player.score.overallScore}</td>
                                                    {(showSendAvatars && player.score.status) && <th className="text-center" onClick={() => toggleSendAvatar(player.user._id)}><img src={AvatarIcon} /></th>}
                                                </tr>)
                                        })}
                                    </tbody>

                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </PlayersModal>
            </FocusLock>
        </>
    );
}

export default ShowRestOfGroup;