import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const checkPermissions = (userPermission, requiredPermissions) => {
    return requiredPermissions.includes(userPermission);
};

const ProtectedRoute = ({ permissions, element }) => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const userRole = profile.roleId.title;

    return checkPermissions(userRole, permissions) ? element : <Navigate to="/403" />;
};

export default ProtectedRoute;
