import axios from "axios";

let BASE_URL = "https://store.t7dgaming.com/api"

const getProviderBalance = async (playload) => {
  return await axios({
    method: 'post',
    url: `${BASE_URL}/providers/balance`,
    data: playload
  });
}

export default {
  getProviderBalance
}