import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import miniGames from '../../services/mimiGames.service'

const initialState = {
    loading: false,
    miniGames: [],
    miniGamesCategories: [],
    pageNumber: 1,
    error: ''
}

const MiniGamesSlice = createSlice({
    name: 'miniGames',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;
        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMiniGames.fulfilled, (state, action) => {
            state.miniGames = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchMiniGames.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchMiniGames.rejected, (state, action) => {
            state.miniGames = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(fetchMiniGamesCategories.fulfilled, (state, action) => {
            state.miniGamesCategories = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchMiniGamesCategories.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchMiniGamesCategories.rejected, (state, action) => {
            state.miniGamesCategories = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchMiniGames = createAsyncThunk('miniGames/all', async (payload) => {
    const allMiniGames = await miniGames.getMiniGames(payload)
    return (allMiniGames.data);
})

export const fetchMiniGamesCategories = createAsyncThunk('miniGames/categories', async () => {
    const allCategories = await miniGames.getCategories()
    return (allCategories.data);
})


export const { incrementPageNumber, decrementPageNumber } = MiniGamesSlice.actions
export default MiniGamesSlice.reducer;