import axios from "axios";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://shared.t7d.io"
} else {
    BASE_URL = "https://staging.shared.t7d.io"
}

const getCountries = () => {
    return axios({
        method: 'get',
        url: `${BASE_URL}/countries`
    });
}

export default { getCountries }