import React from "react";
import { PrepaidLogo } from "assets/img";
import { MintrouteLogo } from "assets/img";
import { Vouchers } from "assets/img";
import { EzpinLogo } from "assets/img";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  CardGroup,
  CardImg,
  CardSubtitle,
  CardText,
  Button
} from "reactstrap";
import providersService from "services/providers.service";


function Providers() {
  const [ezpinBalance, setEzpinBalance] = React.useState("");
  const [prepaidBalance, setPrepaidBalance] = React.useState("");
  const [mintrouteBalance, setMintrouteBalance] = React.useState("");

  const getEzpinBalance = async () => {
    const ezpin = await providersService.getProviderBalance({ providerName: "ezpin" })
    setEzpinBalance(ezpin.data[0].balance);
  }
  const getPrepaidBalance = async () => {
    const prepaid = await providersService.getProviderBalance({ providerName: "prepaidforge" })
    setPrepaidBalance(prepaid.data[0].balance);
  }
  const getMintrouteBalance = async () => {
    const mintroute = await providersService.getProviderBalance({ providerName: "mintroute" })
    setMintrouteBalance(mintroute.data[0].balance);
  }
  React.useEffect(() => {
    getEzpinBalance()
    getPrepaidBalance()
    getMintrouteBalance()
  }, [])
  return (
    <>
      <div className="content">
        <Row xs={1} md={1}>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h4"><img src={Vouchers} alt="Providers" style={{ marginRight: "8px" }} /> Providers</CardTitle>
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row xs={1} md={3}  >
          <Col>
            <Card>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {/* Image */}
                <div style={{ height: "80px", width: "80px", margin: "20px", backgroundImage: `url(${EzpinLogo})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                {/* End image */}
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  <CardBody>
                    <CardTitle tag="h4">EZ PIN balance </CardTitle>
                    <CardText>{ezpinBalance} $</CardText>
                  </CardBody>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {/* Image */}
                <div style={{ height: "80px", width: "80px", margin: "20px", backgroundImage: `url(${PrepaidLogo})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                {/* End image */}
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <CardBody>
                <CardTitle tag="h4">Prepaidforge balance</CardTitle>
                <CardText>{prepaidBalance} $</CardText>
              </CardBody>
              </div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {/* Image */}
                <div style={{ height: "80px", width: "80px", margin: "20px", backgroundImage: `url(${MintrouteLogo})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                {/* End image */}
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <CardBody>
                <CardTitle tag="h4">Mintroute balance</CardTitle>
                <CardText>{mintrouteBalance} $</CardText>
              </CardBody>
              </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Providers;