import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://notification.t7d.io"
} else {
    BASE_URL = "https://staging.notification.t7d.io"
    // BASE_URL = "http://localhost:3005"

}
// Notifications
const sendNotifications = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/notification/sendNotification`,
        data: payload
    });
}

const sendNotificationsToTournamentParticipants = async (id, payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/notification/sendNotificationsToTournamentParticipants/${id}`,
        data: payload,
        headers: authHeader()
    });
}

const sendNotificationsToSpecificProfiles = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/notification/sendNotificationToSpecificUsers`,
        data: payload
    });
}

//Messages
const sendMessages = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/messages/createMessage`,
        data: payload
    });
}

export default {
    sendNotifications, sendNotificationsToTournamentParticipants, sendNotificationsToSpecificProfiles,
    sendMessages
}