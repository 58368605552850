import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import tournamentService from 'services/tournament.service';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const SendCredentials = ({ modal, toggle, id, tournamentTitle, lvl, groupNb }) => {
    const [data, setData] = React.useState({ tournament_id: id, title: tournamentTitle, groupNumber: groupNb, room: undefined, password: undefined, type: undefined, lvl: lvl })
    const [templateSelected, setTemplateSelected] = React.useState(false)
    const [firstInputLabel, setFirstInputLabel] = React.useState("")
    const [secondInputlabel, setSecondInputlabel] = React.useState("")
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const sendEmails = async () => {
        console.log(data);
        if (data.room !== undefined && data.password !== undefined) {
            tournamentService.sendCredentials(data).then((response) => {
                if (response.data.error === true) {
                    setColor("3");
                    setMessage(response.data.message)
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
                else {
                    setColor("2");
                    setMessage("Credentials sent successfully to group " + groupNb + " !");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }
            }).catch((err) => {
                console.log(err.message);
            })
        }
        else {
            setColor("3");
            setMessage("Please fill in the required fields!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === "type") {
            setTemplateSelected(true)
            if (value === "Host") {
                setFirstInputLabel("Tournament Host Name");
                setSecondInputlabel("Game ID");
            }
            else {
                setFirstInputLabel("Match ID");
                setSecondInputlabel("Password");
            }
        }
        setData({ ...data, [name]: value })
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                Send credentials to group {groupNb}
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Select a template </label><br />
                                    <select defaultValue="" value={data.type} onChange={inputHandler} name="type">
                                        <option value="" disabled hidden>Select template</option>
                                        <option value="Lobby">Lobby Match Credentials</option>
                                        <option value="Direct">Direct Invite Match Credentials</option>
                                        <option value="Host">Host Invite</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        {templateSelected &&
                            <>
                                <Row style={{ width: "100%" }}>
                                    <Col className="px-md-1" md="12">
                                        <FormGroup>
                                            <label>{firstInputLabel} </label>
                                            <Input placeholder={firstInputLabel} value={data.room} onChange={inputHandler} type="text" name="room" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col className="px-md-1" md="12">
                                        <FormGroup>
                                            <label>{secondInputlabel}</label>
                                            <Input placeholder={secondInputlabel} value={data.password} onChange={inputHandler} type="text" name="password" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button className='post' onClick={() => sendEmails()}>Send</Button>
                            </>
                        }
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(SendCredentials);