import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://mini-games.t7d.io"
} else {
    BASE_URL = "https://staging.mini-games.t7d.io"
    // BASE_URL = "http://localhost:5000"
}
//Mini games category
const addCategory = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/miniGamesCategoryRoute/addCategory`,
        data: payload
    });
}

const updateCategory = async (payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/miniGamesCategoryRoute/updateCategory`,
        data: payload
    });
}

const deleteCategory = async (payload) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/miniGamesCategoryRoute/deleteCategory`,
        data: payload
    });
}

const getCategories = async () => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/miniGamesCategoryRoute/getAllCategory`
    });
}

// Mini games
const getMiniGames = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/miniGamesRoute/getAllMiniGamesForAdmin`,
        data: payload,
        headers: authHeader()
    });
}

const addMiniGame = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/miniGamesRoute/addMiniGame`,
        data: payload,
        headers: authHeader()
    });
}

const updateMiniGame = async (payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/miniGamesRoute/updateOneMiniGame`,
        data: payload,
        headers: authHeader()
    });
}

const deleteMiniGame = async (payload) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/miniGamesRoute/deleteOneMiniGame`,
        data: payload,
        headers: authHeader()
    });
}

export default {
    addCategory, updateCategory, deleteCategory, getCategories,
    getMiniGames, addMiniGame, updateMiniGame, deleteMiniGame
}