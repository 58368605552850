import React from 'react';
import axios from 'axios';
import { EditIcon, DeleteIcon, Premium, Kick, BanIcon, Switch } from '../../assets/img';
import styled from 'styled-components';
import profileService from 'services/profile.service';
import { useDispatch } from 'react-redux';

const ActionStyle = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    .kick{
        cursor: pointer;
    }
`

const ParticipantRow = ({ index, participant, allCountries, kick }) => {
    const [country, setCountry] = React.useState(null);
    const fetchCountry = async (country) => {
        const countryObject = allCountries.find(({ countryName }) => countryName === country)
        countryObject ? setCountry(<img
            src={countryObject.logo}
            style={{ width: "25px", height: "25px" }} />) : setCountry("")
    }

    React.useEffect(() => {
        fetchCountry(participant.player.countryOfOrigin)
    }, [allCountries]);

    return (<>
        <tr key={index}>
            <td><div style={{ display: "inline-flex", gap: "20px" }}><div className="photo"><img src={participant.player.avatar} /></div>{participant.player.username} </div></td>
            <td className="text-center">{country}</td>
            <td className="text-center">{participant.player.isPremium.status ? <img src={Premium} style={{ width: "29px", height: "29px" }} /> : "Regular"}</td>
            <td className="text-center">{participant.participation.inGamePlayerId}</td>
            <td className="text-center"></td>
            <td className="text-center">
                <ActionStyle>
                    <div className='kick'><img src={Kick} alt="Kick" onClick={() => kick(participant)} /></div>
                </ActionStyle>
                {/* <img style={{ paddingRight: "10px" }} src={Switch} /> */}
                {/* <img style={{ paddingRight: "10px", cursor: "pointer" }} src={Kick} /> */}
                {/* <img style={{ paddingRight: "10px" }} src={BanIcon} /> */}
                {/* <img src={DeleteIcon} onClick={() => console.log("Hello")} /> */}
            </td>
        </tr>
    </>
    );
}

export default ParticipantRow;