import React from 'react'
import './greenButton.css';
import styled from 'styled-components';
import { ThinkingIcon } from 'assets/img';

const NoDataAvailable = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`
function NoData({ text }) {
    return (
        <NoDataAvailable>
            <img src={ThinkingIcon} />
            <p>{text}</p>
        </NoDataAvailable >
    )
}

export default NoData;