import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import { AddIcon } from 'assets/img';
import { DeleteIcon } from 'assets/img';
import profileService from 'services/profile.service';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    
    Form{
        flex-direction: "column"; display: 'flex'; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const ActionStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    .add{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`
const SendPoints = ({ modal, toggle, tournamentId, groupWinners, groupNb, brackets }) => {
    const [allData, setAllData] = React.useState([{ tournamentId, profileId: undefined, score: undefined }])
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const checkProperties = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].profileId === undefined || arr[i].score === undefined)
                return false;
        }
        return true;
    }

    const setPlayersPoints = async () => {
        if (checkProperties(allData)) {
            for (let i = 0; i < allData.length; i++) {
                profileService.addPlayerTournamentScore(allData[i]).then((response) => {
                    if (response.data.error === true) {
                        setColor("3");
                        setMessage(response.data.message)
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                        }, 7000);
                    }
                    else {
                        setColor("2");
                        setMessage(`Points sent successfully to ${brackets ? "brackets" : "group" + (groupNb + 1)} winners!`);
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                            toggle();
                        }, 2000);
                    }
                }).catch((err) => {
                    console.log(err.message);
                })
            }
        }
        else {
            setColor("3");
            setMessage("Please fill in all the fields!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 3000);
        }

    }
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...allData];
        list.splice(index, 1);
        setAllData(list)
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setAllData([...allData, { tournamentId, profileId: undefined, score: undefined }]);
    };
    const inputHandler = (e, index) => {
        const { name, value } = e.target
        const list = [...allData];
        if (name === "profileId") {
            list[index].profileId = value;
            setAllData(list);
        }
        if (name === "score") {
            list[index].score = Number(value);
            setAllData(list);
        }
    }
    React.useEffect(() => {
        console.log(groupWinners);
        // This code will run every time allData changes
    }, [allData]);


    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                Send Points to {brackets ? "brackets" : "group" + (groupNb + 1)} winners
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        {allData.map((d, i) => {
                            return (
                                <ActionStyle>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="9">
                                            <FormGroup>
                                                <label>Select Player </label><br />
                                                <select value={d.profileId} onChange={(e) => inputHandler(e, i)} name="profileId">
                                                    <option value="" disabled selected hidden>Select player</option>
                                                    {groupWinners && groupWinners.map((winner) => {
                                                        return (
                                                            <option key={winner.userData._id} value={winner.userData._id}>{winner.userData.username}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-md-1" md="3">
                                            <FormGroup>
                                                <label>Score </label><br />
                                                <Input type="number" value={d.score && d.score} onChange={(e) => inputHandler(e, i)} name="score" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {allData.length - 1 === i && allData.length < groupWinners.length && <div className='add'><img src={AddIcon} alt="add" onClick={handleAddClick} /></div>}
                                    {allData.length !== 1 && <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => handleRemoveClick(i)} /></div>}
                                </ActionStyle>
                            )
                        })}
                        <Button className='post' onClick={() => setPlayersPoints()}>Send</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(SendPoints);