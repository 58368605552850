import React from 'react';
import moment from 'moment';
import styled from 'styled-components'
import { CountryDropdown } from 'react-country-region-selector';
import { ArrowIcon, BackArrowIcon, Discount, PremiumSubscription, Artifact, GD, Vouchers, CashPrize, AddAvatarIcon } from '../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button, Card, CardImg, CardTitle } from "reactstrap";
import { Dialog } from '@reach/dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import tournamentService from 'services/tournament.service';
import notificationService from 'services/notification.service';
import { useDispatch } from 'react-redux';
import { getTournaments } from 'redux/reducers/tournamentReducer';
import { FormControlLabel, Switch, Slider } from '@mui/material';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 100px;
        height: 80px;
        margin-left: 20px;
    }
`

const CreateUpdateTournament = ({ modal, toggle, tournament }) => {
    const [countryValue, setCountryValue] = React.useState(undefined);
    const [privateTournament, setPrivateTournament] = React.useState(false);

    const [games, setGames] = React.useState([]);
    const [game, setGame] = React.useState("");
    const [event, setEvent] = React.useState(undefined);
    const [logo, setLogo] = React.useState(undefined);
    const [cover, setCover] = React.useState(undefined);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [prizeClaim, setPrizeClaim] = React.useState(true)
    const [showSemiFinalAndFinalInputs, setShowSemiFinalAndFinalInputs] = React.useState(false);
    const [original, setOriginal] = React.useState(false);
    const [wasPrivate, setWasPrivate] = React.useState(false);
    const [isCountryDisabled, setIsCountryDisabled] = React.useState(false);
    const [isRegionDisabled, setIsRegionDisabled] = React.useState(false);
    const [notifyUsers, setNotifyUsers] = React.useState(false);

    const formData = React.useMemo(() => new FormData(), []);
    const dispatch = useDispatch();

    const format = "yyyy-MM-DDThh:mm"

    const marksForGd = [
        {
            value: 0,
            label: '0',
            color: 'red'
        },
        {
            value: 1000,
            label: '1000',
            color: '#00000'

        },
        {
            value: 5000,
            label: '5000',
        },
        {
            value: 10000,
            label: '10000',
        },
    ];


    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'logo' || name === 'cover') {
            formData.set(name, e.target.files[0])
        } else if (name === "playersType") {
            switch (value) {
                case "Free": case "Boosted":
                    formData.set("playersType[name]", value);
                    formData.set("playersType[colorCode]", "6b12a3");
                    break;
                case "Premium":
                    formData.set("playersType[name]", "Premium")
                    formData.set("playersType[colorCode]", "ffff00");
                    break;
                case "Pro":
                    formData.set("playersType[name]", "Pro")
                    formData.set("playersType[colorCode]", "125fa3");
                    break;
                default:
                    break;
            }
        } else if (name === "tournamentFormat") {
            formData.set(name, value)
            if (value === "Groups") setShowSemiFinalAndFinalInputs(true);
            else setShowSemiFinalAndFinalInputs(false);
        } else if (name === "private") {
            setPrivateTournament(!privateTournament)
        }
        else if (name.includes("prize")) {
            formData.set(`prizes[${name}]`, value);
        } else if (name === "region") {
            setIsCountryDisabled(true)
            formData.delete("country")
            formData.set(name, value)
        } else if (name === "gameId") {
            formData.set(name, value)
            setGame(value);
        } else if (name === "notify") {
            setNotifyUsers(!notifyUsers);
            console.log(notifyUsers);
        } else if (name === "tournamentEvent") {
            formData.set(name, value)
            setEvent(value);
        } else {
            formData.set(name, value)
        }
    }
    const prizeClaimHandler = (index) => {
        switch (index) {
            case 1:
                formData.set('prizeClaim[en]', "Cash Prize");
                formData.set('prizeClaim[ar]', "جائزة نقدية")
                break;
            case 2:
                formData.set('prizeClaim[en]', "Vouchers");
                formData.set('prizeClaim[ar]', "قسائم")
                break;
            case 3:
                formData.set('prizeClaim[en]', "GD Counter part");
                formData.set('prizeClaim[ar]', "GDمقابل ال")
                break;
            case 4:
                formData.set('prizeClaim[en]', "Discount");
                formData.set('prizeClaim[ar]', "تخفيض")
                break;
            case 5:
                formData.set('prizeClaim[en]', "In-App artifacts");
                formData.set('prizeClaim[ar]', "قطع التطبيق المميزة")
                break;
            case 6:
                formData.set('prizeClaim[en]', "Premium subscriptions");
                formData.set('prizeClaim[ar]', "إشتراكات البريميوم")
                break;
            default:
                break;
        }
        setPrizeClaim(false);
    }
    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const loadFile = (e, name) => {
        const [file] = e.target.files;
        if (name === "logo")
            setLogo(URL.createObjectURL(file));
        else
            setCover(URL.createObjectURL(file));
    };

    const sendNotificationsToUsers = async (isCreation, tournamentTitle, tournamentID, gameID) => {
        if (isCreation) {
            const payload = {
                notifType: "INFO", categoryType: "TOURNAMENT", playerGameIds: [gameID], from: "T7D", body: "Check out the latest Tournaments exclusively on T7D!", title: "New Tournaments!", link: tournamentID
            }
            notificationService.sendNotificationsToSpecificProfiles(payload).then((response) => {
                console.log("Notifications sent!");
            }).catch((err) => {
                console.log(err.message);
            })
        }
        else {
            const payload = {
                categoryType: "TOURNAMENT", notifType: "INFO", from: "T7D", body: tournamentTitle + " you’re subscribed into has been updated!", title: "Oh no! It had to happen!", link: tournamentID
            }
            notificationService.sendNotificationsToTournamentParticipants(tournamentID, payload).then((response) => {
                console.log("Notifications sent!");
            }).catch((err) => {
                console.log(err.message);
            })
        }
    }

    const createUpdateTournament = async () => {
        if (tournament) { //Update
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            if (formData.get("tournamentFormat") === "Groups" && (formData.get("semiFinal[startTime]") === null || formData.get("final[startTime]") === null)) {
                setColor("3");
                setMessage("The tournament format is Groups. Please fill in the semi-final and final start times!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else if (!checkPrivateState()) {
                setColor("3");
                setMessage("The tournament is private. Please select a method to join!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                if (wasPrivate && !privateTournament) {
                    formData.set('private', "Off");
                    formData.delete("private[methodToJoin]");
                    formData.delete("private[amount]");
                }
                for (var pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                tournamentService.update(tournament._id, formData).then((response) => {
                    dispatch(getTournaments({ skip: 0, limit: 20 }))
                    setColor("2");
                    setMessage("Tournament updated successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                    if (notifyUsers)
                        sendNotificationsToUsers(false, tournament.title, tournament._id, tournament.gameId._id)
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
        else { // create new tournament
            if (formData.get("gameId") === null || formData.get("title") === null || formData.get("scheduledStartDateTime") === null
                || formData.get("scheduledEndDateTime") === null || formData.get("registrationOpeningDateTime") === null || formData.get("registrationClosingDateTime") === null
                || formData.get("registrationOpeningDateTime") === null || formData.get("size") === null || formData.get("playersType[name]") === null || formData.get("tournamentType") === null
                || formData.get("tournamentFormat") === null || (formData.get("tournamentFormat") === "Groups" && (formData.get("semiFinal[startTime]") === null && formData.get("final[startTime]") === null))
                || (formData.get("region") === null && formData.get("country") === null)
                || formData.get("description[en]") === null || formData.get("description[ar]") === null
                || formData.get("informations[en]") === null || formData.get("informations[ar]") === null
                || formData.get("rules[en]") === null || formData.get("rules[ar]") === null
                || formData.get("prizeClaim[en]") === null || formData.get("prizeClaim[ar]") === null
                || formData.get("prizes[prize1]") === null || formData.get("logo") === null || formData.get("cover") === null
                || !checkPrivateState()) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                formData.set("knockouts[startTime]", formData.get('scheduledStartDateTime'));
                if (!privateTournament) {
                    formData.delete("private[methodToJoin]");
                    formData.delete("private[amount]");
                }
                tournamentService.create(formData).then((response) => {
                    dispatch(getTournaments({ skip: 0, limit: 20 }))
                    setColor("2");
                    setMessage("Tournament created successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                    if (notifyUsers)
                        sendNotificationsToUsers(true, formData.get("title"), "", formData.get("gameId")) //isCreation=false, tournamentTitle, tournamentID
                }).catch((error) => {
                    if (error.response.status !== 200) {
                        setColor("3");
                        setMessage(error.response.data.message)
                        setShowNotif(true);
                        setTimeout(() => {
                            setShowNotif(false)
                        }, 7000);
                    }
                    console.log(error.message);
                })
            }
        }
    }

    const getGames = async () => {
        tournamentService.getTournamentGames().then(response => {
            setGames(response.data.data)
        })
    }
    React.useEffect(() => {
        getGames();
        if (tournament) {
            setGame(tournament.gameId._id)
            if (tournament.tournamentEvent === undefined)
                setEvent("N/A")
            else setEvent(tournament.tournamentEvent)
            formData.set("description[ar]", tournament.description.ar)
            formData.set("description[en]", tournament.description.en)
            formData.set("informations[ar]", tournament.informations.ar)
            formData.set("informations[en]", tournament.informations.en)
            formData.set("rules[ar]", tournament.rules.ar)
            formData.set("rules[en]", tournament.rules.en)
            if (tournament.tournamentFormat === "Groups") {
                setShowSemiFinalAndFinalInputs(true);
                // The tournament is originally groups format
                setOriginal(true);
            }
            if (tournament.private !== undefined) {
                setPrivateTournament(true);
                formData.set("private[methodToJoin]", tournament.private.methodToJoin)
                formData.set("private[amount]", tournament.private.amount)
                // the tournament is originally private
                setWasPrivate(true);
            }
        }
    }, [])


    const checkPrivateState = () => {
        if (privateTournament && formData.get("private[methodToJoin]") === null) return false
        else if (privateTournament && formData.get("private[methodToJoin]") !== null) {
            if (formData.get("private[amount]") === null) formData.set("private[amount]", 50);
            return true
        }
        else return true;
    }


    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Create/Update tournament" className='creationModal' isOpen={modal} onDismiss={toggle}>
                {/* <div className='title'><img src={AddIcon} /> Create Tournament</div> */}
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList style={{ backgroundColor: 'transparent' }}>
                        <Tab style={{ marginLeft: "-8%" }}>Overview</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Information</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Details</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Rules</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Prizes</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Gallery</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Select Game</label><br />
                                                <select value={game} onChange={inputHandler} name="gameId">
                                                    <option value="" disabled selected hidden>Select game</option>
                                                    {games.map((g) => {
                                                        return (
                                                            <option key={g._id} value={g._id}>{g.title}</option>
                                                        );
                                                    })}
                                                </select>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Choose a tournament title </label>
                                                <Input placeholder="Tournament title" type="text" defaultValue={tournament && tournament.title} onChange={inputHandler} name="title" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Tournament event? select one </label>
                                                <select defaultValue="" value={event} onChange={inputHandler} name="tournamentEvent">
                                                    <option key="0" value="" disabled hidden>Select an event</option>
                                                    <option key="1" value="Ramadan">Ramadan</option>
                                                    <option key="2" value="Summer">Summer</option>
                                                    <option key="3" value="N/A">Not an event</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Tournament start </label>
                                                <Input type="datetime-local" defaultValue={tournament && moment.utc(tournament.scheduledStartDateTime).format(format)} onChange={inputHandler} name="scheduledStartDateTime" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Tournament end </label>
                                                <Input type="datetime-local" onChange={inputHandler} defaultValue={tournament && moment.utc(tournament.scheduledEndDateTime).format(format)} name="scheduledEndDateTime" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Registration opens </label>
                                                <Input type="datetime-local" onChange={inputHandler} defaultValue={tournament && moment.utc(tournament.registrationOpeningDateTime).format(format)} name="registrationOpeningDateTime" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Registration ends </label>
                                                <Input type="datetime-local" onChange={inputHandler} defaultValue={tournament && moment.utc(tournament.registrationClosingDateTime).format(format)} name="registrationClosingDateTime" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button
                                        className="next"
                                        onClick={() => handleButtonChange(1)}
                                    > Next  <img src={ArrowIcon} /></Button>
                                </Form>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Capacity </label>
                                                <Input placeholder="Number of users by tournament" type="number" min="1" defaultValue={tournament && tournament.size} onChange={inputHandler} name="size" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Players type </label>
                                                <select defaultValue={tournament && tournament.playersType.name} onChange={inputHandler} name="playersType">
                                                    <option value="" disabled selected hidden>Select players type</option>
                                                    <option value="Free">Free</option>
                                                    <option value="Boosted">Boosted</option>
                                                    <option value="Premium">Premium</option>
                                                    <option value="Pro">Pro</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Type </label>
                                                <select defaultValue={tournament && tournament.tournamentType} onChange={inputHandler} name="tournamentType">
                                                    <option value="" disabled selected hidden>Select tournament type</option>
                                                    <option value="Solo">Solo</option>
                                                    <option value="Duo">Duo</option>
                                                    <option value="Quads">Quads</option>
                                                    <option value="Teams">Teams</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Format </label>
                                                <select defaultValue={tournament && tournament.tournamentFormat} disabled={tournament !== undefined} onChange={inputHandler} name="tournamentFormat">
                                                    <option value="" disabled selected hidden>Select tournament format</option>
                                                    <option value="Single Elimination">Single Elimination</option>
                                                    <option value="Groups">Groups</option>
                                                    <option value="Brackets">Brackets</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {showSemiFinalAndFinalInputs && <><Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Semi final start datetime </label>
                                                <Input type="datetime-local" onChange={inputHandler} defaultValue={(tournament && original) && moment.utc(tournament.semiFinal.startTime).format(format)} name="semiFinal[startTime]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Final start datetime </label>
                                                    <Input type="datetime-local" onChange={inputHandler} defaultValue={(tournament && original) && moment.utc(tournament.final.startTime).format(format)} name="final[startTime]" />
                                                </FormGroup>
                                            </Col>
                                        </Row></>}
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Region </label>
                                                <select defaultValue={tournament && tournament.region} onChange={inputHandler} name="region" disabled={isRegionDisabled}>
                                                    <option value="" disabled selected hidden>Select region</option>
                                                    <option value="MENA">MENA</option>
                                                    <option value="Middle-East">Middle-East</option>
                                                    <option value="North Africa">North Africa</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Country </label>
                                                <CountryDropdown
                                                    value={(tournament !== undefined && countryValue === undefined) ? tournament.country : countryValue}
                                                    disabled={isCountryDisabled}
                                                    onChange={(val) => {
                                                        setIsRegionDisabled(true)
                                                        formData.delete("region")
                                                        formData.set('country', val)
                                                        setCountryValue(val)
                                                    }} />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Private </label>{` `}
                                                <FormControlLabel
                                                    control={<Switch color="primary" checked={privateTournament} onChange={inputHandler} name="private" />}
                                                />
                                                <br />
                                                {privateTournament === true && <>
                                                    <label>Select Join method</label><br />
                                                    <select defaultValue={tournament && tournament.private && formData.get("private[methodToJoin]")} onChange={inputHandler} name="private[methodToJoin]">
                                                        <option value="" disabled selected hidden>Select method to join</option>
                                                        <option value={"gd"}>{"gd"}</option>
                                                    </select>
                                                    <label>amount</label>
                                                    <Slider
                                                        aria-label="Temperature"
                                                        defaultValue={tournament && tournament.private && formData.get("private[amount]")}
                                                        onChange={inputHandler}
                                                        valueLabelDisplay="auto"
                                                        step={10}
                                                        marks={marksForGd}
                                                        min={0}
                                                        max={10000}
                                                        name="private[amount]"
                                                        sx={{
                                                            "& .MuiSlider-mark": {
                                                                color: "white", // Set the color for marks
                                                            },
                                                            "& .MuiSlider-markLabel": {
                                                                color: "white", // Set the color for mark labels
                                                            },
                                                        }}
                                                    />
                                                </>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <Button
                                    className="back"
                                    onClick={() => handleButtonChange(0)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>

                                <Button
                                    className="next"
                                    onClick={() => handleButtonChange(2)}
                                >Next <img src={ArrowIcon} /></Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>English description</label>
                                                <Input placeholder="English description" type="textarea" defaultValue={tournament && tournament.description.en} onChange={inputHandler} name="description[en]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Arabic description</label>
                                                <Input placeholder="Arabic description" type="textarea" defaultValue={tournament && tournament.description.ar} onChange={inputHandler} name="description[ar]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>English informations</label>
                                                <Input placeholder="English informations" type="textarea" defaultValue={tournament && tournament.informations.en} onChange={inputHandler} name="informations[en]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Arabic informations</label>
                                                <Input placeholder="Arabic informations" type="textarea" defaultValue={tournament && tournament.informations.ar} onChange={inputHandler} name="informations[ar]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(1)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>
                                <Button
                                    className='next'
                                    onClick={() => handleButtonChange(3)}
                                ><img src={ArrowIcon} /> Next</Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Rules (English)</label>
                                                <Input placeholder="English rules" type="textarea" defaultValue={tournament && tournament.rules.en} onChange={inputHandler} name="rules[en]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Rules (Arabic)</label>
                                                <Input placeholder="Arabic rules" type="textarea" defaultValue={tournament && tournament.rules.ar} onChange={inputHandler} name="rules[ar]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(2)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>
                                <Button
                                    className='next'
                                    onClick={() => handleButtonChange(4)}
                                ><img src={ArrowIcon} /> Next</Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            {/* <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "normal", justifyContent: "normal", width: "100%", padding: "50px 0px 50px 0px" }}> */}
                            {prizeClaim ?
                                <><Row style={{ marginTop: '30px' }}>
                                    <Col sm="4">
                                        <Card body style={{ height: '138px', cursor: 'pointer' }} onClick={() => prizeClaimHandler(1)}>
                                            <CardImg top style={{ width: '30.3px', height: '34.4px', margin: '20px 0 25px 23px', objectFit: 'contain' }}
                                                src={CashPrize} alt="Cash prize" />
                                            <CardTitle style={{ fontSize: '11px', textAlign: 'center' }}>Cash prize</CardTitle>
                                        </Card>
                                    </Col>
                                    <Col sm="4">
                                        <Card body style={{ height: '138px', cursor: 'pointer' }} onClick={() => prizeClaimHandler(2)}>
                                            <CardImg top style={{ width: '37.7px', height: '22.5px', margin: '25px 0 30px 15px', objectFit: 'contain' }}
                                                src={Vouchers} alt="Vouchers" />
                                            <CardTitle style={{ fontSize: '11px', textAlign: 'center' }}>Vouchers</CardTitle>
                                        </Card>
                                    </Col>
                                    <Col sm="4">
                                        <Card body style={{ height: '138px', cursor: 'pointer' }} onClick={() => prizeClaimHandler(3)}>
                                            <CardImg top style={{ width: '36px', height: '36px', margin: '15px 0 20px 20px', objectFit: 'contain' }}
                                                width="100%" src={GD} alt="GD Counter part" />
                                            <CardTitle style={{ fontSize: '11px', textAlign: 'center' }}>GD Counter part</CardTitle>
                                        </Card>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col sm="4">
                                            <Card body style={{ height: '138px', cursor: 'pointer' }} onClick={() => prizeClaimHandler(4)}>
                                                <CardImg top style={{ width: '35.6px', height: '35.6px', margin: '23px 0 15px 20px', objectFit: 'contain' }}
                                                    src={Discount} alt="Discount" />
                                                <CardTitle style={{ fontSize: '11px', textAlign: 'center' }}>Discount</CardTitle>
                                            </Card>
                                        </Col>
                                        <Col sm="4">
                                            <Card body style={{ height: '138px', cursor: 'pointer' }} onClick={() => prizeClaimHandler(5)}>
                                                <CardImg top style={{ width: '43.3px', height: '31px', margin: '27px 0 10px 15px', objectFit: 'contain' }}
                                                    src={Artifact} alt="In-App artifacts" />
                                                <CardTitle style={{ fontSize: '11px', textAlign: 'center' }}>In-App artifacts</CardTitle>
                                            </Card>
                                        </Col>
                                        <Col sm="4">
                                            <Card style={{ height: '138px', cursor: 'pointer' }} onClick={() => prizeClaimHandler(6)}>
                                                <CardImg top style={{ width: '35.3px', height: '19.5px', margin: '45px 0 20px 35px', objectFit: 'contain' }}
                                                    src={PremiumSubscription} alt="Premium subscriptions" />
                                                <CardTitle style={{ fontSize: '11px', textAlign: 'center' }}>Premium subscriptions</CardTitle>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>GD Prize (Optional)</label>
                                                <Input placeholder="GD prize" type="number" defaultValue={tournament && tournament.gdPrize} onChange={inputHandler} name="gdPrize" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </> :
                                <>
                                    <img src={BackArrowIcon} onClick={() => setPrizeClaim(true)} /> {formData.get('prizeClaim[en]')}
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Prize 1 </label>
                                                <Input type="text" defaultValue={tournament && tournament.prizes.prize1} onChange={inputHandler} name="prize1" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Prize 2 </label>
                                                <Input type="text" defaultValue={tournament && tournament.prizes.prize2} onChange={inputHandler} name="prize2" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Prize 3 </label>
                                                <Input type="text" defaultValue={tournament && tournament.prizes.prize3} onChange={inputHandler} name="prize3" />
                                            </FormGroup>
                                        </Col>
                                    </Row></>}
                            <CreationForm>
                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(3)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>
                                <Button
                                    className='next'
                                    onClick={() => handleButtonChange(5)}
                                ><img src={ArrowIcon} /> Next</Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Logo </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'logo') }} name="logo" />
                                                </Button>
                                                <img src={(tournament && logo === undefined) ? tournament.logo : logo} className="avatar" alt="logo" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Cover </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'cover') }} name="cover" />
                                                </Button>
                                                <img src={(tournament && cover === undefined) ? tournament.cover : cover} className="avatar" alt="cover" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Input type="checkbox" onChange={inputHandler} name="notify" />{' '} Notify users {tournament ? "with the updates" : ""}
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(4)}
                                ><img src={BackArrowIcon} /> Back</Button>

                                <Button className='post' onClick={createUpdateTournament}>{tournament ? 'Update' : 'Post'}</Button>
                            </CreationForm>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateTournament);
