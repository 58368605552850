import React from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import TournamentDetails from '../../components/Tournament/TournamentDetails'
import styled from "styled-components";
import { DevIcon } from "assets/img";
import Loader from "components/UI/Loader";
var ps;
const FooterStyle = styled.div`
    position: fixed;
    left: 20px;
    bottom: 10px;
    color: #757ba8;
    text-align: left;
    font-size: 9px;
`
function Layout(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [psInitialized, setPsInitialized] = React.useState(false);
  const [profileData, setProfileData] = React.useState({});
  const [tokenAvailable, setTokenAvailable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sidebarOpened, setSidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    let ps;

    const initPerfectScrollbar = () => {
      if (mainPanelRef.current && !psInitialized) {
        ps = new PerfectScrollbar(mainPanelRef.current, {
          suppressScrollX: true
        });

        setPsInitialized(true);
      }
    };

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      initPerfectScrollbar();
    }

    return function cleanup() {
      if (ps) {
        ps.destroy();
        setPsInitialized(false);
      }

      if (navigator.platform.indexOf("Win") > -1) {
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, [psInitialized]);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  React.useEffect(() => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const token = JSON.parse(localStorage.getItem('token'));
    if (!token || !profile) {
      setTokenAvailable(false);
      setIsLoading(false);
      navigate("/");
    }
    else {
      setTokenAvailable(true);
      setIsLoading(false);
    }
    if (profile) {
      setProfileData(profile);
    }
  }, [navigate]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpened(!sidebarOpened);
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  if (isLoading) {
    return <Loader />
  }
  if (!tokenAvailable) {
    console.log("herrereer");
    return null; // Return null to prevent rendering the requested page
  }
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            <Sidebar
              routes={routes}
              logo={{
                // outterLink: "https://www.creative-tim.com/",
                text: profileData.username,
                imgSrc: profileData.avatar
              }}
              toggleSidebar={toggleSidebar}
            />
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              <Outlet />
              {/* {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              } */}
              <FooterStyle>
                <img src={DevIcon} style={{ width: "15px", height: "15px" }} /> {" "} A work of art by @Wifek
              </FooterStyle>

            </div>
          </div>
          {/* <FixedPlugin bgColor={color} handleBgClick={changeColor} /> */}
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Layout;
