import React from 'react';
import styled from 'styled-components'
import { AddIcon, ArrowIcon, BackArrowIcon, DeleteIcon, EditIcon, QuizThemeIcon, QuizzesIcon } from '../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import profileService from 'services/profile.service';
import { useDispatch } from 'react-redux';
import { themes } from 'redux/reducers/quizSlice';
import moment from 'moment';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=date]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=date]::-webkit-date-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`

const CreateUpdateTheme = ({ modal, toggle, theme }) => {
    const [showNotif, setShowNotif] = React.useState(false);
    const [color, setColor] = React.useState();
    const [message, setMessage] = React.useState("");
    const [categories, setCategories] = React.useState([]);
    const [category, setCategory] = React.useState("");
    const [available, setAvailable] = React.useState();
    const [data, setData] = React.useState({ title: undefined, quizCategory: undefined, startDate: undefined, endDate: undefined, available: true })

    const dispatch = useDispatch();
    const format = "yyyy-MM-DD"

    const inputHandler = (e, index) => {
        const { name, value } = e.target
        if (name === "quizCategory") {
            setCategory(value);
            setData({ ...data, [name]: value })
        }
        else if (name === "available") {
            setAvailable(value === "true" ? true : false);
            setData({ ...data, [name]: value })
        }
        else
            setData({ ...data, [name]: value })
    }

    const AddUpdateTheme = async () => {
        if (theme) { //Update
            data._id = theme._id;
            profileService.updateQuizTheme(data).then((response) => {
                setColor("2");
                setMessage("Quiz theme updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new quiz theme
            if (data.title !== undefined && data.quizCategory !== undefined && data.startDate !== undefined && data.endDate !== undefined) {
                profileService.addQuizTheme(data).then((response) => {
                    setColor("2");
                    setMessage("Quiz theme created successfully!");
                    setShowNotif(true);
                    dispatch(themes({ skip: 0 }));
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
            else {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
        }
    }

    const getCategories = async () => {
        profileService.getAllCategories().then(response => {
            setCategories(response.data.data)
        })
    }

    React.useEffect(() => {
        getCategories();
        if (theme) {
            setCategory(theme.quizCategory);
            setAvailable(theme.available);
            setData({ title: theme.title, quizCategory: theme.quizCategory, startDate: theme.startDate, endDate: theme.endDate, available: theme.available })
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update theme" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={QuizThemeIcon} style={{ height: "20px", width: "20px" }} />  {theme ? 'Update' : 'Add'} Quiz theme</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Quiz theme title </label>
                                    <Input placeholder="Quiz theme title" type="text" defaultValue={theme && theme.title} onChange={inputHandler} name="title" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Quiz category</label><br />
                                    <select value={category} onChange={inputHandler} name="quizCategory">
                                        <option value="" disabled selected hidden>Select quiz category</option>
                                        {categories.map((category) => {
                                            return (
                                                <option key={category._id} value={category._id}>{category.name}</option>
                                            );
                                        })}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Start date </label>
                                    <Input type="date" defaultValue={theme && moment(theme.startDate).format(format)} onChange={inputHandler} name="startDate" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>End date </label>
                                    <Input type="date" defaultValue={theme && moment(theme.endDate).format(format)} onChange={inputHandler} name="endDate" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Available ?</label>
                                    <select value={available} onChange={inputHandler} name="available">
                                        <option value="true" selected>Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button className='post' onClick={AddUpdateTheme}>{theme ? 'Update' : 'Post'}</Button>
                    </Form>
                </CreationForm>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateTheme);