
import React from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import profileService from 'services/profile.service';
import styled from 'styled-components';
import Notification from 'utils/notification';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { categories } from 'redux/reducers/quizSlice';
import { EditIcon, DeleteIcon, CategoryIcon, ThinkingIcon } from 'assets/img';
import CreateUpdateCategory from './CreateUpdateCategory';
import Loader from 'components/UI/Loader';

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .edit{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`
const NoCategories = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
    button{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
`

function QuizCategories() {
    const [quizCategory, setQuizCategory] = React.useState()
    const [quizCategoryId, setQuizCategoryId] = React.useState()
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const dispatch = useDispatch();
    const { loading, quizCategories, pageNumber, error } = useSelector((state) => state.quiz, shallowEqual)

    // Toggle for Modal
    const toggle = (c) => {
        if (c !== undefined) {
            setQuizCategory(c)
        } else {
            setQuizCategory(undefined)
        }
        fetchQuizCategories()
        setModal(!modal)
    }

    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }

    const fetchQuizCategories = async () => {
        dispatch(categories())
    }
    React.useEffect(() => {
        fetchQuizCategories();
    }, [])

    const deleteCategory = async (id) => {
        try {
            profileService.deleteCategory(id).then((response) => {
                setQuizCategoryId("");
                fetchQuizCategories();
                deleteToggle();
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                <Row>
                    <Col md="12">
                        <Card style={{
                            height: '760px',
                            overflowY: 'auto',
                        }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={CategoryIcon} alt="Quizzes" style={{ marginRight: "8px", width: "25px", height: "27px" }} /> Quiz Categories</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" >
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (quizCategories.data && quizCategories.data.length === 0) ?
                                            <NoCategories>
                                                <img src={ThinkingIcon} />
                                                <p>No Categories! Please create one!</p>
                                            </NoCategories>
                                            :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Category Name</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {quizCategories.data && quizCategories.data.map((c, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>{c.name}</td>
                                                                <td>{c.description}</td>

                                                                <td>
                                                                    <ActionStyle>
                                                                        <div className='edit'><img src={EditIcon} alt="edit" onClick={() => { toggle(c); }} /></div>
                                                                        <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setQuizCategoryId(c._id) }} /></div>
                                                                    </ActionStyle>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                    <ModalHeader toggle={deleteToggle}>Delete Quiz Category</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this quiz category?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteCategory(quizCategoryId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                    </ModalFooter>

                </Modal>
                {modal && <CreateUpdateCategory modal={modal} toggle={toggle} category={quizCategory} />}
            </div>
        </>
    )
}

export default QuizCategories;