const GET_ALL_PRODUCTS_ACTION = (payload) => {
    return {
        type: "GET_ALL_PRODUCTS",
        data: payload

    }
}

const GET_PRODUCT_GIFTCARDS_ACTION = (payload) => {
    return {
        type: "GET_PRODUCT_GIFTCARDS",
        data: payload

    }
}
export { GET_ALL_PRODUCTS_ACTION, GET_PRODUCT_GIFTCARDS_ACTION }