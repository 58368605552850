import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://tournaments.t7d.io"
} else {
    BASE_URL = "https://staging.tournaments.t7d.io"
    // BASE_URL = "http://localhost:4000"
}

//Tournament
const getAllTournaments = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournaments/allTournamentsForAdmin`,
        data: payload,
        headers: authHeader()
    });
}

const create = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournaments/createTournament`,
        data: payload,
        headers: authHeader()
    });
}

const duplicate = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournaments/duplicateTournament`,
        data: payload,
        headers: authHeader()
    });
}

const update = async (id, payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/tournaments/updateOneTournament/${id}`,
        data: payload,
        headers: authHeader()
    });
}

const deleteOneTournament = async (id) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/tournaments/softDeleteOneTournament/${id}`,
        headers: authHeader()
    });
}

const getOneTournament = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/tournaments/findOneTournament/${id}`
    });
}
//Participations
const getTournamentPlayersProfile = async (id) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/participations/getTournamentPlayersProfile/${id}`,
        headers: authHeader()
    });
}

const allTournamentParticipations = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/participations/allTournamentParticipations/${id}`
    });
}

const kickPlayer = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/participations/kickPlayer`,
        data: payload,
        headers: authHeader()
    });
}
//Games
const getTournamentGames = async () => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/games/getGames`
    });
}

const getGameById = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/games/getGameById/${id}`
    });
}

const addGame = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/games/addGame`,
        data: payload,
        headers: authHeader()
    });
}

const updateGame = async (id, payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/games/updateOneGame/${id}`,
        data: payload,
        headers: authHeader()
    });
}

// Games categories
const getGamesCategories = async () => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/gamesCategories/getCategories`
    });
}
// Groups
const getGroupPlayers = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_groups/getGroupsPlayers`,
        data: payload
    });
}

const generateGroups = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_groups/generateGroups`,
        data: payload
    });
}

const submitParticipationScore = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_groups/updatedParticipationState`,
        data: payload
    });
}

const generateGroupsWinners = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_groups/setGroupsWinners`,
        data: payload
    });
}

const getWinners = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_groups/getWinners`,
        data: payload
    });
}

const sendCredentials = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_groups/sendcredentialToPlayers`,
        data: payload
    });
}
// Brackets
const getBracketPlayers = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/tournament_brackets/getBracketPlayersForAdmin/${id}`,
        headers: authHeader()
    });
}

const generateBracket = async (id, payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_brackets/generateBrackets/${id}`,
        data: payload,
        headers: authHeader()
    });
}

const submitPlayerScoreInBracket = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/tournament_brackets/setBracketScore`,
        data: payload,
        headers: authHeader()
    });
}

export default {
    getAllTournaments, create, duplicate, update, deleteOneTournament, getOneTournament,
    getTournamentPlayersProfile, allTournamentParticipations, kickPlayer,
    getTournamentGames, getGameById, addGame, updateGame,
    getGamesCategories,
    getGroupPlayers, generateGroups, submitParticipationScore, generateGroupsWinners, getWinners, sendCredentials,
    getBracketPlayers, generateBracket, submitPlayerScoreInBracket
}