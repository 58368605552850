import React from 'react';
import styled from 'styled-components'
import { AddAvatarIcon, AddIcon, ArrowIcon, BackArrowIcon, DeleteIcon, GiftCardIcon } from '../../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button, Tooltip } from "reactstrap";
import { Dialog } from '@reach/dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Notification from '../../../utils/notification';
import { useDispatch } from 'react-redux';
import storeService from 'services/store.service';
import { getProductGiftCards } from 'redux/reducers/storeReducer';
import countries from '../../../assets/data/countries.json'

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 100px;
        height: 80px;
        margin-left: 20px;
    }
`
const ActionStyle = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    .country{
        flex: 0 0 100px;
    }
    .add{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`

const CreateUpdateGiftCard = ({ modal, toggle, giftCard }) => {
    const [categories, setCategories] = React.useState([]);
    const [platforms, setPlatforms] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [regionNames, setRegionNames] = React.useState([]);
    const [icon, setIcon] = React.useState(undefined);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [platform, setPlatform] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [product, setProduct] = React.useState("");
    const [available, setAvailable] = React.useState();
    const [hotDeal, setHotDeal] = React.useState();
    const [visible, setVisible] = React.useState();
    const [rating, setRating] = React.useState();
    const [margins, setMargins] = React.useState([]);
    const [discounts, setDiscounts] = React.useState([]);

    const formData = React.useMemo(() => new FormData(), []);
    const dispatch = useDispatch();

    const inputHandler = (e, index) => {
        const { name, value } = e.target
        if (name === 'icon') {
            formData.set(name, e.target.files[0])
        } else if (name === "categoryId") {
            setCategory(value);
            formData.set(name, value);
        } else if (name === "platformId") {
            setPlatform(value);
            formData.set(name, value);
        } else if (name === "productId") {
            setProduct(value);
            formData.set(name, value);
        } else if (name === "available") {
            setAvailable(value);
            formData.set(name, value);
        } else if (name === "hotDeal") {
            setHotDeal(value);
            formData.set(name, value);
        } else if (name === "visible") {
            setVisible(value);
            formData.set(name, value);
        } else if (name === "rating") {
            setRating(value);
            formData.set(name, value);
        } else if (name === "globalMargin") {
            const allMargins = [...margins];
            allMargins[0] = ["global", parseInt(value)];
            setMargins(allMargins);
            formData.set("margin", JSON.stringify(Object.fromEntries(allMargins)));
        } else if (name === "marginRegions") {
            const allMargins = [...margins];
            const curr = allMargins[index];
            allMargins[index] = [value, curr[1]];
            setMargins(allMargins);
            formData.set("margin", JSON.stringify(Object.fromEntries(allMargins)));
        } else if (name === "specificMargin") {
            const allMargins = [...margins];
            const curr = allMargins[index];
            allMargins[index] = [curr[0], parseInt(value)];
            setMargins(allMargins);
            formData.set("margin", JSON.stringify(Object.fromEntries(allMargins)));
        } else if (name === "globalDiscount") {
            const allDiscounts = [...discounts];
            allDiscounts[0] = ["global", parseInt(value)];
            setDiscounts(allDiscounts);
            formData.set("discount", JSON.stringify(Object.fromEntries(allDiscounts)));
        } else if (name === "discountRegions") {
            const allDiscounts = [...discounts];
            const curr = allDiscounts[index];
            allDiscounts[index] = [value, curr[1]];
            setDiscounts(allDiscounts);
            formData.set("discount", JSON.stringify(Object.fromEntries(allDiscounts)));
        } else if (name === "specificDiscount") {
            const allDiscounts = [...discounts];
            const curr = allDiscounts[index];
            allDiscounts[index] = [curr[0], parseInt(value)];
            setDiscounts(allDiscounts);
            formData.set("discount", JSON.stringify(Object.fromEntries(allDiscounts)));
        } else if (name === "regions") {
            const array = [];
            const countriesName = [];
            const selectedData = e.target.selectedOptions;
            for (let i = 0; i < selectedData.length; i++) {
                countriesName.push(selectedData[i].text);
                array.push(selectedData[i].value);
            }
            setRegions(array);
            setRegionNames(countriesName);
            formData.set(name, JSON.stringify(array));
        } else {
            formData.set(name, value)
        }
    }

    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const tooltipToggle = () => {
        setTooltipOpen(!tooltipOpen)
    }
    const loadFile = (e) => {
        const [file] = e.target.files;
        setIcon(URL.createObjectURL(file));
    };
    const checkObject = (obj) => {
        for (const [key, value] of Object.entries(obj)) {
            if (value === undefined || key === undefined) {
                return false
            }
        }
        return true
    }

    const AddUpdateGiftCard = async () => {

        if (giftCard) { //Update
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            if ((formData.get("margin") !== null && !checkObject(formData.get("margin"))) ||
                (formData.get("discount") !== null && !checkObject(formData.get("discount")))) {
                setColor("3");
                setMessage("Please fill all the margin and discount fields");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                formData.set("_id", giftCard._id);
                storeService.updateGiftCard(formData).then((response) => {
                    setColor("2");
                    setMessage("GiftCard updated successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }

        }
        else { // create new giftCard
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            //  }
            if (formData.get("productId") === null || formData.get("categoryId") === null || formData.get("platformId") === null
                || formData.get("sku") === null || formData.get("description") === null || formData.get("fullDescription") === null
                || formData.get("price") === null || formData.get("originalPrice") === null || formData.get("discountPremium") === null
                || formData.get("gdPrice") === null || formData.get("provider") === null || formData.get("regions") === null
                || formData.get("rating") === null || formData.get("tag") === null || formData.get("available") === null
                || formData.get("activationSteps") === null || formData.get("maxPurchase") === null || formData.get("hotDeal") === null
                || formData.get("visible") === null || formData.get("icon") === null
                || formData.get("discount") === null || !checkObject(formData.get("discount"))
                || formData.get("margin") === null || !checkObject(formData.get("margin"))) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                storeService.addGiftCard(formData).then((response) => {
                    setColor("2");
                    setMessage("GiftCard created successfully!");
                    setShowNotif(true);
                    dispatch(getProductGiftCards({ skip: 0, limit: 30, productId: formData.get("productId") }))
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }
    const getProducts = async () => {
        storeService.getAllProducts().then(response => {
            setProducts(response.data.data)
        })
    }
    const getPlatforms = async () => {
        storeService.getAllPlatforms().then(response => {
            setPlatforms(response.data.data)
        })
    }
    const getCategories = async () => {
        storeService.getAllCategories().then(response => {
            setCategories(response.data.data)
        })
    }
    const handleAddMarginClick = (type) => {
        if (type === "margin") setMargins([...margins, []]);
        else if (type === "discount") setDiscounts([...discounts, []]);
    };
    const handleRemoveMarginClick = (type, index) => {
        if (type === "margin") {
            const list = [...margins];
            list.splice(index, 1);
            formData.set("margin", JSON.stringify(Object.fromEntries(list)));
            setMargins(list);
        }
        else if (type === "discount") {
            const list = [...discounts];
            list.splice(index, 1);
            formData.set("discount", JSON.stringify(Object.fromEntries(list)));
            setDiscounts(list);
        }
    };
    React.useEffect(() => {
        getPlatforms();
        getCategories();
        getProducts();
        if (giftCard) {
            setProduct(giftCard.productId)
            setPlatform(giftCard.platformId)
            setCategory(giftCard.categoryId)
            setRegions(giftCard.regions)
            setRating(giftCard.rating)
            setAvailable(giftCard.available)
            setVisible(giftCard.visible)
            setHotDeal(giftCard.hotDeal)
            setDiscounts(Object.entries(giftCard.discount))
            setMargins(Object.entries(giftCard.margin))
            setRegionNames(giftCard.regions.map((value) => {
                const matchingObject = countries.find(obj => obj.code === value);
                return matchingObject ? matchingObject.name : null;
            }));
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update giftcard" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={GiftCardIcon} style={{ height: "20px", width: "20px" }} />{giftCard ? 'Update' : 'Add'} gift card</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList style={{ backgroundColor: 'transparent' }}>
                        <Tab style={{ marginLeft: "2%" }}>Information</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Description</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Pricing</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Gallery & more</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Product</label><br />
                                                <select value={product} onChange={inputHandler} name="productId">
                                                    <option value="" disabled selected hidden>Select product</option>
                                                    {products.map((product) => {
                                                        return (
                                                            <option key={product._id} value={product._id}>{product.title}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>SKU </label>
                                                <Input placeholder="Gift card SKU" type="text" defaultValue={giftCard && giftCard.sku} onChange={inputHandler} name="sku" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Category</label><br />
                                                <select value={category} onChange={inputHandler} name="categoryId">
                                                    <option value="" disabled selected hidden>Select category</option>
                                                    {categories.map((category) => {
                                                        return (
                                                            <option key={category._id} value={category._id}>{category.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Platform</label><br />
                                                <select value={platform} onChange={inputHandler} name="platformId">
                                                    <option value="" disabled selected hidden>Select platform</option>
                                                    {platforms.map((platform) => {
                                                        return (
                                                            <option key={platform._id} value={platform._id}>{platform.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Provider</label><br />
                                                <Input placeholder="Provider" type="text" defaultValue={giftCard && giftCard.provider} onChange={inputHandler} name="provider" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Region(s)</label><br />
                                                <div style={{ display: 'flex' }}>
                                                    <select style={{ height: '60px' }} multiple={true} value={regions} onChange={inputHandler} name="regions">
                                                        {countries.map((country) => {
                                                            return (
                                                                <option key={country.code} value={country.code}>{country.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <h4 href="#" id="Tooltip">?</h4>
                                                </div>
                                                {regions.toString()}
                                                <Tooltip container='Tooltip' placement="right" isOpen={tooltipOpen} target="Tooltip" toggle={tooltipToggle}>
                                                    For windows: Hold down the control (ctrl) button to select multiple options<br />
                                                    For Mac: Hold down the command button to select multiple options
                                                </Tooltip>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button
                                        className="next"
                                        onClick={() => handleButtonChange(1)}
                                    ><img src={ArrowIcon} /> Next</Button>
                                </Form>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Description</label>
                                                <Input placeholder="Description" type="textarea" defaultValue={giftCard && giftCard.description} onChange={inputHandler} name="description" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Full description</label>
                                                <Input placeholder="Arabic description" type="textarea" defaultValue={giftCard && giftCard.fullDescription} onChange={inputHandler} name="fullDescription" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Activation Steps</label>
                                                <Input placeholder="Activation Steps" type="textarea" defaultValue={giftCard && giftCard.activationSteps} onChange={inputHandler} name="activationSteps" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(0)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>
                                <Button
                                    className='next'
                                    onClick={() => handleButtonChange(2)}
                                ><img src={ArrowIcon} /> Next</Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm style={{ maxHeight: "600px", overflow: "scroll" }}>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Price</label>
                                                <Input placeholder="Price" type="number" step="0.01" defaultValue={giftCard && giftCard.price} onChange={inputHandler} name="price" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Original price</label>
                                                <Input placeholder="Original price" type="number" step="0.01" defaultValue={giftCard && giftCard.originalPrice} onChange={inputHandler} name="originalPrice" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Profit margin</label>
                                                <ActionStyle>
                                                    <p className="country">Global</p>
                                                    <Input className="margin" placeholder="Global margin" type="number" step="0.01" value={margins[0] && margins[0][1]} onChange={inputHandler} name="globalMargin" />
                                                    {(regionNames.length > 0 && margins.length - 1 < regionNames.length) &&
                                                        <div className='add'>
                                                            <img src={AddIcon} alt="add" onClick={() => handleAddMarginClick("margin")} />
                                                        </div>
                                                    }
                                                </ActionStyle>
                                                {margins.length >= 2 && margins.slice(1).map(([key, value], i) => {
                                                    return (
                                                        <ActionStyle>
                                                            <select className="country" value={key} onChange={(e) => inputHandler(e, (i + 1))} name="marginRegions">
                                                                <option value="" disabled selected hidden>Select region</option>
                                                                {regionNames.map((region, index) => {
                                                                    return (
                                                                        <option key={index} value={region}>{region}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                            <Input className="margin" placeholder="Special country margin" type="number" step="0.01" value={value} onChange={(e) => inputHandler(e, (i + 1))} name="specificMargin" />
                                                            {margins.length !== 1 && <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => handleRemoveMarginClick("margin", (i + 1))} /></div>}
                                                        </ActionStyle>
                                                    )
                                                })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Discount</label>
                                                <ActionStyle>
                                                    <p className="country">Global</p>
                                                    <Input className="discount" placeholder="Global Discount" type="number" step="0.01" value={discounts[0] && discounts[0][1]} onChange={inputHandler} name="globalDiscount" />
                                                    {(regionNames.length > 0 && discounts.length - 1 < regionNames.length) &&
                                                        <div className='add'>
                                                            <img src={AddIcon} alt="add" onClick={() => handleAddMarginClick("discount")} />
                                                        </div>
                                                    }
                                                </ActionStyle>
                                                {discounts.length >= 2 && discounts.slice(1).map(([key, value], i) => {
                                                    return (
                                                        <ActionStyle>
                                                            <select className="country" value={key} onChange={(e) => inputHandler(e, (i + 1))} name="discountRegions">
                                                                <option value="" disabled selected hidden>Select region</option>
                                                                {regionNames.map((region, index) => {
                                                                    return (
                                                                        <option key={index} value={region}>{region}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                            <Input className="discount" placeholder="Special country discount" type="number" step="0.01" value={value} onChange={(e) => inputHandler(e, (i + 1))} name="specificDiscount" />
                                                            {discounts.length !== 1 && <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => handleRemoveMarginClick("discount", (i + 1))} /></div>}
                                                        </ActionStyle>
                                                    )
                                                })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Premium discount</label>
                                                <Input placeholder="Premium discount" type="number" step="0.01" defaultValue={giftCard && giftCard.discountPremium} onChange={inputHandler} name="discountPremium" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>GD Price</label>
                                                <Input placeholder="GD Price" type="number" step="0.01" defaultValue={giftCard && giftCard.gdPrice} onChange={inputHandler} name="gdPrice" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Maximum Purchase</label>
                                                <Input placeholder="Maximum Purchase" type="number" defaultValue={giftCard && giftCard.maxPurchase} onChange={inputHandler} name="maxPurchase" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(1)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>
                                <Button
                                    className='next'
                                    onClick={() => handleButtonChange(3)}
                                ><img src={ArrowIcon} /> Next</Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Icon </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e) }} name="icon" />
                                                </Button>
                                                <img src={(giftCard && icon === undefined) ? giftCard.icon : icon} className="avatar" alt="icon" />
                                            </FormGroup>
                                        </Col>
                                    </Row> {/* ['rating', 'tag', 'available', visible, hotDeal] */}
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Rating</label>
                                                <select value={rating} onChange={inputHandler} name="rating">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Tag</label>
                                                <Input placeholder="Tag" type="text" defaultValue={giftCard && giftCard.tag} onChange={inputHandler} name="tag" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Available ?</label>
                                                <select value={available} onChange={inputHandler} name="available">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Visible ?</label>
                                                <select value={visible} onChange={inputHandler} name="visible">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Hot Deal ?</label>
                                                <select value={hotDeal} onChange={inputHandler} name="hotDeal">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(2)}
                                ><img src={BackArrowIcon} /> Back</Button>

                                <Button className='post' onClick={AddUpdateGiftCard}>{giftCard ? 'Update' : 'Post'}</Button>
                            </CreationForm>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateGiftCard);