import NoData from "components/UI/NoData";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";


function Forbidden() {
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <NoData text={`403 Access Denied. You Do Not Have The Permission To Access This Page.`} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        </>
    );
}

export default Forbidden;

