import React from 'react';
import styled from 'styled-components'
import { CategoryIcon } from '../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import profileService from 'services/profile.service';
import { useDispatch } from 'react-redux';
import { categories } from 'redux/reducers/quizSlice';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`

const CreateUpdateCategory = ({ modal, toggle, category }) => {
    const [showNotif, setShowNotif] = React.useState(false);
    const [color, setColor] = React.useState();
    const [message, setMessage] = React.useState("");
    const [data, setData] = React.useState({ name: undefined, description: undefined })

    const dispatch = useDispatch();

    const inputHandler = (e, index) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const AddUpdateCategory = async () => {
        if (category) { //Update
            profileService.updateCategory(category._id, data).then((response) => {
                setColor("2");
                setMessage("Quiz category updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new category
            if (data.name !== undefined && data.description !== undefined) {
                profileService.addCategory(data).then((response) => {
                    setColor("2");
                    setMessage("Quiz category created successfully!");
                    setShowNotif(true);
                    dispatch(categories());
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
            else {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
        }
    }

    React.useEffect(() => {
        if (category) {
            setData({ name: category.name, description: category.description })
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update category" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={CategoryIcon} style={{ height: "20px", width: "20px" }} />  {category ? 'Update' : 'Add'} Quiz category</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>

                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Name </label>
                                    <Input placeholder="Category name" type="text" defaultValue={category && category.name} onChange={inputHandler} name="name" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Description </label>
                                    <Input placeholder="Category description" type="textarea" defaultValue={category && category.description} onChange={inputHandler} name="description" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className='post' onClick={AddUpdateCategory}>{category ? 'Update' : 'Post'}</Button>
                    </Form>
                </CreationForm>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateCategory);