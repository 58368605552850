import axios from "axios";
import authHeader from "./auth.header";

let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
  BASE_URL = "https://admin-back.t7d.io"
} else {
  BASE_URL = "https://staging.admin-back.t7d.io"
  // BASE_URL = "http://localhost:3002"
}

const login = async (playload) => {
  return await axios({
    method: 'post',
    url: `${BASE_URL}/admins/login`,
    data: playload,
    headers: {
      'X-Device-Identifier': localStorage.getItem('deviceIdentifier')
    }
  });
}

const verifyCode = async (playload) => {
  return await axios({
    method: 'post',
    url: `${BASE_URL}/admins/verifyCode`,
    data: playload,
    headers: {
      'X-Device-Identifier': localStorage.getItem('deviceIdentifier')
    }
  });
}

const register = async (playload) => {
  return await axios({
    method: 'post',
    url: `${BASE_URL}/admins/register`,
    data: playload
  });
}

const forgotPassword = (playload) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/admins/forgot-password`,
    data: playload
  });
}

const resetPassword = (playload, token) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/admins/reset-password/${token}`,
    data: playload
  })
}

const allAdmins = (playload) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/admins/`,
    data: playload,
    headers: authHeader()
  })
}

const createAdmin = (playload) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/admins/createAdmin`,
    data: playload,
    headers: authHeader()
  })
}

const resetOtherProfilePassword = (playload) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/admins/resetPassword`,
    data: playload,
    headers: authHeader()
  })
}

const updateAdminProfile = (playload, id) => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/admins/${id}`,
    data: playload,
    headers: authHeader()
  })
}

// Avatar
const addAvatar = (playload) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/avatars`,
    data: playload,
    headers: authHeader()
  })
}

const deleteAvatar = (id) => {
  return axios({
    method: 'delete',
    url: `${BASE_URL}/avatars/${id}`,
    headers: authHeader()
  })
}

const allAvatars = (playload) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/avatars/getAvatars`,
    data: playload,
    headers: authHeader()
  })
}

// Roles
const allRoles = () => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/roles/`
  })
}

// Companies
const allCompanies = () => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/companies/`
  })
}

export default {
  login, verifyCode, register, forgotPassword, resetPassword,
  allAdmins, createAdmin, resetOtherProfilePassword, updateAdminProfile,
  addAvatar, allAvatars, deleteAvatar,
  allRoles,
  allCompanies
}