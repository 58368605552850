import React from 'react'
import { Button } from 'reactstrap';
import SendCredentials from './SendCredentials';
import ShowMorePlayers from './ShowMorePlayers';

const GroupPlayers = ({ tournamentId, tournamentTitle, tournamentGame, lvl, players, groupNb }) => {
    const [morePlayersModal, setMorePlayersModal] = React.useState(false)
    const [sendCredentialsModal, setSendCredentialsModal] = React.useState(false)
    
    const toggleMorePlayers = () => {
        setMorePlayersModal(!morePlayersModal)
    }
    const toggleSendCredentials = () => {
        setSendCredentialsModal(!sendCredentialsModal)
    }
    return (<>
        {morePlayersModal && <ShowMorePlayers toggle={toggleMorePlayers} modal={morePlayersModal} players={players} groupNumber={groupNb} tournamentId={tournamentId} tournamentGame={tournamentGame} lvl={lvl} />}
        {sendCredentialsModal && <SendCredentials toggle={toggleSendCredentials} modal={sendCredentialsModal} groupNb={groupNb} id={tournamentId} tournamentTitle={tournamentTitle} lvl={lvl} />}

        {players.sort((a, b) => { return b.score.overallScore - a.score.overallScore }).slice(0, 4).map(p => {
            return (
                <p>{p.user.username}</p>
            )
        })}
        <div className='button'>
            <Button onClick={() => toggleMorePlayers()} color="primary">Show more players</Button>
            <br/>
            <Button onClick={() => toggleSendCredentials()} color="primary">Send credentials</Button>
        </div>
    </>);
}

export default GroupPlayers;