import React from "react";
import { Card, CardBody, Table, Row, Col, CardHeader, CardTitle, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginationBox from "components/UI/PaginationBox";
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/adminSlice';
import Loader from "components/UI/Loader";
import { useNavigate } from "react-router-dom";
import NoData from "components/UI/NoData";
import { fetchAdmins } from "redux/reducers/adminSlice";
import authService from "services/auth.service";
import { ResetPassword } from "assets/img";
import Notification from "utils/notification";
import { AdminsIcon } from "assets/img";

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .reset{
        cursor: pointer;
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
`
const SearchDiv = styled.div`
    display: flex;
    gap: 10px;
    input{
        background-color: #322e60;
        border-radius: 17px;
        width: 250px;
    }
    > * {
        flex: 0 0 auto;
    }
    button{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
`
const SortByDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: fit-content;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`

const Admins = () => {
    const [role, setRole] = React.useState("All");
    const [company, setCompany] = React.useState("All");
    const [roles, setRoles] = React.useState([]);
    const [companies, setCompanies] = React.useState([]);
    const [adminId, setAdminId] = React.useState();
    const [username, setUsername] = React.useState();
    const [searchedName, setSearchedName] = React.useState();
    const [modalResetPassword, setModalResetPassword] = React.useState(false);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const { loading, admins, pageNumber } = useSelector(state => state.admins, shallowEqual)

    React.useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile) {
            setIsSuperAdmin(profile.roleId.title === 'super admin');
        }
        getAllAdmins();
    }, [pageNumber])

    const toggleResetPassword = () => {
        setModalResetPassword(!modalResetPassword);
    }

    const resetPassword = async (id) => {
        try {
            authService.resetOtherProfilePassword({ userId: id }).then((response) => {
                toggleResetPassword();
                setColor("2");
                setMessage(`${username}'s password has been reset successfully!`);
                setAdminId();
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 2000);
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    const getRoles = async () => {
        authService.allRoles().then(response => {
            setRoles(response.data.data)
        })
    }
    const getCompanies = async () => {
        authService.allCompanies().then(response => {
            setCompanies(response.data.data)
        })
    }

    React.useEffect(() => {
        getRoles();
        getCompanies();
    }, [])

    const getAllAdmins = () => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        dispatch(fetchAdmins({ pageNumber, skip, email: searchedName, role, company }))
    }
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }

    const navigateToAdmin = (id) => {
        navigate(id);
    };

    const searchProfile = (searchedRole, searchedCompany) => {
        dispatch(fetchAdmins({ pageNumber: 1, skip: 0, email: searchedName, role: searchedRole, company: searchedCompany }))
    }

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={AdminsIcon} alt="Store" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Admins</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SearchDiv>
                                            <h4>Search by email:</h4>
                                            <Input placeholder="Search admin" type="text" onChange={(e) => setSearchedName(e.target.value)} onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    searchProfile(role)
                                                }
                                            }} name="email" />
                                            <Button onClick={() => searchProfile(role)}>Search</Button>
                                        </SearchDiv>
                                        <SortByDiv>
                                            <h5>Role: </h5>
                                            <select defaultValue="" onChange={(e) => { setRole(e.target.value); searchProfile(e.target.value) }} name="role">
                                                <option key={1} value="All">All</option>
                                                {roles && roles.map((role) => {
                                                    return (
                                                        <option key={role._id} value={role._id}>{role.title}</option>
                                                    );
                                                })}
                                            </select>
                                            <h5>Company: </h5>
                                            <select defaultValue="" onChange={(e) => { setCompany(e.target.value); searchProfile(role, e.target.value) }} name="company">
                                                <option key={1} value="All">All</option>
                                                {companies && companies.map((company) => {
                                                    return (
                                                        <option key={company._id} value={company._id}>{company.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortByDiv>
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* <Filters /> */}
                                <div className="table-full-width table-responsive" style={{
                                    height: '565px',
                                    overflowY: 'auto',
                                }}>
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        !admins.data ? <NoData text={"No Admins!"} /> :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Admin</th>
                                                        <th>Email</th>
                                                        <th>Role</th>
                                                        <th>Company</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {admins.data.map((admin, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ cursor: "pointer" }} >{/* onClick={() => { navigateToProfile(p._id) }}> */}
                                                                    <div style={{ display: "inline-flex", gap: "30px" }}>
                                                                        <div className="photo">
                                                                            <img src={admin.avatar} />
                                                                        </div>
                                                                        {admin.username}
                                                                    </div>
                                                                </td>
                                                                <td>{admin.email}</td>
                                                                <td>{admin.roleId.title}</td>
                                                                <td>{admin.companyId.name}</td>
                                                                {isSuperAdmin && <td>
                                                                    <ActionStyle>
                                                                        <div className='reset'>
                                                                            <img src={ResetPassword} alt="reset" onClick={() => { setUsername(admin.username); setAdminId(admin._id); toggleResetPassword() }} />
                                                                        </div>
                                                                    </ActionStyle>
                                                                </td>}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(admins.total / limit)} totalNumber={admins.total} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
                <Modal isOpen={modalResetPassword} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={toggleResetPassword} >
                    <ModalHeader toggle={toggleResetPassword}>Reset {username}'s password</ModalHeader>
                    <ModalBody>
                        Are you sure you want to reset {username}'s password ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => resetPassword(adminId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={toggleResetPassword}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
};
export default Admins;
