import React from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import { Button, FormGroup, Form, Input, Row, Col, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import Notification from '../../utils/notification';
import authService from 'services/auth.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const Container = styled.div`
    position: relative;
    height: auto;
    width: 40%;
    max-width: 500px;
    left: 12%;
    top: 25%;
    background-color: rgba(117,123,168,0.18);
    border-radius: 17px;
`

const Header = styled.div`
    height: 10%;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    background-color: rgba(117,123,168,0.06);

    h1{
        width: 80%;
        color: #9ca2cf;
        font-size: 26px;
        text-align:center;
        margin:0;
        padding: 25px 0px 25px 0px;
    
}
`

const SigninForm = styled.div`
    position: relative;
    height: 100%;
    width: 70%;
    gap: 30px;
    padding: 15px 10px 20px 10px;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input, .input-group, .input-group-text{
        background-color: #322e60;
        border-radius: 17px;
    }
    button{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    p{
        color: #525f7f;
        text-align: center;
        font-size: 12px;
    }
`
const InputContainer = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
border-radius: 15615561665px;
    pointer-events: none;

`
const Signin = () => {
    const [showNotif, setShowNotif] = React.useState(false)
    const [showPwd, setShowPwd] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [credentials, setCredentials] = React.useState({})
    const [newDevice, setNewDevice] = React.useState(false)
    const [code, setCode] = React.useState()
    const [email, setEmail] = React.useState()

    const inputHandler = (e) => {
        const { name, value } = e.target
        setCredentials(old => {
            return {
                ...old,
                [name]: value
            }
        })
    }
    const navigate = useNavigate();
    const sendRequest = () => {
        if (credentials.username !== undefined && credentials.password !== undefined) {
            authService.login(credentials).then(response => {
                if (response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify(response.data.data.admin));
                    localStorage.setItem('token', JSON.stringify(response.data.data.token));
                    navigate("/Dashboard");
                }
                else if (response.status === 202) {
                    setEmail(response.data.email)
                    setColor("5");
                    setMessage("New device detected! Check your email for verification code.");
                    setShowNotif(true);
                    setNewDevice(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
            }).catch(error => {
                setColor("3");
                setMessage("Wrong credentials!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            })
        } else {
            setColor("3");
            setMessage("Please enter your credentials!");
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }

    const submitCode = () => {
        if (code !== undefined) {
            authService.verifyCode({ email, code }).then(response => {
                if (response.status === 200) {
                    localStorage.setItem('profile', JSON.stringify(response.data.data.admin));
                    localStorage.setItem('token', JSON.stringify(response.data.data.token));
                    navigate("/Dashboard");
                }
            }).catch(error => {
                setColor("3");
                setMessage("Wrong code!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            })
        } else {
            setColor("3");
            setMessage("Please enter the verification code!");
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }

    const showPassword = () => {
        setShowPwd(!showPwd)
    }
    const generateDeviceIdentifier = async () => {
        const deviceIdentifier = localStorage.getItem('deviceIdentifier');
        if (!deviceIdentifier) {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            localStorage.setItem('deviceIdentifier', visitorId);
        }
    };
    React.useEffect(() => {
        localStorage.removeItem("profile");
        localStorage.removeItem("token");
        generateDeviceIdentifier();
    }, [])
    return (
        <>
            <Container>
                {showNotif && <Notification color={color} message={message} />}

                <Header><h1>T7Dash</h1></Header>
                <SigninForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 25px 0px" }}>
                        {!newDevice ?
                            <>
                                <Row style={{ width: "100%" }}>
                                    <Col className="px-md-1" md="12">
                                        <FormGroup>
                                            <label>Username</label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText >
                                                        <i className="fa fa-user"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input className='inputContainer'
                                                    style={{ border: 0 }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            sendRequest()
                                                        }
                                                    }}
                                                    placeholder="Enter your T7D username"
                                                    type="text"
                                                    onChange={inputHandler}
                                                    name="username"
                                                />
                                                <InputContainer />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText >

                                                    </InputGroupText>
                                                </InputGroupAddon>

                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col className="px-md-1" md="12">
                                        <FormGroup>
                                            <label>Password </label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input className='inputContainer'
                                                    style={{ border: 0 }}

                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            sendRequest()
                                                        }
                                                    }}
                                                    placeholder="Enter your T7D access key"
                                                    type={showPwd ? "text" : "password"}
                                                    onChange={inputHandler}
                                                    name="password" />
                                                <InputContainer />

                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText onClick={showPassword}>
                                                        {showPwd ? <i className="fa fa-eye" /> : <i className="fa fa-eye-slash" />}
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> :
                            <Row style={{ width: "100%" }}>
                                <Col className="px-md-1" md="12">
                                    <FormGroup>
                                        <label>Code</label>
                                        <Input placeholder="Code" type="text" onChange={(e) => setCode(e.target.value)} name="code" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        {!newDevice ?
                            <Button style={{ marginTop: "20px", width: "97%" }} className="btn-fill" color="primary" onClick={sendRequest}>
                                Sign in
                            </Button>
                            :
                            <Button style={{ marginTop: "20px", width: "97%" }} className="btn-fill" color="primary" onClick={submitCode}>
                                Submit
                            </Button>
                        }
                    </Form>
                    <p>This is a T7DGaming Limited Dashboard.
                        <br />Access Limited to Staff Members.</p>
                </SigninForm>
            </Container>
        </>
    )
}

export default Signin