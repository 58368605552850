import React from "react";
import NotificationAlert from "react-notification-alert";

const Notification = ({ color, message }) => {
    const notificationAlertRef = React.useRef(null);

    const notify = (color, message) => {
        let type;
        switch (color) {
            case "1":
                type = "primary";
                break;
            case "2":
                type = "success";
                break;
            case "3":
                type = "danger";
                break;
            case "4":
                type = "warning";
                break;
            case "5":
                type = "info";
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: "bc",
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    React.useEffect(() => {
        notify(color, message);
    }, [])

    return (
        <>
            <div className="content">
                <div className="react-notification-alert-container">
                    <NotificationAlert ref={notificationAlertRef} />
                </div>
            </div>
        </>
    );
}

export default Notification;