import React from 'react'
import styled from 'styled-components';
import tournamentService from 'services/tournament.service';
import { useDispatch, useSelector } from 'react-redux';
import { getWinners } from '../../../redux/reducers/tournamentReducer';
import { ThinkingIcon } from 'assets/img';
import { Table } from 'reactstrap';
import GrantGD from './GrantGD';
import { AddIcon } from 'assets/img';

const Grid = styled.div`
    margin: 30px 0 0 50px;
    width: 90%;
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    position: relative;
    gap:20px;
    .title{
        margin: auto;
        padding: 8px;
        height: 34px;
        width: 100%;
        color: #fe4958;
        text-indent: 10px;
    }
`
const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .grantGD{
        cursor: pointer;
    }
`

const NoResults = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`
const NumberDiv = styled.div`
    width: 35px;
    font-size: 20px;
    text-align: center;
    border-radius: 13px;
    background-image: linear-gradient(to bottom, rgba(27, 37, 115,.13), rgba(255, 70, 94,.13));
`


const GDWinners = ({ id, format, nbRounds }) => {
    const [bracketsWinners, setBracketsWinners] = React.useState([])
    const [profileId, setProfileId] = React.useState();
    const [username, setUsername] = React.useState();
    const [modal, setModal] = React.useState(false);

    const dispatch = useDispatch()
    const winners = useSelector(state => format === "Single Elimination"
        ? state.tournament.knockoutsWinners
        : format === "Groups" ? state.tournament.finalWinners : [])


    React.useEffect(() => {
        if (format === "Single Elimination")
            dispatch(getWinners({ tournament_id: id, lvl: "knockouts", condition: "overallScore" }))
        else if (format === "Groups")
            dispatch(getWinners({ tournament_id: id, lvl: "final", condition: "overallScore" }))
        else if (format === "Brackets") {
            tournamentService.getBracketPlayers(id).then((response) => {
                const lastRoundPlayers = response.data.data.filter(match => match.tournamentRoundText === nbRounds)
                if (lastRoundPlayers[0].participants[0].isWinner || lastRoundPlayers[0].participants[1].isWinner);
                setBracketsWinners(lastRoundPlayers[0].participants)
            }).catch((error) => {
                console.log(error.message);
            })
        }
    }, [])
    const toggle = (id, name) => {
        if (id !== undefined) {
            setProfileId(id);
            setUsername(name);
        } else {
            setProfileId(undefined);
            setUsername(undefined);
        }
        setModal(!modal)
    }

    if (winners.length === 0 && bracketsWinners.length === 0) {
        return (
            <NoResults>
                <img src={ThinkingIcon} />
                <p>The tournament is not over yet!<br />
                    See you later Wrathogod</p>
            </NoResults>
        )
    }

    return (
        <>
            {modal && <GrantGD modal={modal} toggle={toggle} profileId={profileId} username={username} />}
            <Grid>
                {bracketsWinners.length !== 0 ?
                    <>
                        <Table>
                            <thead className="text-primary">
                                <tr>
                                    <th className="text-center">Winners</th>
                                    <th className="text-center">Grant GD</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bracketsWinners.map((winner, index) => {
                                    return (
                                        <tr key={index} >
                                            <td>
                                                <div style={{ display: "inline-flex", gap: "30px" }}>
                                                    <NumberDiv style={{ color: '#fe4958' }}>{index + 1}</NumberDiv>
                                                    <div className="photo">
                                                        <img src={winner.avatar} />
                                                    </div>
                                                    {winner.name}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <ActionStyle>
                                                    <div className='grantGD'>
                                                        <img src={AddIcon} alt="Grant GD" onClick={() => toggle(winner.id, winner.name)} />
                                                    </div>
                                                </ActionStyle>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </>
                    :
                    <>
                        {winners.map((groupWinners, index) => {
                            return (
                                <Table>
                                    <thead className="text-primary">
                                        <tr className='title'>Group {(index + 1)}</tr>
                                        <tr>
                                            <th>Winners</th>
                                            <th className="text-center">Grant GD</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupWinners.sort((a, b) => { return b.overallScore - a.overallScore }).map((winner, index) => {
                                            return (
                                                <tr key={index} >
                                                    <td>
                                                        <div style={{ display: "inline-flex", gap: "30px" }}>
                                                            <NumberDiv style={{ color: '#fe4958' }}>{index + 1}</NumberDiv>
                                                            <div className="photo">
                                                                <img src={winner.userData.avatar} />
                                                            </div>
                                                            {winner.userData.username}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <ActionStyle>
                                                            <div className='grantGD'>
                                                                <img src={AddIcon} alt="Grant GD" onClick={() => toggle(winner.userData._id, winner.userData.username)} />
                                                            </div>
                                                        </ActionStyle>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            )
                        })
                        }
                    </>
                }
            </Grid>
        </>
    );
}

export default GDWinners;