import { combineReducers } from 'redux'

import { tournamentReducer } from './tournamentReducer.js'
import { storeReducer } from './storeReducer.js'
import orderSlice from './orderSlice.js'
import quizSlice from './quizSlice.js'
import profileSlice from './profileSlice.js'
import adminSlice from './adminSlice.js'
import GdSlice from './GdSlice.js'
import supportSlice from './supportSlice.js'
import miniGameSlice from './miniGameSlice.js'
import tournamentSlice from './tournamentSlice.js'

const AllReducers = combineReducers({
    tournament: tournamentReducer,
    tournamentSlice: tournamentSlice,
    store: storeReducer,
    orders: orderSlice,
    quiz: quizSlice,
    profiles: profileSlice,
    admins: adminSlice,
    gd: GdSlice,
    support: supportSlice,
    miniGames: miniGameSlice
})

export default AllReducers