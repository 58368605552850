import axios from 'axios'
import React from 'react'
import moment from 'moment'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from 'reactstrap'
import {
    GameIcon, Pro, Solo, Premium, Team, Free, CalendarIcon, Check, UserIcon, Settings, TagIcon, Description, ChatIcon, MoneyIcon,
    One, Two, Three, ClockIcon, RedLike
} from '../../assets/img'
import tournamentService from 'services/tournament.service'

const Overview = ({ tournamentId }) => {
    const [game, setGame] = React.useState()
    const [tournament, setTournament] = React.useState()

    const getTournament = async () => {
        tournamentService.getOneTournament(tournamentId).then((response) => {
            setTournament(response.data.data)
            tournamentService.getGameById(response.data.data.gameId._id).then(response => {
                setGame(response.data.data)
            })
        })
    }

    React.useEffect(() => {
        getTournament()
    }, [])

    return (<>
        <br />
        <Row>
            <Col lg="4">
                <Card className="card-chart" style={{ height: "145px", overflow: "auto" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {/* Image */}
                        {tournament && <div style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px", height: "145px", width: "99px", backgroundImage: `url(${tournament.cover})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>}
                        {/* End image */}
                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <CardHeader >
                                <CardTitle tag="h4">
                                    <i className="card-category" /> <img src={TagIcon} /> Tournament title
                                </CardTitle>
                            </CardHeader>
                            <CardBody style={{ color: "#757ba8" }}>
                                {tournament && tournament.title}
                                <div style={{ display: "flex" }}>
                                    {tournament && tournament.playersType.name === "Free" ? <img width="73px" height="22px" margin="9px 32px 11px 15px" src={Free} /> :
                                        tournament && tournament.playersType.name === "Pro" ? <img width="58px" height="23px" margin="36px 10px 10px 63px" src={Pro} /> :
                                            <img width="22px" height="23px" margin="9px 9.2px 10px 23px" src={Premium} />}
                                    {tournament && tournament.tournamentType === "Solo" ? <img width="56.6px" height="22px" src={Solo} /> : <img width="63.6px" height="23px" margin="9px 5.2px 10px 9.2px" src={Team} />}
                                </div>
                            </CardBody>
                        </div>
                    </div>
                </Card>
            </Col>
            <Col lg="4">
                <Row>
                    <Col lg="6">
                        <Card className="card-chart" style={{ height: "145px" }}>
                            <CardHeader className='text-center'>
                                <CardTitle tag="h4">
                                    <i className="card-category" /><img src={CalendarIcon} /> Tournament start
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='text-center' style={{ color: "#757ba8" }}>
                                {tournament && moment.utc(tournament.scheduledStartDateTime).format('ll')} {tournament && moment.utc(tournament.scheduledStartDateTime).format('LT')}<br />
                                {tournament && moment.utc(tournament.scheduledEndDateTime).format('ll')} {tournament && moment.utc(tournament.scheduledEndDateTime).format('LT')}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card className="card-chart" style={{ height: "145px" }}>
                            <CardHeader className='text-center' >
                                <CardTitle tag="h4">
                                    <i className="card-category" /><img src={CalendarIcon} /> Registration phase
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='text-center' style={{ color: "#757ba8" }}>
                                {tournament && moment.utc(tournament.registrationOpeningDateTime).format('ll')} {tournament && moment.utc(tournament.registrationOpeningDateTime).format('LT')}<br />
                                {tournament && moment.utc(tournament.registrationClosingDateTime).format('ll')} {tournament && moment.utc(tournament.registrationClosingDateTime).format('LT')}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col lg="4">
                <Row>
                    <Col lg="6">
                        <Card className="card-chart" style={{ height: "145px" }}>
                            <CardHeader className='text-center' >
                                <CardTitle tag="h4">
                                    <i className="card-category" /><img src={UserIcon} /> Capacity
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='text-center' style={{ color: "#757ba8" }}>
                                {tournament && tournament.size}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card className="card-chart" style={{ height: "145px" }}>
                            <CardHeader className='text-center' >
                                <CardTitle tag="h4">
                                    <i className="card-category" /> <img src={Settings} /> State
                                </CardTitle>
                            </CardHeader>
                            <CardBody className='text-center' style={{ color: "#757ba8" }}>
                                {tournament && Date.parse(tournament.registrationOpeningDateTime) > new Date().getTime() ? <p style={{ color: "#e57d07" }}><img src={ClockIcon} /> Upcoming</p> :
                                    (tournament && (Date.parse(tournament.scheduledStartDateTime) <= new Date().getTime() && Date.parse(tournament.scheduledEndDateTime) >= new Date().getTime()) || tournament && Date.parse(tournament.scheduledStartDateTime) > new Date().getTime() ?
                                        <p style={{ color: "#23e35d" }}><img src={Check} /> Active</p> :
                                        <p style={{ color: "#fe4958" }}><img src={RedLike} /> Completed</p>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col lg="4">
                <Row>
                    <Col lg="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <i className="card-category" /> <img src={GameIcon} /> Game
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table borderless>
                                    <tbody>
                                        <tr>
                                            <td>{game && game.title}</td>
                                        </tr>
                                        <tr>
                                            <td>Gategory: {game && game.categoryId !== undefined && game.categoryId.title}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Card className="card-chart" style={{ height: "280px", overflow: "auto" }}>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <i className="card-category" /> <img src={ChatIcon} /> Description
                                </CardTitle>
                            </CardHeader>
                            <CardBody style={{ color: "#757ba8" }}>
                                {tournament && tournament.description}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col lg="4">
                <Card className="card-chart" style={{ height: "300px", overflow: "auto" }}>
                    <CardHeader>
                        <CardTitle tag="h4" style={{ textAlign: 'center' }}>
                            <i className="card-category" /> <img src={Description} /> Rules
                        </CardTitle>
                    </CardHeader>
                    <CardBody style={{ color: "#757ba8" }}>
                        {tournament && tournament.rules}
                    </CardBody>
                </Card>
            </Col>
            <Col lg="4">
                <Card className="card-chart">
                    <CardHeader>
                        <CardTitle tag="h4" style={{ textAlign: 'center' }}>
                            <i className="card-category" /> <img src={MoneyIcon} /> Prize pool
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Table borderless>
                            <tbody>
                                <tr className='text-center' style={{ color: "#757ba8" }}>
                                    <td><img src={One} style={{ width: "43px", height: "47px", marginRight: "10px" }} /> Player</td>
                                    <td>{tournament && tournament.prizes.prize1}</td>
                                </tr>
                                <tr className='text-center' style={{ color: "#757ba8" }}>
                                    <td><img src={Two} style={{ width: "43px", height: "47px", marginRight: "10px" }} /> Player</td>
                                    <td>{tournament && tournament.prizes.prize2}</td>
                                </tr>
                                <tr className='text-center' style={{ color: "#757ba8" }}>
                                    <td><img src={Three} style={{ width: "43px", height: "47px", marginRight: "10px" }} /> Player</td>
                                    <td>{tournament && tournament.prizes.prize3}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>);
}

export default Overview;