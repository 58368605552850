import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://products.t7d.io"
} else {
    // BASE_URL = "http://localhost:3001"
    BASE_URL = "https://staging.products.t7d.io"
}

//Products
const getAllProducts = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/products/getProductsForAdmin`,
        data: payload,
        headers: authHeader()
    });
}

const addProduct = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/products/addproduct`,
        data: payload,
        headers: authHeader()
    });
}

const updateProduct = async (payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/products/updateOneProduct`,
        data: payload,
        headers: authHeader()
    });
}

const deleteProduct = async (payload) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/products/deleteOneProduct`,
        data: payload,
        headers: authHeader()
    });
}

const getOneProduct = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/products/getProductById/${id}`
    });
}

//Giftcard
const getGiftCards = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/giftCard/getGiftCardsForAdmin`,
        data: payload,
        headers: authHeader()
    });
}

const addGiftCard = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/giftCard/addGiftCard`,
        data: payload,
        headers: authHeader()
    });
}

const updateGiftCard = async (payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/giftCard/updateOneGiftCard`,
        data: payload,
        headers: authHeader()
    });
}

const deleteGiftCard = async (payload) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/giftCard/deleteOneGiftCard`,
        data: payload,
        headers: authHeader()
    });
}

// Category
const getAllCategories = async (payload) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/categories/getAllCategory`
    });
}

const addCategory = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/categories/addCategory`,
        data: payload,
        headers: authHeader()
    });
}

const updateCategory = async (payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/categories/updateCategory`,
        data: payload,
        headers: authHeader()
    });
}

const deleteCategory = async (payload) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/categories/deleteCategory`,
        data: payload,
        headers: authHeader()
    });
}

// Platform
const getAllPlatforms = async (payload) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/platform/getAllPlatform`
    });
}

const addPlatform = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/platform/addPlatform`,
        data: payload,
        headers: authHeader()
    });
}

const updatePlatform = async (payload) => {
    return await axios({
        method: 'put',
        url: `${BASE_URL}/platform/updatePlatform`,
        data: payload,
        headers: authHeader()
    });
}

const deletePlatform = async (payload) => {
    return await axios({
        method: 'delete',
        url: `${BASE_URL}/platform/deletePlatform`,
        data: payload,
        headers: authHeader()
    });
}

export default {
    getAllProducts, addProduct, updateProduct, deleteProduct, getOneProduct,
    getGiftCards, addGiftCard, updateGiftCard, deleteGiftCard,
    getAllCategories, addCategory, updateCategory, deleteCategory,
    getAllPlatforms, addPlatform, updatePlatform, deletePlatform
}