import React from 'react'
import { Button } from 'reactstrap';
import ShowRestOfGroup from './ResultsShowRestOfGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from 'redux/reducers/tournamentReducer';

const ResultsGroupWinners = ({ tournamentId, tournamentGame, tournament, lvl, GroupWinners, players, groupNb }) => {
    const [modal, setModal] = React.useState(false)

    const dispatch = useDispatch()
    const data = {
        tournament_id: tournamentId,
        lvl
    }
    React.useEffect(() => {
        dispatch(getGroups(data))
    }, [])
    const toggle = () => {
        setModal(!modal)
    }
    return (<>
        {modal && <ShowRestOfGroup toggle={toggle} modal={modal} players={players} groupNumber={groupNb} tournamentGame={tournamentGame} tournament={tournament} />}

        {GroupWinners.map((p, i) => {
            return (
                <p key={i}>{p.userData.username}</p>
            )
        })}
        <div className='button'>
            <Button onClick={() => toggle()} color="primary">Show more players</Button>
        </div>
    </>);
}

export default ResultsGroupWinners;