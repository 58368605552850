import React from 'react';
import styled from 'styled-components'
import { AddAvatarIcon, AddIcon, ArrowIcon, BackArrowIcon, QuestIcon } from '../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import { useDispatch } from 'react-redux';
import questService from 'services/quest.service';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 100px;
        height: 80px;
        margin-left: 20px;
    }
`

const CreateUpdateQuest = ({ modal, toggle, quest }) => {

    const [icon, setIcon] = React.useState(undefined);
    const [online, setOnline] = React.useState();
    const [repeatable, setRepeatable] = React.useState();
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const formData = React.useMemo(() => new FormData(), []);

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'file') {
            formData.set(name, e.target.files[0])
        } else if (name === "online") {
            setOnline(value);
            formData.set(name, value);
        } else if (name === "repeatable") {
            setRepeatable(value === "true" ? true : false);
            formData.set(name, value);
        } else {
            formData.set(name, value)
        }
    }

    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const loadFile = (e) => {
        const [file] = e.target.files;
        setIcon(URL.createObjectURL(file));
    };

    const AddUpdateQuest = async () => {
        if (quest) { //Update
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            if (formData.get("repeatable") === true && formData.get("frequency") === null) {
                setColor("3");
                setMessage("The quest is repeatable. Please fill in its frequency!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            questService.updateQuest(quest._id, formData).then((response) => {
                setColor("2");
                setMessage("GiftCard updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })
        }
        else { // create new quest
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            if (formData.get("name") === null || formData.get("description") === null || formData.get("gdReward") === null
                || formData.get("repeatable") === null || (formData.get("repeatable") === true && formData.get("frequency") === null)
                || formData.get("online") === null || formData.get("numberOfTasks") === null || formData.get("file") === null) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                if (formData.get("repeatable") === false) formData.set("frequency", "N/A")
                questService.addQuest(formData).then((response) => {
                    setColor("2");
                    setMessage("Quest created successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }
    React.useEffect(() => {
        if (quest) {
            setOnline(quest.online)
            setRepeatable(quest.repeatable)
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update giftcard" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={QuestIcon} style={{ height: "20px", width: "20px" }} />{quest ? 'Update' : 'Add'} Quest</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList style={{ backgroundColor: 'transparent' }}>
                        <Tab style={{ marginLeft: "2%" }}>Information</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Gallery & more</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Name </label>
                                                <Input placeholder="Quest name" type="text" defaultValue={quest && quest.name} onChange={inputHandler} name="name" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Description</label><br />
                                                <Input placeholder="Description" type="textarea" defaultValue={quest && quest.description} onChange={inputHandler} name="description" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Number of tasks</label><br />
                                                <Input placeholder="numberOfTasks" type="number" defaultValue={quest && quest.numberOfTasks} onChange={inputHandler} name="numberOfTasks" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>GD reward</label>
                                                <Input placeholder="GD Reward" type="number" defaultValue={quest && quest.gdReward} onChange={inputHandler} name="gdReward" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button
                                        className="next"
                                        onClick={() => handleButtonChange(1)}
                                    ><img src={ArrowIcon} /> Next</Button>
                                </Form>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Icon </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e) }} name="file" />
                                                </Button>
                                                <img src={(quest && icon === undefined) ? quest.icon : icon} className="avatar" alt="icon" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Online ?</label>
                                                <select value={online} onChange={inputHandler} name="online">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Repeatable ?</label>
                                                <select value={repeatable} onChange={inputHandler} name="repeatable">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {repeatable && <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Frequency</label>
                                                <Input placeholder="e.g. Daily, ..." type="text" defaultValue={quest && quest.frequency} onChange={inputHandler} name="frequency" />
                                            </FormGroup>
                                        </Col>
                                    </Row>}
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(0)}
                                ><img src={BackArrowIcon} /> Back</Button>

                                <Button className='post' onClick={AddUpdateQuest}>{quest ? 'Update' : 'Post'}</Button>
                            </CreationForm>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateQuest);