import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import orderService from 'services/order.service';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
`
const GrantGD = ({ modal, toggle, profileId, username }) => {
    const [data, setData] = React.useState({ profileId: profileId, giftedAmount: undefined, typeOfGift: undefined })
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const addGD = async () => {
        console.log(data);
        if (data.giftedAmount !== undefined && data.typeOfGift !== undefined) {
            orderService.addGD(data).then((response) => {
                if (response.data.error === true) {
                    setColor("3");
                    setMessage(response.data.message)
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
                else {
                    setColor("2");
                    setMessage("Gd granted to " + username + " successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }
            }).catch((err) => {
                console.log(err.message);
            })
        }
        else {
            setColor("3");
            setMessage("Please fill in the required fields!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }
    const inputHandler = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                Grant GD to {username}
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Gifted amount </label><br />
                                    <Input placeholder="Gifted amount" value={data.room} onChange={inputHandler} type="Number" name="giftedAmount" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Type of gift </label>
                                    <select defaultValue="" value={data.typeOfGift} onChange={inputHandler} name="typeOfGift">
                                        <option value="" disabled hidden>Select type of gift</option>
                                        <option value="Tournament">Tournament</option>
                                        <option value="Gift">Gift</option>
                                        <option value="Refund">Refund</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className='post' onClick={() => addGD()}>Add GD</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(GrantGD);