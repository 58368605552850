import React from 'react';
import { Match, SVGViewer, SingleEliminationBracket, createTheme } from '@g-loot/react-tournament-brackets';
import Loader from 'components/UI/Loader';
import { ThinkingIcon } from 'assets/img';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import tournamentService from 'services/tournament.service';
import Notification from 'utils/notification';
import BracketScore from './BracketScore';
import SendPoints from '../Results/SendPoints';

const GlootTheme = createTheme({
    textColor: { main: '#000000', highlighted: '#F4F2FE', dark: '#707582' },
    matchBackground: { wonColor: '#2D2D59', lostColor: '#1B1D2D' },
    score: {
        background: {
            wonColor: `#10131C`,
            lostColor: '#10131C',
        },
        text: { highlightedWonColor: '#7BF59D', highlightedLostColor: '#FB7E94' },
    },
    border: {
        color: '#292B43',
        highlightedColor: 'RGBA(152,82,242,0.4)',
    },
    roundHeader: { backgroundColor: '#3B3F73', fontColor: '#F4F2FE' },
    connectorColor: '#3B3F73',
    connectorColorHighlight: 'RGBA(152,82,242,0.4)',
    svgBackground: 'RGBA(0,0,0,0.1)',
});

const NoBrackets = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`
const GenerateFirstRoundButton = styled.div`
    position: absolute;
    top: 55%;
    cursor: pointer;
    z-index: 20;
    button.generate{
        width: 100px;
        height: 27px;
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 13.5px;
        font-size: 11px;
        margin: 0;
        padding: 0;
    }
`;
const GenerateButton = styled.div`
    position: absolute;
    display: flex;
    top: 90px;
    right: 16px;
    z-index: 20;
    cursor: pointer;
    button.generate, button.submitResults, button.sendPoints{
        width:200px;
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
    }
`;

export const BracketsSE = ({ id, event }) => {
    const [matches, setMatches] = React.useState([]);
    const [tournamentGame, setTournamentGame] = React.useState({});
    const [numberOfRounds, setNumberOfRounds] = React.useState();
    const [firstRound, setFirstRound] = React.useState(false);
    const [lastRound, setLastRound] = React.useState(false);
    const [winners, setWinners] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    // Modal open state
    const [generateModal, setGenerateModal] = React.useState(false);
    const [submitResultsModal, setSubmitResultsModal] = React.useState(false);
    const [sendPointsModal, setSendPointsModal] = React.useState(false);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const refreshData = async () => {
        let allMatches = []
        await tournamentService.getBracketPlayers(id).then((response) => {
            allMatches = response.data.data;
            setMatches(response.data.data)
        }).catch((error) => {
            console.log(error.message);
        })
        await tournamentService.getOneTournament(id).then(async (response) => {
            const totalNumberOfRounds = response.data.data.brackets?.NumberOfRounds;
            setNumberOfRounds(totalNumberOfRounds);
            setTournamentGame(response.data.data.gameId);
            setFirstRound(response.data.data.brackets?.PlayedRounds[0].round1);
            const isLastRound = Object.values(response.data.data.brackets?.PlayedRounds[(totalNumberOfRounds - 1)])[0];
            setLastRound(isLastRound);
            if (isLastRound) {
                const lastRoundPlayers = allMatches.filter(match => match.tournamentRoundText === Number(totalNumberOfRounds))
                setWinners(await getWinnersData(lastRoundPlayers))
            }
        }).catch((error) => {
            console.log(error.message);
        })
        setLoading(false)
    }

    const getWinnersData = async (players) => {
        const winnersData = [];
        if (players[0] && players[0].participants) {
            for (const player of players[0].participants) {
                let userData = {
                    _id: player.id,
                    username: player.name,
                    avatar: player.avatar
                }
                winnersData.push({ userData: userData });
            }
        }
        return winnersData;
    }
    React.useEffect(() => {
        refreshData()
    }, [])
    const submitResultsToggle = () => {
        setSubmitResultsModal(!submitResultsModal)
    }
    const sendPointsToggle = () => {
        setSendPointsModal(!sendPointsModal)
    }
    const generateToggle = () => {
        if (firstRound)
            setGenerateModal(!generateModal)
    }
    const generateBrackets = async () => {
        generateToggle();
        await tournamentService.generateBracket(id).then((response) => {
            setLoading(true);
            setColor("2");
            setMessage(response.data.data);
            setShowNotif(true);
            refreshData();
            setTimeout(() => {
                setShowNotif(false)
            }, 2000);

        }).catch((error) => {
            if (error.response.status !== 200) {
                setColor("3");
                setMessage(error.response.data.message)
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            console.log(error.message);
        })
    }

    return (
        <>
            {submitResultsModal && <BracketScore toggle={submitResultsToggle} modal={submitResultsModal} matches={matches} numberOfRounds={numberOfRounds} tournamentId={id} tournamentGame={tournamentGame} refresh={refreshData} />}
            {sendPointsModal && <SendPoints toggle={sendPointsToggle} modal={sendPointsModal} tournamentId={id} groupWinners={winners} groupNb={1} brackets={true} />}
            {showNotif && <Notification color={color} message={message} />}
            <div className="content">
                <GenerateButton>
                    {firstRound && <Button className='submitResults' onClick={submitResultsToggle}>Submit/View results</Button>}
                    {(firstRound && !lastRound) && <Button className='generate' onClick={generateToggle}>Generate next round</Button>}
                    {(lastRound && event !== undefined && event !== null && event !== "N/A") && <Button className='sendPoints' onClick={sendPointsToggle} >Set winners points</Button>}
                </GenerateButton>
                {loading ? <div style={{ paddingTop: "100px" }}><Loader /></div> :
                    !firstRound ?
                        <NoBrackets>
                            <img src={ThinkingIcon} />
                            <p>Brackets are not available at this moment,<br />
                                try generating them?</p>
                            <GenerateFirstRoundButton>
                                <Button className='generate' onClick={generateBrackets}>Generate First Round</Button>
                            </GenerateFirstRoundButton>
                        </NoBrackets> :
                        <SingleEliminationBracket
                            matches={matches}
                            theme={GlootTheme}
                            matchComponent={Match}
                            options={{
                                style: {
                                    roundHeader: {
                                        backgroundColor: GlootTheme.roundHeader.backgroundColor,
                                        fontColor: GlootTheme.roundHeader.fontColor,
                                    },
                                    connectorColor: GlootTheme.connectorColor,
                                    connectorColorHighlight: GlootTheme.connectorColorHighlight,
                                },
                            }}
                            svgWrapper={({ children, ...props }) => (
                                <SVGViewer background={GlootTheme.svgBackground} SVGBackground={GlootTheme.svgBackground} width={1500} height={700} {...props}>
                                    {children}
                                </SVGViewer>
                            )}
                        />
                }
            </div>
            <Modal isOpen={generateModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={generateToggle} >
                <ModalHeader toggle={generateToggle}>Generate Brackets</ModalHeader>
                <ModalBody>
                    Are you sure you want to generate the brackets?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => generateBrackets()}>Yes</Button>{' '}
                    <Button color="secondary" onClick={generateToggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}