import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button, Collapse, Label } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import { ManetteIcon, ArrowIcon, BackArrowIcon, AddAvatarIcon } from 'assets/img';
import tournamentService from 'services/tournament.service';
import { FormControlLabel, Switch } from '@mui/material';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';

const CreationForm = styled.div`
    position: relative;
    top: -50px;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-left: 20px;
    }
`

const AddUpdateTournamentGame = ({ modal, toggle }) => {
    const [data, setData] = React.useState([])
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [tabIndex, setTabIndex] = React.useState(0);
    const [addCollapse, setAddCollapse] = React.useState(false);
    const [updateCollapse, setUpdateCollapse] = React.useState(false);
    const [status, setStatus] = React.useState('');
    const [logo, setLogo] = React.useState(undefined);
    const [cover, setCover] = React.useState(undefined);
    const [icon, setIcon] = React.useState(undefined);
    const [category, setCategory] = React.useState("");
    const [namingConstraints, setNamingConstraints] = React.useState({ "serial": false, "regionTag": false, "numeral": false });
    const [game, setGame] = React.useState("");
    const [gameObject, setGameObject] = React.useState("");
    const [games, setGames] = React.useState([]);
    const [gameCategories, setGameCategories] = React.useState([]);

    const formData = React.useMemo(() => new FormData(), []);

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "gameId") {
            setGame(value);
            setGameObject(games.find(({ _id }) => _id === value))
        }
        else if (name === "categoryId") {
            setCategory(value);
            formData.set(name, value);
        }
        else if (name.includes("namingConstraints")) {
            formData.set(name, e.target.checked);
            let naming = (name.substr(18)).slice(0, -1);
            setNamingConstraints({ ...namingConstraints, [naming]: e.target.checked })
        }
        else if (name === 'logo' || name === 'cover' || name === 'icon') {
            formData.set(name, e.target.files[0])
        }
        else formData.set(name, value);
    }

    const getGames = async () => {
        tournamentService.getTournamentGames().then(response => {
            setGames(response.data.data)
        })
    }
    const getGameCategories = async () => {
        tournamentService.getGamesCategories().then(response => {
            setGameCategories(response.data.data)
        })
    }
    React.useEffect(() => {
        getGames();
        getGameCategories();

    }, [])


    const toggleAddButton = () => {
        setAddCollapse(!addCollapse);
        setUpdateCollapse(false);
        if (!addCollapse) {
            setStatus('Add Game');
            setTabIndex(0);
            setGameObject("");
            formData.set("namingConstraints[serial]", false);
            formData.set("namingConstraints[regionTag]", false);
            formData.set("namingConstraints[numeral]", false);
        }
        else {
            setStatus('');
        }
    };
    const toggleUpdateButton = () => {
        setUpdateCollapse(!updateCollapse);
        setAddCollapse(false);

        !updateCollapse ? setStatus('Update Game') : setStatus('')
    };


    const goToUpdateGame = async () => {
        if (game.length !== 0) {
            setUpdateCollapse(false);
            setAddCollapse(true);
            setCategory(gameObject.categoryId._id)
            setNamingConstraints({
                "serial": gameObject.namingConstraints.serial,
                "numeral": gameObject.namingConstraints.numeral,
                "regionTag": gameObject.namingConstraints.regionTag
            })
        }
    }

    const addUpdateGame = async () => {
        if (gameObject) { //Update
            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            tournamentService.updateGame(gameObject._id, formData).then((response) => {
                setColor("2");
                setMessage("Game updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new tournament
            if (formData.get("title") === null || formData.get("description") === null || formData.get("ageRestriction") === null ||
                formData.get("categoryId") === null || formData.get("namingConstraints[serial]") === null ||
                formData.get("namingConstraints[regionTag]") === null || formData.get("namingConstraints[numeral]") === null ||
                formData.get("region") === null || formData.get("logo") === null || formData.get("cover") === null || formData.get("icon") === null) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                for (var pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                tournamentService.addGame(formData).then((response) => {
                    setColor("2");
                    setMessage("Game added successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }

    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const loadFile = (e, name) => {
        const [file] = e.target.files;
        if (name === "logo")
            setLogo(URL.createObjectURL(file));
        else if (name === "cover")
            setCover(URL.createObjectURL(file));
        else
            setIcon(URL.createObjectURL(file));
    };

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <img src={ManetteIcon} /> Tournament games
                <br />
                <Button color="primary" onClick={toggleAddButton} style={{ marginBottom: '1rem' }}>
                    Add Game
                </Button>
                <Button color="primary" onClick={toggleUpdateButton} style={{ marginBottom: '1rem' }}>
                    Update Game
                </Button>

                <h4>{status}</h4>
                <Collapse isOpen={addCollapse}>
                    <Tabs index={tabIndex} onChange={handleTabsChange}>
                        <TabPanels>
                            <TabPanel>
                                <CreationForm>
                                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Game title </label>
                                                    <Input placeholder="Game title" type="text" defaultValue={gameObject && gameObject.title} onChange={inputHandler} name="title" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Game description </label>
                                                    <Input placeholder="Game description" type="text" defaultValue={gameObject && gameObject.description} onChange={inputHandler} name="description" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Age restriction </label>
                                                    <Input placeholder="Age restriction" type="number" min="1" defaultValue={gameObject && gameObject.ageRestriction} onChange={inputHandler} name="ageRestriction" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Game category</label><br />
                                                    <select value={category} onChange={inputHandler} name="categoryId">
                                                        <option value="" disabled selected hidden>Select game</option>
                                                        {gameCategories.map((gc) => {
                                                            return (
                                                                <option key={gc._id} value={gc._id}>{gc.title}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup check>
                                                    <label>Naming constraints </label><br />
                                                    <FormControlLabel
                                                        control={<Switch color="secondary" checked={namingConstraints.serial} onChange={inputHandler} name="namingConstraints[serial]" />}
                                                        label="Serial"
                                                    />
                                                    <FormControlLabel
                                                        control={<Switch color="secondary" checked={namingConstraints.regionTag} onChange={inputHandler} name="namingConstraints[regionTag]" />}
                                                        label="region tag"
                                                    />
                                                    <FormControlLabel
                                                        control={<Switch color="secondary" checked={namingConstraints.numeral} onChange={inputHandler} name="namingConstraints[numeral]" />}
                                                        label="Numeral"
                                                    />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Region </label>
                                                    <Input placeholder="Region" type="text" defaultValue={gameObject && gameObject.region} onChange={inputHandler} name="region" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button
                                            className="next"
                                            onClick={() => handleButtonChange(1)}
                                        >Next <img src={ArrowIcon} /></Button>
                                    </Form>
                                </CreationForm>
                            </TabPanel>
                            <TabPanel>
                                <CreationForm>
                                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Logo</label><br />
                                                    <Button className='add'>
                                                        <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'logo') }} name="logo" />
                                                    </Button>

                                                    <img src={(gameObject && logo === undefined) ? gameObject.logo : logo} className="avatar" alt="logo" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Cover</label><br />
                                                    <Button className='add'>
                                                        <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'cover') }} name="cover" />
                                                    </Button>

                                                    <img src={(gameObject && cover === undefined) ? gameObject.cover : cover} className="avatar" alt="cover" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col className="px-md-1" md="12">
                                                <FormGroup>
                                                    <label>Icon</label><br />
                                                    <Button className='add'>
                                                        <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'icon') }} name="icon" />
                                                    </Button>

                                                    <img src={(gameObject && icon === undefined) ? gameObject.icon : icon} className="avatar" alt="icon" />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Button
                                        className='back'
                                        onClick={() => handleButtonChange(0)}
                                    ><img src={BackArrowIcon} /> Back</Button>

                                    <Button className='post' onClick={() => addUpdateGame()}>{gameObject ? 'Update' : 'Post'}</Button>
                                </CreationForm>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Collapse>
                <Collapse isOpen={updateCollapse}>
                    <CreationForm>
                        <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                            <Row style={{ width: "100%" }}>
                                <Col className="px-md-1" md="12">
                                    <FormGroup>
                                        <label>Select Game</label><br />
                                        <select value={game} onChange={inputHandler} name="gameId">
                                            <option value="" disabled selected hidden>Select game</option>
                                            {games.map((g) => {
                                                return (
                                                    <option key={g._id} value={g._id}>{g.title}</option>
                                                );
                                            })}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button className='next' onClick={() => goToUpdateGame()}>Next</Button>
                        </Form>
                    </CreationForm>
                </Collapse>
            </Dialog>
        </>
    );
}

export default React.memo(AddUpdateTournamentGame);