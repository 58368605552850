import React from 'react'
import { useDispatch } from 'react-redux'
const PaginationBox = ({ currentPage, numberOfPages, totalNumber, decrement, increment }) => {
    const dispatch = useDispatch();

    const decrementPage = (current, event) => {
        if (Number(current) > 1) {
            decrement()
            event.stopPropagation();
        }
    }
    const incrementPage = (current, event) => {
        if (Number(current) < Number(numberOfPages)) {
            increment()
            event.stopPropagation();
        }
    }
    return (
        <>
            <div className='mx-auto px-2 text-center items-center mt-8 mb-4 shadow rounded-lg bg-gray-200 w-2/12'>
                <button onClick={(e) => decrementPage(currentPage, e)} className='mx-2 cursor-pointer rounded-full text-center items-center shadow-lg bg-white text-black mx-2 my-2 px-2 py-1 disabled:bg-gray-200'>
                    Prev</button>
                <span style={{ color: "white" }}>Page {currentPage} of {numberOfPages}</span>
                <button onClick={(e) => incrementPage(currentPage, e)} className='mx-2 cursor-pointer rounded-full text-center items-center shadow-lg text-black bg-white mx-2 my-2 px-2 py-1 disabled:bg-gray-200'>
                    Next</button>
            </div>
        </>
    )
}

export default PaginationBox
