import { Dialog } from "@mui/material";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Input, Row, Table } from "reactstrap";
import styled from "styled-components";
import BracketPlayerScoreRow from "./BracketPlayerScoreRow";

const PlayersModal = styled(Dialog)`
    .modalStyle{
        background-image: linear-gradient(to bottom, #3b437d, #1f265e);
    }
`
const BracketScore = ({ toggle, modal, matches, numberOfRounds, tournamentId, tournamentGame, refresh }) => {

    const [showedTr, setShowedTr] = React.useState({ kills: true, goals: true, placement: true, deaths: true, assist: true, status: true })
    const [roundNumber, setRoundNumber] = React.useState(1);
    const [filteredMatches, setFilteredMatches] = React.useState([]);


    React.useEffect(() => {
        if (tournamentGame.title.includes('Free Fire') || tournamentGame.title.includes('Pubg') || tournamentGame.title.includes('Call Of Duty')) {
            setShowedTr(values => ({ ...values, assist: false, goals: false }))
        } else if (tournamentGame.title.includes('FIFA') || tournamentGame.title.includes('Rocket League')) {
            setShowedTr(values => ({ ...values, placement: false, kills: false, deaths: false }))
        }
        else {
            setShowedTr(values => ({ ...values, placement: false, goals: false }))
        }
        filterMatches(roundNumber)
    }, [])

    const filterMatches = (nb) => {
        setRoundNumber(Number(nb));
        setFilteredMatches(matches.filter(match => match.tournamentRoundText === Number(nb)))
    }

    return (
        <PlayersModal
            fullWidth={true}
            maxWidth={"md"}
            open={modal}
            onClose={() => { toggle(); refresh() }}>
            <div className="modalStyle">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4"> {roundNumber === numberOfRounds ? "Final " : (roundNumber + 1) === numberOfRounds ? "Semi-Final " : "Round " + roundNumber} players</CardTitle>
                        <Input style={{ backgroundColor: "#322e60" }} type="select" name="roundNumber" value={roundNumber} onChange={(e) => { setRoundNumber(e.target.value); filterMatches(e.target.value) }}>
                            {Array(numberOfRounds).fill("").map((round, index) => {
                                return (
                                    <option key={index} value={(index + 1)}>Round {(index + 1)}</option>
                                );
                            })}
                        </Input>
                    </CardHeader>
                    <CardBody>
                        {filteredMatches.map((group, i) => {
                            return (
                                <Table key={`group-${i}${roundNumber}`} borderless responsive style={{ tableLayout: "fixed" }}>
                                    <thead>
                                        <tr>
                                            <th>Group {(i + 1)}</th>
                                        </tr>
                                        <tr>
                                            <th className={"w-20"}>Player Name</th>
                                            {showedTr.goals && <th className="text-center">Goals</th>}
                                            {showedTr.kills && <th className="text-center">Kills</th>}
                                            {showedTr.placement && <th className="text-center">Placement</th>}
                                            {showedTr.deaths && <th className="text-center">Deaths</th>}
                                            {showedTr.assist && <th className="text-center">Assists</th>}
                                            {showedTr.status && <th className="text-center">Is winner?</th>}
                                            <th className="text-center">Overall score</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {group.participants.map((player, index) => <BracketPlayerScoreRow key={`group-${i}${roundNumber}-player${index}`} index={index} player={player} tournamentId={tournamentId} roundNumber={roundNumber} showedTr={showedTr} />)}
                                    </tbody>

                                </Table>
                            )
                        })}
                    </CardBody>
                </Card>
            </div>
        </PlayersModal>
    );
}

export default BracketScore;