import React from 'react'
import './loader.css';

function Loader() {
    return (
        <div className='blobs-container'>
            <div className="blobs">
                <div className="blob-center"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
            </div>
        </div>
    )
}

export default Loader