import React from 'react'
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import styled from 'styled-components'
import { Dialog } from '@reach/dialog';
import Notification from '../../../utils/notification';
import { AvatarIcon, AddAvatarIcon } from 'assets/img';
import authService from 'services/auth.service';
import profileService from 'services/profile.service';
import { Check } from 'assets/img';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

`
const AvatarsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
`

const ContainerOfAvatar = styled.div`
    position: relative;
    min-height: 50px;
    min-width: 50px;
    margin: 10px 0 10px 0;
    cursor: pointer;
`
const CheckAvatarContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    max-width: 20px;
    img{
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50% !important;

    }
`
const SendAvatar = ({ modal, toggle, id }) => {
    const [avatarUrl, setAvatarUrl] = React.useState(undefined)
    const [selectedIndex, setSelectedIndex] = React.useState(-1)
    const [avatars, setAvatars] = React.useState([])
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const sendAvatar = async () => {
        if (avatarUrl !== undefined) {
            const body = { avatarUrl, profileId: id }
            profileService.addToMyAvatars(body).then(() => {
                setColor("2");
                setMessage("Avatar(s) saved successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000)
            }).catch((error) => {
                if (error.response.status === 409) {
                    console.log(error.response.data.message);
                    setColor("3");
                    setMessage(error.response.data.message);
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 4000);
                }
                else {
                    setColor("3");
                    setMessage(error.response.data.message)
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 7000);
                }
            })
        }
        else {
            setColor("3");
            setMessage("Please select an avatar!")
            setShowNotif(true);
            setTimeout(() => {
                setShowNotif(false)
            }, 7000);
        }
    }

    React.useEffect(() => {
        authService.allAvatars({ skip: 0 }).then(res => {
            res.data.data.map((avatar) => {
                if(avatar.state === "true")
                    setAvatars(avatars => [...avatars, { "avatar": avatar, "state": false }])
            })
        })
    }, [])

    const selectImage = (url, index) => {
        if (selectedIndex !== -1 && selectedIndex !== index)
            avatars[selectedIndex].state = false
        avatars[index].state = true
        setSelectedIndex(index);
        setAvatars([...avatars]);
        setAvatarUrl(url);
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Send credentials" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <img src={AvatarIcon} /> Avatars
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <AvatarsContainer className='button-container'>
                                        {avatars.map((a, index) =>
                                            <ContainerOfAvatar>
                                                {a.state &&
                                                    <CheckAvatarContainer><img src={Check} alt={index} /></CheckAvatarContainer>
                                                }
                                                <img src={a.avatar.image} className="avatar" alt={index} onClick={() => selectImage(a.avatar.image, index)} />
                                            </ContainerOfAvatar>

                                        )}
                                    </AvatarsContainer>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className='post' onClick={() => sendAvatar()}>send Avatar to winner</Button>
                    </Form>
                </CreationForm>
            </Dialog>
        </>
    );
}

export default React.memo(SendAvatar);