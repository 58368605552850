import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import order from '../../services/order.service'

const initialState = {
    loading: false,
    allOrders: [],
    pageNumber: 1,
    error: ''
}

const OrdersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;

        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.allOrders = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchOrders.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.allOrders = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchOrders = createAsyncThunk('orders/getOrders', async (payload) => {
    const orders = await order.getAllOrders(payload.pageNumber, payload)
    return (orders.data);
})


export const { incrementPageNumber, decrementPageNumber } = OrdersSlice.actions
export default OrdersSlice.reducer;