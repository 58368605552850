import React from "react";
import { Card, CardBody, Table, Row, Col, CardHeader, CardTitle } from "reactstrap";
import styled from "styled-components";
import { LeaderBoard } from "assets/img";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginationBox from "components/UI/PaginationBox";
import { leaderboard } from "redux/reducers/quizSlice";
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/quizSlice';
import Loader from "components/UI/Loader";
import NoData from "components/UI/NoData";

const NumberDiv = styled.div`
    width: 35px;
    font-size: 20px;
    text-align: center;
    border-radius: 13px;
    background-image: linear-gradient(to bottom, rgba(27, 37, 115,.13), rgba(255, 70, 94,.13));
`

const Leaderboard = () => {
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const dispatch = useDispatch();

    const { loading, scores, pageNumber } = useSelector((state) => state.quiz, shallowEqual)

    const getAllScores = (limit) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        dispatch(leaderboard({ skip, limit }))
    }
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }

    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }

    React.useEffect(() => {
        getAllScores(limit);
    }, [pageNumber])
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={LeaderBoard} alt="Store" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Leaderboard</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* <Filters /> */}
                                <div className="table-full-width table-responsive" style={{
                                    height: '620px',
                                    overflowY: 'auto',
                                }}>
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (scores.data && scores.data.length === 0) ? <NoData text={"No scores to show yet!"} /> :
                                            <Table>
                                                {/* <thead className="text-primary" style={{display: "table", width: "calc(100% - 17px)"}}> */}
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th className="text-center">Players</th>
                                                        <th>Scores</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scores.data && scores.data.map((s, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td style={{ paddingLeft: '20%' }}>
                                                                    <div style={{ display: "inline-flex", gap: "30px" }}>
                                                                        <NumberDiv style={{ color: '#fe4958' }}>{skip + index + 1}</NumberDiv>
                                                                        <div className="photo">
                                                                            <img src={s.profileId.avatar} />
                                                                        </div>
                                                                        {s.profileId.username}
                                                                    </div>
                                                                </td>
                                                                <td>{s.score}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(scores.NumberOfPlayers / 20)} totalNumber={scores.NumberOfPlayers} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Leaderboard;
