import React from "react";
import { Card, CardBody, Table, Row, Col, CardHeader, CardTitle, Input, Button } from "reactstrap";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginationBox from "components/UI/PaginationBox";
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/GdSlice';
import Loader from "components/UI/Loader";
import { GDIcon } from "assets/img";
import orderService from "services/order.service";
import { ThinkingIcon } from "assets/img";
import { fetchGdProfilesHistory } from "redux/reducers/GdSlice";
import { fetchGdInHistory } from "redux/reducers/GdSlice";
import { fetchGdOutHistory } from "redux/reducers/GdSlice";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import NoData from "components/UI/NoData";

const NumberDiv = styled.div`
    width: 35px;
    font-size: 20px;
    text-align: center;
    border-radius: 13px;
    background-image: linear-gradient(to bottom, rgba(27, 37, 115,.13), rgba(255, 70, 94,.13));
`
const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .grantGD{
        cursor: pointer;
    }
`
const SearchDiv = styled.div`
    display: flex;
    gap: 10px;
    input{
        background-color: #322e60;
        border-radius: 17px;
        width: 250px;
    }
    > * {
        flex: 0 0 auto;
    }
    button{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
`
const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: 250px;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`

const GD = () => {
    const [spentGD, setSpentGD] = React.useState(0);
    const [gainedGD, setGainedGD] = React.useState(0);
    const [display, setDisplay] = React.useState(1);
    const [searchedUsername, setSearchedUsername] = React.useState();
    const [paymentFilter, setPaymentFilter] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [dates, setDates] = React.useState(undefined);
    const [sortBy, setSortBy] = React.useState(undefined);
    const [modal, setModal] = React.useState(false);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);

    const { afterToday } = DateRangePicker;
    const dispatch = useDispatch();

    const filters = [
        { key: "1", value: "totalGainedGd" },
        { key: "2", value: "numberOfPurchasesUsingGD" },
        { key: "3", value: "totalSpentGd" }
    ]
    const orderBy = [
        { key: "1", value: "Ascending" },
        { key: "-1", value: "Descending" },
    ]
    const whatToDisplay = [
        { key: "1", value: "In GD Transactions" },
        { key: "2", value: "Out GD Transactions" },
        { key: "3", value: "Profiles with total in/out GD" }
    ]

    const { loading, GdProfilesHistory, pageNumber, error } = useSelector((state) => state.gd, shallowEqual)

    React.useEffect(() => {
        filterData(display, paymentFilter, order, searchedUsername, dates);
    }, [pageNumber])

    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    const skipToFirstPage = () => {
        for (let i = pageNumber; i > 1; i--) {
            decrementPage()
        }
    }
    const getGdStatus = async () => {
        await orderService.getStatus().then(response => {
            setSpentGD(response.data.data[0].totalSpentGd)
            setGainedGD(response.data.data[0].totalGainedGd)
        }).catch(err => {
            console.log(err);
        })
    }
    const filterData = (displayPage, filter, orderData, username, updatedDates) => {
        const page = displayPage === undefined ? display : displayPage;
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        let sortBy = { pageNumber, skip, limit }
        if (filter !== "") {
            sortBy.paymentFilter = filter;
            orderData !== "" ? sortBy.order = orderData : sortBy.order = -1
        }
        if (username !== "")
            sortBy.username = username
        if (updatedDates !== undefined && updatedDates !== null) {
            sortBy.startDate = updatedDates[0];
            sortBy.endDate = updatedDates[1];
        }
        if ((displayPage !== undefined && displayPage !== display) || filter !== paymentFilter || orderData !== order || username !== searchedUsername || updatedDates !== dates) { // changing display
            skipToFirstPage();
        }
        switch (page) {
            case "3":
                dispatch(fetchGdProfilesHistory(sortBy))
                break;
            case "2":
                dispatch(fetchGdOutHistory(sortBy))
                break;
            default:
                dispatch(fetchGdInHistory(sortBy))
                break;
        }
    }
    React.useEffect(() => {
        getGdStatus();
    }, [])
    return (
        <>
            <div className="content">
                <Row >
                    <Col md="12">
                        <Card style={{
                            height: '750px',
                            overflowY: 'auto',
                        }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={GDIcon} alt="Store" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> GD</CardTitle>
                                <CardTitle>
                                    <div style={{ display: "flex", gap: "30px", flexFlow: "row wrap", justifyContent: "flex-end" }}>
                                        <h4>Available GD: <b>{gainedGD - spentGD}</b></h4>
                                        <h4>Spent GD: <b>{spentGD}</b></h4>
                                        <h4>Gained GD: <b>{gainedGD}</b></h4>
                                    </div>
                                </CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SortFilterDiv>
                                            <h4>Display:</h4>
                                            <select defaultValue="1" onChange={(e) => { setDisplay(e.target.value); setSearchedUsername(undefined); filterData(e.target.value); }} name="display">
                                                {whatToDisplay.map((d) => {
                                                    return (
                                                        <option key={d.key} value={d.key}>{d.value}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                        <SearchDiv>
                                            <h4>Search profile:</h4>
                                            <Input placeholder="Search profile" type="text" onChange={(e) => { setSearchedUsername(e.target.value) }} onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    filterData(display, paymentFilter, order, searchedUsername, dates)
                                                    skipToFirstPage();
                                                }
                                            }} name="username" />
                                            <Button onClick={() => { filterData(display, paymentFilter, order, searchedUsername, dates); skipToFirstPage() }}>Search</Button>
                                        </SearchDiv>
                                        {display !== "3" &&
                                            <DateRangePicker showOneCalendar
                                                size="sm"
                                                placeholder="Select Date Range"
                                                disabledDate={afterToday()}
                                                placement='bottomEnd'
                                                onChange={(date) => { setDates(date); filterData(display, paymentFilter, order, searchedUsername, date); }}
                                                onClean={() => setDates(undefined)}
                                            />
                                        }
                                        {display === "3" &&
                                            <>
                                                <SortFilterDiv>
                                                    <h4>Sort by:</h4>
                                                    <select defaultValue="" onChange={(e) => { setPaymentFilter(e.target.value); filterData(display, e.target.value, order, searchedUsername, dates); }} name="paymentFilter">
                                                        <option value=""></option>
                                                        {filters.map((filter) => {
                                                            return (
                                                                <option key={filter.key} value={filter.value}>{filter.value}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </SortFilterDiv>
                                                {paymentFilter !== "" &&
                                                    <SortFilterDiv>
                                                        <h4>Order </h4>
                                                        <select defaultValue="-1" onChange={(e) => { setOrder(e.target.value); filterData(display, paymentFilter, e.target.value, searchedUsername, dates) }} name="order">
                                                            {orderBy.map((o) => {
                                                                return (
                                                                    <option key={o.key} value={o.key}>{o.value}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </SortFilterDiv>
                                                }
                                            </>
                                        }
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" >
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (GdProfilesHistory.data && GdProfilesHistory.data.length === 0) ?
                                            <NoData text={"No GD history yet!"} />
                                            :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Player</th>
                                                        <th className="text-center">{display !== "1" ? "GD Gained" : "GD spent"}</th>
                                                        <th className="text-center">{display !== "3" ? "Type of GD" : "GD Spent"}</th>
                                                        <th className="text-center">{display !== "3" ? "Old GD Amount" : "No. of purchases using GD"}</th>
                                                        {display !== "3" && <th className="text-center">Transaction Date</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {GdProfilesHistory.data && GdProfilesHistory.data.map((p, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                {display === "3" ?
                                                                    <td>
                                                                        <div style={{ display: "inline-flex", gap: "30px" }}>
                                                                            <NumberDiv style={{ color: '#fe4958' }}>{skip + index + 1}</NumberDiv>
                                                                            <div className="photo">
                                                                                <img src={p.profile.avatar} />
                                                                            </div>
                                                                            {p.profile.username}
                                                                        </div>
                                                                    </td> :
                                                                    <td>{p.username}</td>
                                                                }
                                                                <td className="text-center">{display === "3" ? p.gdHistory.totalGainedGd : (display === "2" ? p.giftedAmount : p.spentAmount)}</td>
                                                                <td className="text-center">{display === "3" ? p.gdHistory.totalSpentGd : (display === "2" ? p.typeOfGift : p.typeOfSpent)}</td>
                                                                <td className="text-center">{display === "3" ? p.gdHistory.numberOfPurchasesUsingGD : p.oldGdAmount}</td>
                                                                {display !== "3" &&
                                                                    <td className="text-center">{moment.utc(p.createdAt).format('ll')} {moment.utc(p.createdAt).format('LT')}</td>
                                                                }
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox
                            currentPage={pageNumber}
                            numberOfPages={Math.ceil((GdProfilesHistory.totalNbOfProfiles ? GdProfilesHistory.totalNbOfProfiles : GdProfilesHistory.totalCount) / limit)}
                            totalNumber={GdProfilesHistory.totalNbOfProfiles ? GdProfilesHistory.totalNbOfProfiles : GdProfilesHistory.totalCount}
                            decrement={decrementPage}
                            increment={incrementPage}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default GD;
